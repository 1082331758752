<script lang="ts" setup>
import { storeToRefs } from "pinia";
import type { PropType } from "vue";
import { computed, ref, watch } from "vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import KodexaTextInput from "~/components/inputs/kodexa-text-input.vue";
import appStore from "~/store";
import type { Assistant, AssistantConnection, DataFlowNode } from "~/model";
import KodexaTargetConnection from "~/components/dataFlow/panels/connections/kodexa-target-connection.vue";
import KodexaCheckbox from "~/components/inputs/kodexa-checkbox.vue";
import KodexaConfirm from "~/components/kodexa-confirm.vue";
import {log} from "~/utils/logger";

const props = defineProps({
  node: {
    type: Object as PropType<DataFlowNode>,
  },
});

const emit = defineEmits(["selected", "step-deleted"]);

const { assistantConnections } = storeToRefs(appStore.projectStore);
const { assistants } = storeToRefs(appStore.projectStore);

const localAssistant = computed(() => {
  return assistants.value.find((assistant: Assistant) => props.node && assistant.id === props.node.id?.split("//")[1]);
});

const localConnections = computed(() => {
  return Array.from(assistantConnections.value);
});

const currentNavigation = ref({ ref: "general", name: "General", icon: "cog" });

const tabs = computed(() => {
  return {
    general: { ref: "general", name: "General" },
    connections: { ref: "connections", name: "Connections" },
    schedule: { ref: "schedule", name: "Schedule" },
    options: { ref: "options", name: "Options" },
  };
});

const scheduleAdded = ref(false);
const errors = ref<{ [key: string]: string }>({});
const initialAssistantState = ref({}); // To store the initial state of localAssistant
const initialConnectionsState = ref<AssistantConnection[]>([]); // To store the initial state of assistant connections

// Watch for changes in the localAssistant data to mark the project as dirty
watch(localAssistant, (newAssistantState) => {
  if (newAssistantState) {
    appStore.projectStore.addToAssistantsToUpdate(newAssistantState);
    newAssistantState.schedules.forEach((schedule) => {
      errors.value[schedule.id] = validateCronExpression(schedule.cronExpression) || "";
    });
  }
}, { deep: true });

const localAssistantErrors = computed(() => {
  return localAssistant.value.validationErrors;
});

// Store the initial state when the component is mounted
onMounted(() => {
  if (localAssistant.value) {
    initialAssistantState.value = JSON.parse(JSON.stringify(localAssistant.value));
    initialConnectionsState.value = JSON.parse(JSON.stringify(Array.from(assistantConnections.value)));
  }
});

// Watch for changes in the localAssistant and assistantConnections to detect if there are changes
watch(localAssistant.value, () => {
  log.info("Assistant changed");
  appStore.projectStore.addToAssistantsToUpdate(localAssistant.value);
}, { deep: true });

function validateCronExpression(cronExpression: string): string | null {
  const cronParts = cronExpression.trim().split(/\s+/); // split by one or more spaces
  if (cronParts.length !== 6) {
    return "Cron expression must have exactly 6 parts";
  }

  const validators = [
    { value: cronParts[0], name: "Second", min: 0, max: 59, errorMessage: "Invalid second. Please enter a value between 0 and 59." },
    { value: cronParts[1], name: "Minute", min: 0, max: 59, errorMessage: "Invalid minute. Please enter a value between 0 and 59." },
    { value: cronParts[2], name: "Hour", min: 0, max: 23, errorMessage: "Invalid hour. Please enter a value between 0 and 23." },
    { value: cronParts[3], name: "Day", min: 1, max: 31, errorMessage: "Invalid day. Please enter a value between 1 and 31." },
    { value: cronParts[4], name: "Month", min: 1, max: 12, errorMessage: "Invalid month. Please enter a value between 1 and 12." },
    { value: cronParts[5], name: "Day of the week", min: 0, max: 6, errorMessage: "Invalid day of the week. Please enter a value between 0 and 6." },
  ];

  for (const { value, errorMessage, min, max } of validators) {
    if (value === "*" || value === "?") {
      continue;
    }
    if (value.includes("/")) {
      const [intervalBase, intervalStep] = value.split("/");
      if (intervalBase !== "*" || Number.isNaN(+intervalStep) || +intervalStep < min || +intervalStep > max) {
        return errorMessage;
      }
      continue;
    }
    // handling ranges
    if (value.includes("-")) {
      const [rangeStart, rangeEnd] = value.split("-").map(Number);
      if (Number.isNaN(rangeStart) || Number.isNaN(rangeEnd) || rangeStart < min || rangeEnd > max || rangeStart >= rangeEnd) {
        return errorMessage;
      }
      continue;
    }

    const numValue = +value;
    if (Number.isNaN(numValue) || numValue < min || numValue > max) {
      return errorMessage;
    }
  }

  return null;
}

function addSchedule() {
  if (localAssistant.value) {
    const newSchedule = {
      id: Date.now().toString(), // Ensure each schedule has a unique ID
      cronExpression: "* * * * * *",
    };
    localAssistant.value.schedules.unshift(newSchedule); // Add new schedule at the beginning
    errors.value[newSchedule.id] = validateCronExpression(newSchedule.cronExpression) || "";
    scheduleAdded.value = true;

    // Watch the new schedule's cronExpression for changes
    watch(
      () => newSchedule.cronExpression,
      (newValue) => {
        errors.value[newSchedule.id] = validateCronExpression(newValue) || "";
      },
    );
  }
}

function updateCronExpression(schedule, value) {
  schedule.cronExpression = value;
  errors.value[schedule.id] = validateCronExpression(value) || "";
}

function deleteSchedule(scheduleId) {
  if (localAssistant.value) {
    localAssistant.value.schedules = localAssistant.value.schedules.filter(schedule => schedule.id !== scheduleId);
    delete errors.value[scheduleId];
  }
}

// Watch existing schedules cronExpression for changes
if (localAssistant.value) {
  localAssistant.value.schedules.forEach((schedule) => {
    watch(
      () => schedule.cronExpression,
      (newValue) => {
        errors.value[schedule.id] = validateCronExpression(newValue) || "";
      },
    );
  });
}

async function deleteThisAssistant() {
  const confirmLostChanges = createConfirmDialog(KodexaConfirm);
  const result = await confirmLostChanges.reveal({
    icon: "alert-circle-outline",
    title: "Unsaved Changes",
    message: "You have unsaved changes, if you continue they will be lost!",
    notes: "Changes to assistants will require the project to reload. Do you want to discard the changes, or stay in the project to save them",
    confirmText: "Discard Changes, Delete Assistant",
    confirmIcon: "alert-circle-outline",
    cancelText: "Return to Project",
    cancelIcon: "close",
    type: "danger",
  });

  if (result.isCanceled) {
    return false;
  }

  await appStore.projectStore.deletedAssistant(localAssistant.value);

  emit("step-deleted");
}
</script>

<template>
  <div>
    <div v-if="localAssistant" class="flex h-full flex-col bg-white">
      <div style="height: calc(100vh - 15rem)">
        <div class="border-b border-gray-200 dark:border-gray-700">
          <ul class="-mb-px ml-2 flex flex-wrap text-center text-sm font-medium text-gray-500 dark:text-gray-400">
            <li
              v-for="(item) in tabs" :key="item.ref"
              class="mr-2"
              @click="currentNavigation = item"
            >
              <a
                class="text-md"
                :class="item.ref === currentNavigation?.ref ? 'inline-flex items-center justify-center p-4 text-theme-primary border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group' : 'inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'"
              >
                <MaterialDesignIcon
                  v-if="item?.icon" :name="item.icon" size="18"
                  class="text-theme-primary mr-3"
                />
                {{ item?.name }}
              </a>
            </li>
          </ul>
        </div>
        <div v-if="localAssistantErrors && localAssistantErrors.instances && localAssistantErrors.instances.length > 0" class="bg-red-50 p-4 mb-4">
          <div class="flex items-start">
            <MaterialDesignIcon class="text-red-600 mr-2 mt-1" name="alertCircle" size="24" />
            <div class="flex flex-col">
              <p class="font-bold text-red-700">
                Validation Errors
              </p>
              <p class="text-red-600 text-sm mt-1">
                Assistant activation blocked due to configuration issues
              </p>
            </div>
          </div>
          <ul class="list-disc list-inside mt-2">
            <li v-for="error in localAssistantErrors.instances" :key="error.option" class="text-red-700">
              <span class="font-semibold">{{ error.option }}:</span> {{ error.message }}
              <p v-if="error.description" class="ml-5 text-sm text-red-600">
                {{ error.description }}
              </p>
            </li>
          </ul>
        </div>
        <div v-if="currentNavigation?.ref === 'general'" class="mx-2 mt-2">
          <div class="col-span-6 mb-1 sm:col-span-3">
            <KodexaTextInput
              v-model="localAssistant.name"
              name="name"
              type="text"
              autocomplete="assistant-name"
              label="Name"
            />
          </div>
          <div class="mt-4 mb-4 text-gray-600">
            <KodexaClipboardable :content="localAssistant.id" :show-content="false" message="Copy Assistant Identifier" />
          </div>
          <div class="col-span-6 mb-1 sm:col-span-3">
            <KodexaTextArea
              v-model="localAssistant.description" name="assistant-description" label="Description"
              :rows="9"
            />
          </div>
          <div class="col-span-6 sm:col-span-3">
            <KodexaCheckbox
              v-model="localAssistant.active" name="enabled" label="Active"
              hint="If the assistant is active it will respond to events"
            />
          </div>
          <div class="col-span-6 mb-1 sm:col-span-3">
            <KodexaCheckbox
              v-model="localAssistant.loggingEnabled" name="loggedEnabled" label="Logging enabled"
              hint="Store logging for the assistant, note this may slow it down"
            />
          </div>
          <div class="col-span-6 mb-1 sm:col-span-3">
            <KodexaCheckbox
              v-model="localAssistant.showInTraining" name="showInTraining" label="Available for testing"
              hint="This means that the assistant is available from the test button in a workspace"
            />
          </div>
          <div class="col-span-6 mb-1 sm:col-span-3">
            <KodexaCheckbox
              v-model="localAssistant.chatEnabled" name="chatEnabled" label="Chat enabled"
              hint="If true we will automatically add this assistant to workspace chats as a participant"
            />
          </div>
          <div class="bg-white shadow sm:rounded-lg mt-4">
            <div class="px-4 py-5 sm:p-6">
              <div class="sm:flex sm:items-start sm:justify-between">
                <div>
                  <h3 class="text-base font-semibold leading-6 text-gray-900">
                    Delete Assistant
                  </h3>
                  <div class="mt-2 max-w-xl text-sm text-gray-500">
                    <p>
                      Deleting an assistant will remove it from the system and all associated data, this action cannot be undone.
                    </p>
                    <KodexaButton id="deleteProject" icon="delete" type="danger" class="mt-7" @click="deleteThisAssistant()">
                      Delete Assistant
                    </KodexaButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="currentNavigation?.ref === 'options'" class="mx-2 mt-2">
          <div v-for="option in localAssistant.definition.options" :key="option.name">
            <ConfigurationOption
              v-model="localAssistant.options"
              :assistant="localAssistant"
              :item="option"
            />
          </div>
        </div>
        <div v-if="currentNavigation?.ref === 'connections'" class="mx-2 mt-2">
          <KodexaArticle article-id="9168911" text="Learn more about connections" :slide="false" />
          <h2 class="font-semibold text-gray-700 mb-4">
            Inbound Connections
          </h2>
          <KodexaButton
            size="small" type="secondary" icon="plus"
            @click="appStore.projectStore.addTargetConnection(localAssistant.id)"
          >
            Add Connection
          </KodexaButton>
          <div v-for="connection in localConnections" :key="connection.id">
            <div v-if="connection.targetAssistant?.id === localAssistant.id" class="border-b-1 border-gray-500 mb-1 mt-1">
              <KodexaSourceConnection :model-value="connection" :assistant="localAssistant" />
            </div>
          </div>
          <h2 class="font-semibold text-gray-700 mt-4 mb-4">
            Outbound Connections
          </h2>
          <KodexaButton
            size="small" type="secondary" icon="plus"
            @click="appStore.projectStore.addSourceConnection(localAssistant.id)"
          >
            Add Connection
          </KodexaButton>
          <div v-for="connection in localConnections" :key="connection.id">
            <div v-if="connection.sourceAssistant?.id === localAssistant.id" class="border-b-1 border-gray-500 mb-1 mt-1">
              <KodexaTargetConnection :model-value="connection" :assistant="localAssistant" />
            </div>
          </div>
        </div>
        <div v-if="currentNavigation?.ref === 'schedule'" class="mx-2 mt-2">
          <KodexaButton
            size="small" type="secondary" icon="plus"
            @click="addSchedule"
          >
            Add New Schedule
          </KodexaButton>
          <div
            v-for="schedule in localAssistant.schedules"
            :key="schedule.id"
            class="mt-4 p-4 border rounded-md bg-gray-50"
          >
            <div>
              <CronLight v-model="schedule.cronExpression" format="quartz" />
              <div class="flex items-center text-gray-700 pt-2 mb-2 mt-2">
                <span class="mr-2">Cron expression: </span>
                <KodexaTextInput
                  v-model="schedule.cronExpression"
                  name="cronExpression"
                  @input="updateCronExpression(schedule, $event.target.value)"
                />
              </div>
              <p v-if="errors[schedule.id]" class="text-red-500">
                {{ errors[schedule.id] }}
              </p>
            </div>
            <KodexaButton
              v-tooltip="'Delete schedule'"
              size="small"
              type="danger"
              icon="delete"
              class="mt-2 deleteSchedule !k-d-block ml-auto"
              @click="deleteSchedule(schedule.id)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
:deep(.deleteSchedule a) {
  margin-right: 0 !important;
}
</style>
