<script lang="ts" setup>
import { ref, watch } from "vue";

interface SelectionOption {
  description?: string;
  id: string;
  label?: string;
  lexicalRelations?: Array<{ type: string; value: string }>;
}

const props = defineProps<{
  modelValue: SelectionOption[];
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: SelectionOption[]): void;
}>();

const items = ref<SelectionOption[]>(props.modelValue || []);
const tempItem = ref<SelectionOption>({ description: "", id: "", label: "" });
const editingIndex = ref(-1);

watch(items, (newItems) => {
  emit("update:modelValue", newItems);
}, { deep: true });

function resetForm() {
  tempItem.value = { description: "", id: "", label: "" };
  editingIndex.value = -1;
}

function addOrUpdateItem() {
  if (editingIndex.value === -1) {
    const newItem: SelectionOption = { ...tempItem.value, id: Date.now().toString() };
    items.value.push(newItem);
  } else {
    items.value[editingIndex.value] = { ...tempItem.value };
    editingIndex.value = -1;
  }
  resetForm();
}

function editItem(index: number) {
  tempItem.value = { ...items.value[index] };
  editingIndex.value = index;
}

function removeItem(index: number) {
  items.value.splice(index, 1);
  if (editingIndex.value === index) {
    resetForm();
  }
}

function addLexicalRelation(item: SelectionOption) {
  if (!item.lexicalRelations) {
    item.lexicalRelations = [];
  }
  item.lexicalRelations.push({ type: "SYNONYM", value: "" });
}

function removeLexicalRelation(item: SelectionOption, idx: number) {
  if (item.lexicalRelations && idx > -1) {
    item.lexicalRelations.splice(idx, 1);
  }
}
</script>

<template>
  <div class="p-4">
    <div class="mb-4">
      <KodexaTextInput
        v-model="tempItem.label"
        name="label" label="Label" placeholder="Enter label"
      />
      <KodexaTextArea
        v-model="tempItem.description" label="Description" placeholder="Enter description" class="mt-1"
        :rows="5" name="description"
      />
      <KodexaButton class="mt-4" type="secondary" size="small" icon="plus" :disabled="!tempItem.label" @click="addOrUpdateItem">
        Add/Update
      </KodexaButton>
    </div>

    <ul class="list-disc pl-5">
      <li v-for="(item, index) in items" :key="item.id" class="mb-4">
        <div class="flex items-start justify-between">
          <div class="grow">
            <p class="text-sm font-semibold">
              {{ item.label }}
            </p>
            <p class="text-xs">
              {{ item.description }}
            </p>
            <div class="mt-3 mb-2">
              <p class="font-md">
                Lexical Relations
              </p>
            </div>
            <ul class="space-y-2">
              <li v-for="(lexicalRelation, idx) in item.lexicalRelations" :key="idx" class="space-y-2">
                <div class="flex items-center justify-between">
                  <KodexaDropDown
                    v-model="lexicalRelation.type"
                    name="relationType"
                    :items="[
                      { id: 'SYNONYM', text: 'Synonym' },
                      { id: 'ANTONYM', text: 'Antonym' },
                    ]"
                    label="Relation Type"
                  />
                  <KodexaButton
                    type="danger"
                    size="small"
                    icon="delete"
                    class="mt-2"
                    @click="removeLexicalRelation(item, idx)"
                  />
                </div>
                <KodexaTextArea
                  v-model="lexicalRelation.value"
                  class="w-full"
                  :rows="3"
                />
              </li>
            </ul>

            <div class="space-y-2">
              <KodexaButton
                type="secondary"
                size="small"
                icon="plus"
                class="w-full sm:w-auto"
                @click="addLexicalRelation(item)"
              >
                Add Lexical Relation
              </KodexaButton>
            </div>
          </div>
          <div class="flex shrink-0">
            <KodexaButton
              class="mr-2" type="secondary" size="small" icon="edit" @click="editItem(index)"
            >
              Edit
            </KodexaButton>
            <KodexaButton
              type="danger" size="small" icon="delete" @click="removeItem(index)"
            >
              Delete
            </KodexaButton>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<style scoped>
</style>
