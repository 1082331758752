<script lang="ts" setup>
import * as monaco from "monaco-editor";
import MonacoEditor from "monaco-editor-vue3";
import "monaco-editor/min/vs/editor/editor.main.css";

const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  language: {
    type: String,
    default: "groovy",
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  width: { type: [String, Number], default: "100%" },
  label: { type: String, default: undefined },
  minimap: { type: Boolean, default: true },
  loading: { type: Boolean, default: false },
  hint: { type: String, default: undefined },
});

const emit = defineEmits(["update:modelValue"]);

// Register languages
monaco.languages.register({ id: "groovy" });
monaco.languages.register({ id: "python" });
monaco.languages.register({ id: "json" });
monaco.languages.register({ id: "xml" });
monaco.languages.register({ id: "yaml" });
monaco.languages.register({ id: "jinja" });

monaco.languages.setMonarchTokensProvider("jinja", {
  keywords: [
    "for",
    "endfor",
    "if",
    "endif",
    "else",
    "elif",
    "set",
    "endset",
    "block",
    "endblock",
    "extends",
    "include",
    "import",
    "macro",
    "endmacro",
    "filter",
    "endfilter",
  ],
  operators: [
    "==",
    "!=",
    "<",
    ">",
    "<=",
    ">=",
    "in",
    "not in",
    "is",
    "is not",
    "and",
    "or",
    "not",
  ],
  symbols: /[=><!~?:&|+\-*/^%]+/,
  tokenizer: {
    root: [
      [/\{\{/, { token: "delimiter.curly", bracket: "@open", next: "@expression" }],
      [/\{%/, { token: "delimiter.curly", bracket: "@open", next: "@statement" }],
      [/\{#/, { token: "comment", bracket: "@open", next: "@comment" }],
      [/[^{}]+/, "text"],
    ],
    expression: [
      [/\}\}/, { token: "delimiter.curly", bracket: "@close", next: "@pop" }],
      { include: "@common" },
    ],
    statement: [
      [/%\}/, { token: "delimiter.curly", bracket: "@close", next: "@pop" }],
      { include: "@common" },
    ],
    comment: [
      [/#\}/, { token: "comment", bracket: "@close", next: "@pop" }],
      [/[^#]+/, "comment"],
    ],
    common: [
      [/[a-z_$][\w$]*/, { cases: { "@keywords": "keyword", "@default": "identifier" } }],
      { include: "@whitespace" },
      [/[{}()[\]]/, "@brackets"],
      [/[@;,.]/, "delimiter"],
      [/"/, { token: "string.quote", bracket: "@open", next: "@string" }],
    ],
    string: [
      [/[^\\"]+/, "string"],
      [/"/, { token: "string.quote", bracket: "@close", next: "@pop" }],
    ],
    whitespace: [
      [/[ \t\r\n]+/, "white"],
      [/\/\*/, "comment", "@comment"],
      [/\/\/.*$/, "comment"],
    ],
  },
});

// Provide Jinja Autocompletions
monaco.languages.registerCompletionItemProvider("jinja", {
  provideCompletionItems: () => {
    const suggestions = [
      {
        label: "for",
        kind: monaco.languages.CompletionItemKind.Keyword,
        insertText: "for ${1:item} in ${2:iterable}\n  ${3}\nendfor",
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        documentation: "For loop",
      },
      {
        label: "if",
        kind: monaco.languages.CompletionItemKind.Keyword,
        insertText: "if ${1:condition}\n  ${2}\nendif",
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        documentation: "If statement",
      },
      // Add more snippets as needed
    ];
    return { suggestions };
  },
});

monaco.languages.setMonarchTokensProvider("groovy", {
  defaultToken: "invalid",
  keywords: [
    "abstract",
    "as",
    "assert",
    "boolean",
    "break",
    "byte",
    "case",
    "catch",
    "char",
    "class",
    "const",
    "continue",
    "def",
    "default",
    "do",
    "double",
    "else",
    "enum",
    "extends",
    "false",
    "final",
    "finally",
    "float",
    "for",
    "goto",
    "if",
    "implements",
    "import",
    "in",
    "instanceof",
    "int",
    "interface",
    "long",
    "native",
    "new",
    "null",
    "package",
    "private",
    "protected",
    "public",
    "return",
    "short",
    "static",
    "strictfp",
    "super",
    "switch",
    "synchronized",
    "this",
    "throw",
    "throws",
    "trait",
    "transient",
    "true",
    "try",
    "void",
    "volatile",
    "while",
  ],

  typeKeywords: [
    "boolean",
    "double",
    "byte",
    "int",
    "short",
    "char",
    "void",
    "long",
    "float",
  ],

  operators: [
    "=",
    ">",
    "<",
    "!",
    "~",
    "?",
    ":",
    "==",
    "<=",
    ">=",
    "!=",
    "&&",
    "||",
    "++",
    "--",
    "+",
    "-",
    "*",
    "/",
    "&",
    "|",
    "^",
    "%",
    "<<",
    ">>",
    ">>>",
    "+=",
    "-=",
    "*=",
    "/=",
    "&=",
    "|=",
    "^=",
    "%=",
    "<<=",
    ">>=",
    ">>>=",
  ],

  symbols: /[=><!~?:&|+\-*/^%]+/,
  escapes: /\\(?:[abfnrtv\\"']|x[0-9A-Fa-f]{1,4}|u[0-9A-Fa-f]{4}|U[0-9A-Fa-f]{8})/,

  tokenizer: {
    root: [
      [/[a-z_$][\w$]*/, { cases: { "@typeKeywords": "keyword", "@keywords": "keyword", "@default": "identifier" } }],
      [/[A-Z][\w$]*/, "type.identifier"], // to show class names nicely

      // whitespace
      { include: "@whitespace" },

      // delimiters and operators
      [/[{}()[\]]/, "@brackets"],
      [/[<>](?!@symbols)/, "@brackets"],
      [/@symbols/, { cases: { "@operators": "operator", "@default": "" } }],

      // @ annotations.
      [/@\s*[a-z_$][\w$]*/i, { token: "annotation" }],

      // numbers
      [/\d*\.\d+(e[\-+]?\d+)?/i, "number.float"],
      [/0x[0-9a-f]+/i, "number.hex"],
      [/\d+/, "number"],

      // delimiter: after number because of .\d floats
      [/[;,.]/, "delimiter"],

      // strings
      [/"([^"\\]|\\.)*$/, "string.invalid"], // non-teminated string
      [/'([^'\\]|\\.)*$/, "string.invalid"], // non-teminated string
      [/"/, "string", "@string_double"],
      [/'/, "string", "@string_single"],
      [/\$\{/, { token: "delimiter.bracket", next: "@bracketCounting" }],
      [/\$/, "string"],
    ],

    bracketCounting: [
      [/\{/, "delimiter.bracket", "@bracketCounting"],
      [/\}/, { token: "delimiter.bracket", next: "@pop" }],
      { include: "root" },
    ],

    string_double: [
      [/[^\\"]+/, "string"],
      [/@escapes/, "string.escape"],
      [/\\./, "string.escape.invalid"],
      [/"/, "string", "@pop"],
    ],

    string_single: [
      [/[^\\']+/, "string"],
      [/@escapes/, "string.escape"],
      [/\\./, "string.escape.invalid"],
      [/'/, "string", "@pop"],
    ],

    whitespace: [
      [/[ \t\r\n]+/, "white"],
      [/\/\*/, "comment", "@comment"],
      [/\/\/.*$/, "comment"],
    ],

    comment: [
      [/[^/*]+/, "comment"],
      [/\*\//, "comment", "@pop"],
      [/[/*]/, "comment"],
    ],
  },
});

// Provide Groovy Autocompletions
monaco.languages.registerCompletionItemProvider("groovy", {
  provideCompletionItems: () => {
    const suggestions = [
      {
        label: "println",
        kind: monaco.languages.CompletionItemKind.Function,
        insertText: "println(${1:text})",
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        documentation: "Prints the text to the console",
      },
      {
        label: "def",
        kind: monaco.languages.CompletionItemKind.Keyword,
        insertText: "def ${1:name} = ${2:value}",
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        documentation: "Declares a variable",
      },
    ];
    return { suggestions };
  },
});

// Register SpEL language
monaco.languages.register({ id: "spel" });

monaco.languages.setMonarchTokensProvider("spel", {
  keywords: [
    "true",
    "false",
    "null",
    "new",
  ],
  operators: [
    "==",
    "!=",
    "<",
    ">",
    "<=",
    ">=",
    "&&",
    "||",
    "!",
    "?",
    ":",
    "=",
    "+",
    "-",
    "*",
    "/",
    "%",
  ],
  symbols: /[=><!~?:&|+\-*/^%]+/,
  tokenizer: {
    root: [
      // Identifiers and keywords
      [/[a-z_$][\w$]*/, {
        cases: {
          "@keywords": "keyword",
          "@default": "identifier",
        },
      }],
      // Whitespace
      { include: "@whitespace" },
      // Delimiters and operators
      [/[{}()[\]]/, "@brackets"],
      [/[<>](?!@symbols)/, "@brackets"],
      [/@symbols/, {
        cases: {
          "@operators": "operator",
          "@default": "",
        },
      }],
      // Numbers
      [/\d*\.\d+(e[\-+]?\d+)?/i, "number.float"],
      [/\d+/, "number"],
      // Strings
      [/"([^"\\]|\\.)*$/, "string.invalid"], // non-terminated string
      [/"/, { token: "string.quote", bracket: "@open", next: "@string" }],
    ],
    string: [
      [/[^\\"]+/, "string"],
      [/\\./, "string.escape"],
      [/"/, { token: "string.quote", bracket: "@close", next: "@pop" }],
    ],
    whitespace: [
      [/[ \t\r\n]+/, "white"],
    ],
  },
});

// Provide SpEL Autocompletions
monaco.languages.registerCompletionItemProvider("spel", {
  provideCompletionItems: () => {
    const suggestions = [
      {
        label: "T",
        kind: monaco.languages.CompletionItemKind.Function,
        insertText: "T(${1:type})",
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        documentation: "Access a Java type",
      },
      {
        label: "new",
        kind: monaco.languages.CompletionItemKind.Keyword,
        insertText: "new ${1:ClassName}(${2:args})",
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        documentation: "Create a new instance of a class",
      },
      {
        label: "#this",
        kind: monaco.languages.CompletionItemKind.Keyword,
        insertText: "#this",
        documentation: "Reference the current context object",
      },
      {
        label: "#root",
        kind: monaco.languages.CompletionItemKind.Keyword,
        insertText: "#root",
        documentation: "Reference the root context object",
      },
    ];
    return { suggestions };
  },
});

const options = ref({
  colorDecorators: true,
  lineHeight: 14,
  tabSize: 2,
  lineNumbers: false,
  padding: 0,
  lineDecorationsWidth: 2,
  automaticLayout: true,
  minimap: { enabled: props.minimap },
  wordwrap: "off",
  mouseWheelZoom: true,
});

const localValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});
</script>

<template>
  <div>
    <label v-if="label" for="name" class="kodexa-label"> {{ label }}</label>
    <p v-if="hint" class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
      {{ hint }}
    </p>
    <MonacoEditor
      v-model:value="localValue"
      theme="vs"
      :style="{
        'width': '100%', 'padding': '2px', 'padding-top': '5px',
      }"
      :options="options"
      :language="language"
      :read-only="readOnly"
      @change="() => emit('update:modelValue', localValue)"
    />
    <div v-if="loading" class="loading-overlay">
      <div class="loading-spinner" />
    </div>
  </div>
</template>

<style scoped>
.kodexa-label {
  @apply block text-sm text-gray-700 mb-1
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
