<!--
  - Copyright (C) 2024 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import { TabStrip } from "@progress/kendo-vue-layout";
import { storeToRefs } from "pinia";
import type { PropType, Ref } from "vue";
import { v4 as uuidv4 } from "uuid";
import { createDataFormViewerStore } from "~/store/useDataFormView";
import type { TagMetadata } from "~/store/useProject";
import appStore from "~/store";
import type { Card, DataObject, Taxon } from "~/model";
import {Toolbar} from "@progress/kendo-vue-buttons";
import {log} from "~/utils/logger";

const props = defineProps({
  card: {
    type: Object as PropType<Card>,
    required: true,
  },
  designMode: {
    type: Boolean,
    required: false,
    default: false,
  },
  viewId: {
    type: String,
    required: true,
  },
  parentDataObject: {
    type: Object as PropType<DataObject>,
    required: false,
    default: undefined,
  },
  parentTagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: false,
    default: undefined,
  },
});

defineEmits(["addCard", "selectCard", "removeCard"]);

const { contentTaxonomies } = storeToRefs(appStore.projectStore);

const rootTaxons = computed(() => {
  // We want to get all the root taxons from the content taxonomies
  const rootTaxons: Taxon[] = [];
  if (!contentTaxonomies.value) {
    return [];
  }

  contentTaxonomies.value.forEach((taxonomy) => {
    // add all the taxons
    taxonomy.taxons.forEach((taxon) => {
      rootTaxons.push(taxon);
    });
  });

  return rootTaxons;
});

const tabs = computed(() => {
  return rootTaxons.value
    .filter((taxon: Taxon) => taxon.enabled)
    .map((taxon: Taxon) => {
      return {
        id: taxon.id,
        title: taxon.label,
        icon: "table",
        content: "tabContent",
        titleRender: "titleRender",
        taxon,
      };
    });
});

const currentTab: Ref<number> = ref(0);

function onSelect(e: any) {
  currentTab.value = e.selected;
}

function getCard(id: string) {
  const taxon = rootTaxons.value.find((taxon) => {
    return taxon.id === id;
  });

  if (!taxon) {
    return;
  }

  return {
    id: uuidv4(),
    type: "transposedGrid",
    properties: {
      title: taxon.label,
      icon: "folder",
      groupTaxon: taxon.name,
    },
    children: [],
  };
}

const { sidecarPanelOpen } = storeToRefs(appStore.workspaceStore);

function toggleSidecar() {
  appStore.workspaceStore.toggleSidecar();
}
</script>

<template>
  <div style="width: 100%">
    <TabStrip :selected="currentTab" :tabs="tabs" class="-mt-1" @select="onSelect">
      <template #tabContent="{ props }">
        <div style="width: 100%">
          <Toolbar
            class="bg-gray-100"
          >
            <KodexaButton :icon="sidecarPanelOpen ? 'close' : 'dock-right'" size="small" :title="sidecarPanelOpen ? 'Close Sidecar' : 'Open Sidecar'" type="secondary" @click="toggleSidecar">
              {{ sidecarPanelOpen ? 'Close' : 'Open' }} Sidecar
            </KodexaButton>
          </Toolbar>
          <KodexaFormTransposedGrid
            style="width: 100%"
            :card="getCard(props.dataItem.id)"
            :parent-data-object="parentDataObject"
            :parent-tag-metadata="parentTagMetadata"
            :view-id="viewId"
          />
        </div>
      </template>
      <template #titleRender="{ props }">
        <span class="k-item k-state-default">
          <span class="text-sm p-0">{{ props.title }}</span>
        </span>
      </template>
    </TabStrip>
  </div>
</template>

<style scoped>
</style>
