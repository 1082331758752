<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { ref, computed } from 'vue';
import { storeToRefs } from "pinia";
import appStore from "~/store";
import { useCreateTaskTemplate } from "~/api/task-templates/task-templates";
import type { TaskTemplate } from "~/model";
import { updateHandler } from "~/utils/error-handler";

// Props and Emits
const props = defineProps<{
  modelValue: boolean
}>();

const emit = defineEmits<{
  'update:modelValue': [value: boolean]
  'refetch-templates': []
}>();

// Store
const { project } = storeToRefs(appStore.projectStore);

// State
const isOpen = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});

const createTemplateMutation = useCreateTaskTemplate();

const newTemplate = ref<Partial<TaskTemplate>>({
  title: '',
  description: '',
  project: project.value,
  metadata: {}
});

const requiredFieldsError = ref<Record<string, string>>({});
const requiredFields = ["title", "description"] as const;

// Methods
const close = () => {
  newTemplate.value = {
    title: '',
    description: '',
    project: project.value,
    metadata: {}
  };
  requiredFieldsError.value = {};
  emit("update:modelValue", false);
};

const clearError = (field: string) => {
  if (requiredFieldsError.value[field]) {
    delete requiredFieldsError.value[field];
    requiredFieldsError.value = { ...requiredFieldsError.value };
  }
};

const validateFields = () => {
  requiredFields.forEach((field) => {
    if (!newTemplate.value[field as keyof TaskTemplate]) {
      requiredFieldsError.value[field] = "This field is required";
    } else {
      delete requiredFieldsError.value[field];
    }
  });

  return Object.keys(requiredFieldsError.value).length === 0;
};

const createNewTemplate = async () => {
  if (!validateFields()) return;

  try {
    await createTemplateMutation.mutateAsync({
      data: newTemplate.value as TaskTemplate
    });

    await updateHandler(
      Promise.resolve(),
      "Template created successfully"
    );

    emit("refetch-templates");
    close();
  } catch (error) {
    console.error("Error creating template:", error);
    await updateHandler(
      Promise.reject(error),
      "Failed to create template"
    );
  }
};
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative z-10" @close="close">
      <div class="fixed inset-0" />
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                <div class="flex h-full flex-col bg-white shadow-xl">
                  <!-- Header -->
                  <div class="bg-gray-50 px-4 py-6 sm:px-6">
                    <div class="flex items-start justify-between space-x-3">
                      <div class="space-y-1">
                        <DialogTitle class="text-base font-semibold leading-6 text-gray-900">
                          New Task Template
                        </DialogTitle>
                        <p class="text-sm text-gray-500">
                          Get started by filling in the information below to create a new task template.
                        </p>
                      </div>
                      <div class="shrink-0 border-gray-200 px-4 py-5 pr-0">
                        <div class="flex justify-end space-x-3">
                          <KodexaButton
                            id="cancelNewTemplate"
                            icon="cancel"
                            type="secondary"
                            size="small"
                            @click="close"
                          >
                            Cancel
                          </KodexaButton>
                          <KodexaButton
                            id="createNewTemplate"
                            icon="plus"
                            type="primary"
                            size="small"
                            :loading="createTemplateMutation.isLoading"
                            @click="createNewTemplate"
                          >
                            Create
                          </KodexaButton>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Form -->
                  <div class="flex-1 overflow-y-auto">
                    <div class="space-y-6 p-4 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:p-6">
                      <!-- Name -->
                      <div class="space-y-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0">
                        <label
                          for="title"
                          class="block text-sm font-medium text-gray-900 sm:mt-1.5"
                        >
                          Template Name
                        </label>
                        <div class="sm:col-span-2">
                          <KodexaTextInput
                            id="title"
                            v-model="newTemplate.title"
                            name="title"
                            :errors="requiredFieldsError"
                            @input="clearError('title')"
                          />
                        </div>
                      </div>

                      <!-- Description -->
                      <div class="space-y-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0">
                        <label
                          for="description"
                          class="block text-sm font-medium text-gray-900 sm:mt-1.5"
                        >
                          Description
                        </label>
                        <div class="sm:col-span-2">
                          <KodexaTextArea
                            id="description"
                            v-model="newTemplate.description"
                            name="description"
                            :rows="4"
                            :errors="requiredFieldsError"
                            @input="clearError('description')"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
