<script lang="ts" setup>
import { ref } from "vue";
import type { Dashboard } from "~/model";

const currentNavigation = ref(undefined);

const defaultDashboards = computed(() => {
  // if we don't have any dashboards defined
  // then we will want to create a dashboard for
  // workspaces first, then one for each document store
  // then one for each training store
  return [
    {
      orgSlug: "kodexa",
      slug: "home-dashboard",
      version: "1.0.0",
      ref: "kodexa/project-dashboard",
      name: "Home",
      description: "A dashboard for your project",
      type: "dashboard",
      widgets: [
        {
          id: "1",
          type: "projectsCount",
          name: "Projects Count",
          properties: {
            defaultPosition: { col: 1, colSpan: 6, rowSpan: 2, order: 1 },
          },
        },
        {
          id: "3",
          type: "mostRecentProject",
          name: "My Recent Projects",
          properties: {
            defaultPosition: { col: 7, colSpan: 6, rowSpan: 2, order: 1 },
          },
        },
        {
          id: "5",
          type: "projects",
          name: "Projects",
          properties: {
            defaultPosition: { col: 1, colSpan: 12, rowSpan: 12, order: 1 },
          },
        },
      ],
    } as Dashboard,
  ] as Dashboard[];
});

const orderedDashboards = computed(() => {
  return defaultDashboards.value;
});

watch(orderedDashboards, () => {
  // if the current navigation isn't in the ordered dashboards then make it undefined
  if (orderedDashboards.value && orderedDashboards.value.length > 0 && currentNavigation.value) {
    const found = orderedDashboards.value.find(dashboard => dashboard.ref === currentNavigation.value.ref);
    if (!found) {
      currentNavigation.value = undefined;
    }
  }

  if (orderedDashboards.value && orderedDashboards.value.length > 0 && currentNavigation.value === undefined) {
    currentNavigation.value = orderedDashboards.value[0];
  }
}, {
  immediate: true,
  deep: true,
});

watch(orderedDashboards, () => {
  if (orderedDashboards.value && orderedDashboards.value.length > 0 && currentNavigation.value === undefined) {
    currentNavigation.value = orderedDashboards.value[0];
  }
}, {
  immediate: true,
  deep: true,
});
</script>

<template>
  <div class="bg-white">
    <header>
      <div>
        <header>
          <div class="border-b border-gray-200 dark:border-gray-700">
            <ul class="-mb-px ml-2 flex flex-wrap text-center text-sm font-medium text-gray-500 dark:text-gray-400">
              <li
                v-for="(item, idx) in orderedDashboards" :key="item.ref"
                class="mr-2"
                @click="currentNavigation = item"
              >
                <a
                  :class="item.ref === currentNavigation?.ref ? 'inline-flex items-center justify-center p-4 text-theme-primary border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group' : 'inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'"
                  class="text-md"
                >
                  <MaterialDesignIcon
                    v-if="item?.icon" :name="item.icon" class="text-theme-primary mr-3"
                    size="18"
                  />
                  {{ item?.name }}
                </a>
              </li>
            </ul>
          </div>
        </header>
      </div>
    </header>
    <div>
      <KodexaDashboard
        v-if="currentNavigation"
        :allow-delete="false"
        :model-value="currentNavigation"
        :setup-mode="false"
      />
    </div>
  </div>
</template>
