<script setup lang="ts">
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import type { GridColumnProps } from "@progress/kendo-vue-grid/dist/npm/interfaces/GridColumnProps";
import * as dayjs from "dayjs";

import relativeTime from "dayjs/plugin/relativeTime";
import { storeToRefs } from "pinia";
import type { PropType } from "vue";
import { ref } from "vue";
import appStore from "~/store";
import { log } from "~/utils/logger";
import { createGridHelper } from "~/store/useGridHelper";
import type { Assistant, DashboardWidget, Execution } from "~/model";
import { useListExecutions } from "~/api/executions/executions";

const props = defineProps({
  widget: {
    type: Object as PropType<DashboardWidget>,
  },
});

dayjs.extend(relativeTime);

const { project, assistants } = storeToRefs(appStore.projectStore);

// Setup our grid helpers
const gridHelper = createGridHelper(`project-activity-${project.value.id}`, {
  pageSize: 10,
  filter: "",
  sort: "",
  query: "",
  page: 1,
}, `assistant.id in [${assistants.value.map((a: Assistant) => `'${a.id}'`).join(",")}]`, [{
  field: "startDate",
  dir: "desc",
}]);

const {
  pageSettings,
  sort,
  filter,
  gridQuery,
} = storeToRefs(gridHelper);

// Query
const {
  isLoading,
  isError,
  data,
  error,
} = useListExecutions(gridQuery.value, { query: { refetchInterval: 20000, retry: false } });

watch(data, (newValue) => {
  if (newValue && newValue.totalElements) {
    gridHelper.setTotal(newValue.totalElements);
    gridHelper.saveState();
  }
});

watch(assistants, (newValue) => {
  if (newValue) {
    gridHelper.setBaseFilter(`assistant.id in [${assistants.value.map((a: Assistant) => `'${a.id}'`).join(",")}]`);
  }
}, { deep: true, immediate: true });

const columns: GridColumnProps[] = [
  { title: "Start", field: "startDate", filter: "date", cell: "startDate" },
  { title: "End", field: "endDate", filter: "date", cell: "endDate" },
  { title: "Assistant", field: "assistant.name", cell: "assistant" },
  { title: "Description", field: "description" },
  { title: "Document", field: "documentFamily", cell: "documentFamily" },
  { title: "Content Objects", field: "contentObjects", cell: "contentObjects" },
  { title: "Status", field: "status", cell: "status" },
  { title: "", cell: "actions" },
];

const showPopup = ref(false);
const selectedExecution = ref<Execution | null>(null);

function viewDetails(execution: Execution) {
  log.info("View details", execution);
  selectedExecution.value = execution;
  showPopup.value = true;
}

function getAssistant(assistantId: string) {
  const assistant = assistants.value.find((a: Assistant) => a.id === assistantId);
  if (assistant) {
    return assistant.name;
  }
  return "";
}

const gridStyle = computed(() => {
  if (props.widget?.properties?.style !== undefined) {
    return props.widget?.properties?.style;
  } else {
    const rowSpan = props.widget?.properties?.defaultPosition?.rowSpan || 6;
    const height = (rowSpan * 85) - 130;
    return {
      height: `${height}px`,
    };
  }
});

const startingDate = computed(() => {
  const date = new Date();
  date.setDate(date.getDate() - 2);
  return date;
});

const startingFilter = {
  logic: "and",
  filters: [{
    field: "startDate",
    operator: "gt",
    value: startingDate.value,
  }],
};

gridHelper.filterChange({ filter: startingFilter });
</script>

<template>
  <div class="mt-4">
    <Grid
      class="mx-4"
      :style="gridStyle"
      :data-items="data ? data.content : []"
      :pageable="pageSettings"
      :loader="isLoading"
      :sort="sort"
      :skip="pageSettings.skip as number"
      :total="pageSettings.total as number"
      :page-size="pageSettings.take as number"
      :take="pageSettings.take as number"
      :columns="columns"
      :filterable="true"
      :filter="filter"
      :resizable="true"
      :sortable="true"
      @pagechange="gridHelper.pageChangeHandler($event)"
      @filterchange="gridHelper.filterChange($event)"
      @sortchange="gridHelper.sortChange($event)"
    >
      <GridNoRecords>
        <KodexaGridNoRecords
          :error="error" :is-loading="isLoading" :is-error="isError"
          no-records-message="No activity found"
        />
      </GridNoRecords>
      <template #startDate="{ props }">
        <td><KodexaDateTimeView :date="props.dataItem.startDate" /></td>
      </template>
      <template #endDate="{ props }">
        <td><KodexaDateTimeView :date="props.dataItem.endDate" /></td>
      </template>
      <template #assistant="{ props }">
        <td>{{ getAssistant(props.dataItem.assistantId) }}</td>
      </template>
      <template #contentObjects="{ props }">
        <td>
          <ul>
            <li v-for="contentObject in props.dataItem.contentObjects" :key="contentObject.id">
              <MaterialDesignIcon :name="contentObject.contentType == 'NATIVE' ? 'file' : 'file'" size="16" />
              {{ contentObject.metadata?.path }}
            </li>
          </ul>
        </td>
      </template>
      <template #documentFamily="{ props }">
        <td>
          {{ props.dataItem.documentFamily?.path }}
        </td>
      </template>
      <template #actions="{ props }">
        <td>
          <KodexaButton type="secondary" size="small" @click="viewDetails(props.dataItem)">
            View
          </KodexaButton>
        </td>
      </template>
      <template #status="{ props }">
        <td>
          <span style="display: flex; align-items: center;">
            <KodexaStatusIcon :status="props.dataItem.status" :label="props.dataItem.status" @click="viewDetails(props.dataItem)" />
            <span style="margin-left: 5px;">{{ props.dataItem.status.replace(/\b\w/g, char => char.toUpperCase()) }}</span>
          </span>
        </td>
      </template>
    </Grid>
    <KodexaExecutionPopup v-if="selectedExecution" v-model="showPopup" :executions="[selectedExecution]" :popup-logs="true" />
  </div>
</template>

<style scoped>

</style>
