<script setup lang="ts">
import type { PropType } from "vue";
import type { Task } from "~/model";

defineProps({
  task: {
    type: Object as PropType<Task>,
    required: true,
  },
  includeOrganization: {
    type: Boolean,
    default: false,
  },
  includeTags: {
    type: Boolean,
    default: true,
  },
});

defineEmits(["updated"]);

function getTaskLink(task: Task) {
  return `/f/t/${task.id}`;
}
</script>

<template>
  <div>
    <div class="flex items-center">
      <div class="ml-1">
        <RouterLink :to="getTaskLink(task)" class="cursor-pointer text-lg font-bold text-gray-900">
          <span v-if="task?.title" id="taskTitle" class="wrap-text">{{ task?.title }}</span>
          <span v-else id="untitledProject" class="wrap-text">Untitled Task</span>
        </RouterLink>
        <div class="text-xs text-gray-700">
          {{ task.description }}
        </div>
      </div>
    </div>
  </div>
</template>
