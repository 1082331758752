<script setup lang="ts">
import type { PropType } from "vue";
import { storeToRefs } from "pinia";
import type { DashboardWidget } from "~/model";
import appStore from "~/store";
import type { LoadingEvent } from "~/store/usePlatform";
import Workspace from "~/views/app/organizations/project/workspace/workspace.vue";
import {log} from "~/utils/logger";

const props = defineProps({
  widget: {
    type: Object as PropType<DashboardWidget>,
    required: false,
  },
});

const { currentWorkspaceId } = storeToRefs(appStore.workspaceStore);
const loaded = ref(false);
watch(
  () => [props.widget, currentWorkspaceId],
  () => {
    log.info("Loading workspace widget");
    if (props.widget) {
      log.info(`Loading workspace widget with workspace [${props.widget?.properties?.workspace}]`);
      const workspaceId = props.widget?.properties?.workspace;
      if (workspaceId && currentWorkspaceId.value !== workspaceId) {
        const loadingEvent = {
          id: "workspace",
          title: "Loading Workspace",
          subtitle: "",
          progress: undefined,
          progressMax: undefined,
        } as LoadingEvent;
        appStore.platformStore.addLoadingEvent(loadingEvent);
        appStore.workspaceStore.loadWorkspace(workspaceId).then(() => {
          appStore.platformStore.removeLoadingEvent(loadingEvent);
          loaded.value = true;
        });
      } else {
        loaded.value = true;
      }
    } else {
      loaded.value = true;
    }
  },
  { immediate: true, deep: true },
);

const docStoreRef = computed(() => {
  if (props.widget?.properties?.documentStoreRef) {
    return `store-${props.widget.properties.documentStoreRef}`;
  }
  return undefined;
});

const dataFormRef = computed(() => {
  if (props.widget?.properties?.dataFormRef) {
    return props.widget.properties.dataFormRef;
  }
  return undefined;
});
const openSidecar = computed(() => {
  if (props.widget?.properties?.openSidecar !== undefined) {
    return props.widget.properties.openSidecar;
  }
  return true;
});
</script>

<template>
  <div>
    <div v-if="currentWorkspaceId && loaded">
      <Workspace :key="currentWorkspaceId" :workspace-options="{ hideToolbar: true, hideClose: true, openPanel: docStoreRef, openSidecar, openForm: dataFormRef }" />
    </div>
  </div>
</template>
