/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  ExceptionResponse,
  ListTeamsParams,
  PageTeam,
  Team,
  ThrowableProblem,
  User
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get a resource with the provided ID
 */
export const getTeam = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<Team>(
      {url: `/api/teams/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTeamQueryKey = (id: MaybeRef<string>,) => {
    return ['api','teams',id] as const;
    }

    
export const getGetTeamQueryOptions = <TData = Awaited<ReturnType<typeof getTeam>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTeam>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetTeamQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTeam>>> = ({ signal }) => getTeam(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTeam>>, TError, TData> 
}

export type GetTeamQueryResult = NonNullable<Awaited<ReturnType<typeof getTeam>>>
export type GetTeamQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetTeam<TData = Awaited<ReturnType<typeof getTeam>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTeam>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetTeamQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the given instance
 */
export const updateTeam = (
    id: MaybeRef<string>,
    team: MaybeRef<Team>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
team = unref(team);
      
      return customAxios<Team>(
      {url: `/api/teams/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: team
    },
      options);
    }
  


export const getUpdateTeamMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTeam>>, TError,{id: string;data: Team}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateTeam>>, TError,{id: string;data: Team}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateTeam>>, {id: string;data: Team}> = (props) => {
          const {id,data} = props ?? {};

          return  updateTeam(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateTeamMutationResult = NonNullable<Awaited<ReturnType<typeof updateTeam>>>
    export type UpdateTeamMutationBody = Team
    export type UpdateTeamMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateTeam = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTeam>>, TError,{id: string;data: Team}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateTeam>>,
        TError,
        {id: string;data: Team},
        TContext
      > => {

      const mutationOptions = getUpdateTeamMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteTeam = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/teams/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteTeamMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTeam>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteTeam>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTeam>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteTeam(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteTeamMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTeam>>>
    
    export type DeleteTeamMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteTeam = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTeam>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteTeam>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteTeamMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listTeams = (
    params?: MaybeRef<ListTeamsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageTeam>(
      {url: `/api/teams`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListTeamsQueryKey = (params?: MaybeRef<ListTeamsParams>,) => {
    return ['api','teams', ...(params ? [params]: [])] as const;
    }

    
export const getListTeamsQueryOptions = <TData = Awaited<ReturnType<typeof listTeams>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListTeamsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTeams>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListTeamsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listTeams>>> = ({ signal }) => listTeams(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listTeams>>, TError, TData> 
}

export type ListTeamsQueryResult = NonNullable<Awaited<ReturnType<typeof listTeams>>>
export type ListTeamsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useListTeams<TData = Awaited<ReturnType<typeof listTeams>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListTeamsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTeams>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListTeamsQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createTeam = (
    team: MaybeRef<Team>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      team = unref(team);
      
      return customAxios<Team>(
      {url: `/api/teams`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: team, signal
    },
      options);
    }
  


export const getCreateTeamMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTeam>>, TError,{data: Team}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createTeam>>, TError,{data: Team}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTeam>>, {data: Team}> = (props) => {
          const {data} = props ?? {};

          return  createTeam(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateTeamMutationResult = NonNullable<Awaited<ReturnType<typeof createTeam>>>
    export type CreateTeamMutationBody = Team
    export type CreateTeamMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateTeam = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTeam>>, TError,{data: Team}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof createTeam>>,
        TError,
        {data: Team},
        TContext
      > => {

      const mutationOptions = getCreateTeamMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const addMemberToTeam = (
    id: MaybeRef<string>,
    user: MaybeRef<NonReadonly<User>>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
user = unref(user);
      
      return customAxios<void>(
      {url: `/api/teams/${id}/members`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: user, signal
    },
      options);
    }
  


export const getAddMemberToTeamMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addMemberToTeam>>, TError,{id: string;data: NonReadonly<User>}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof addMemberToTeam>>, TError,{id: string;data: NonReadonly<User>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addMemberToTeam>>, {id: string;data: NonReadonly<User>}> = (props) => {
          const {id,data} = props ?? {};

          return  addMemberToTeam(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddMemberToTeamMutationResult = NonNullable<Awaited<ReturnType<typeof addMemberToTeam>>>
    export type AddMemberToTeamMutationBody = NonReadonly<User>
    export type AddMemberToTeamMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useAddMemberToTeam = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addMemberToTeam>>, TError,{id: string;data: NonReadonly<User>}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof addMemberToTeam>>,
        TError,
        {id: string;data: NonReadonly<User>},
        TContext
      > => {

      const mutationOptions = getAddMemberToTeamMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const addDashboardToTeam = (
    id: MaybeRef<string>,
    dashboardRef: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
dashboardRef = unref(dashboardRef);
      
      return customAxios<void>(
      {url: `/api/teams/${id}/dashboards/${dashboardRef}`, method: 'POST', signal
    },
      options);
    }
  


export const getAddDashboardToTeamMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addDashboardToTeam>>, TError,{id: string;dashboardRef: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof addDashboardToTeam>>, TError,{id: string;dashboardRef: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addDashboardToTeam>>, {id: string;dashboardRef: string}> = (props) => {
          const {id,dashboardRef} = props ?? {};

          return  addDashboardToTeam(id,dashboardRef,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddDashboardToTeamMutationResult = NonNullable<Awaited<ReturnType<typeof addDashboardToTeam>>>
    
    export type AddDashboardToTeamMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useAddDashboardToTeam = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addDashboardToTeam>>, TError,{id: string;dashboardRef: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof addDashboardToTeam>>,
        TError,
        {id: string;dashboardRef: string},
        TContext
      > => {

      const mutationOptions = getAddDashboardToTeamMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const removeDashboardFromTeam = (
    id: MaybeRef<string>,
    dashboardRef: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
dashboardRef = unref(dashboardRef);
      
      return customAxios<void>(
      {url: `/api/teams/${id}/dashboards/${dashboardRef}`, method: 'DELETE'
    },
      options);
    }
  


export const getRemoveDashboardFromTeamMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeDashboardFromTeam>>, TError,{id: string;dashboardRef: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof removeDashboardFromTeam>>, TError,{id: string;dashboardRef: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeDashboardFromTeam>>, {id: string;dashboardRef: string}> = (props) => {
          const {id,dashboardRef} = props ?? {};

          return  removeDashboardFromTeam(id,dashboardRef,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RemoveDashboardFromTeamMutationResult = NonNullable<Awaited<ReturnType<typeof removeDashboardFromTeam>>>
    
    export type RemoveDashboardFromTeamMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useRemoveDashboardFromTeam = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeDashboardFromTeam>>, TError,{id: string;dashboardRef: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof removeDashboardFromTeam>>,
        TError,
        {id: string;dashboardRef: string},
        TContext
      > => {

      const mutationOptions = getRemoveDashboardFromTeamMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForTeam = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/teams/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForTeamQueryKey = (id: MaybeRef<string>,) => {
    return ['api','teams',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForTeamQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForTeam>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForTeam>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForTeamQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForTeam>>> = ({ signal }) => getChangeSequenceForTeam(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForTeam>>, TError, TData> 
}

export type GetChangeSequenceForTeamQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForTeam>>>
export type GetChangeSequenceForTeamQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetChangeSequenceForTeam<TData = Awaited<ReturnType<typeof getChangeSequenceForTeam>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForTeam>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetChangeSequenceForTeamQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



export const removeMemberFromTeam = (
    id: MaybeRef<string>,
    userId: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
userId = unref(userId);
      
      return customAxios<void>(
      {url: `/api/teams/${id}/members/${userId}`, method: 'DELETE'
    },
      options);
    }
  


export const getRemoveMemberFromTeamMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeMemberFromTeam>>, TError,{id: string;userId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof removeMemberFromTeam>>, TError,{id: string;userId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeMemberFromTeam>>, {id: string;userId: string}> = (props) => {
          const {id,userId} = props ?? {};

          return  removeMemberFromTeam(id,userId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RemoveMemberFromTeamMutationResult = NonNullable<Awaited<ReturnType<typeof removeMemberFromTeam>>>
    
    export type RemoveMemberFromTeamMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useRemoveMemberFromTeam = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeMemberFromTeam>>, TError,{id: string;userId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof removeMemberFromTeam>>,
        TError,
        {id: string;userId: string},
        TContext
      > => {

      const mutationOptions = getRemoveMemberFromTeamMutationOptions(options);

      return useMutation(mutationOptions);
    }
    