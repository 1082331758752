/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  ExceptionResponse,
  ListPlatformEventsParams,
  PagePlatformEvent,
  ThrowableProblem
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * List a page of the events
 */
export const listPlatformEvents = (
    params?: MaybeRef<ListPlatformEventsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PagePlatformEvent>(
      {url: `/api/events`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListPlatformEventsQueryKey = (params?: MaybeRef<ListPlatformEventsParams>,) => {
    return ['api','events', ...(params ? [params]: [])] as const;
    }

    
export const getListPlatformEventsQueryOptions = <TData = Awaited<ReturnType<typeof listPlatformEvents>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListPlatformEventsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listPlatformEvents>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListPlatformEventsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listPlatformEvents>>> = ({ signal }) => listPlatformEvents(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listPlatformEvents>>, TError, TData> 
}

export type ListPlatformEventsQueryResult = NonNullable<Awaited<ReturnType<typeof listPlatformEvents>>>
export type ListPlatformEventsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useListPlatformEvents<TData = Awaited<ReturnType<typeof listPlatformEvents>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListPlatformEventsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listPlatformEvents>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListPlatformEventsQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



