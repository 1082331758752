<script setup lang="ts">
import { Button, SplitButton, Toolbar } from "@progress/kendo-vue-buttons";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { TileLayout } from "@progress/kendo-vue-layout";
import { notify } from "notiwind";
import { storeToRefs } from "pinia";
import { v4 as uuidv4 } from "uuid";
import type { Ref } from "vue";
import { parse, stringify } from "yaml";
import { codeIcon, plusIcon, saveIcon } from "@progress/kendo-svg-icons";
import appStore from "~/store";
import { createDataFormViewerStore } from "~/store/useDataFormView";
import {
  BillChargesValidator,
  BillReadingValidator,
  BillServiceValidator,
  DateValidator,
  MatchingAggregationValidator,
  RequiredValidator,
} from "~/components/dataForm/cards/validators/cass-validators";
import { buildSimplifiedDataObjects } from "~/utils/data";
import type { Card, DataForm } from "~/model";

const props = defineProps({
  viewId: String,
  kioskMode: {
    type: Boolean,
    default: true,
    required: false,
  },
});

const emit = defineEmits(["formSaved", "dataExceptionsChanged"]);

const useDataFormViewer = createDataFormViewerStore(props.viewId as string);
const {
  dataForm,
  designMode,
  dataObjects,
  dataExceptions,
  overrideExceptionMapper,
} = storeToRefs(useDataFormViewer);

function selectCard(card: Card) {
  useDataFormViewer.selectCard(card);
}

watch(dataExceptions, () => {
  const allExceptions = dataExceptions.value.dataAttributeExceptions.concat(dataExceptions.value.dataObjectExceptions);
  emit("dataExceptionsChanged", allExceptions);
});

function addCard(parentCard: Card | undefined = undefined) {
  const newCard = {
    id: uuidv4(),
    type: "label",
    properties: {},
  } as Card;
  useDataFormViewer.addCard(newCard, parentCard);
}

function removeCard(cardDeleteProperties: any) {
  useDataFormViewer.removeCard(cardDeleteProperties.card, cardDeleteProperties.parentCard);
}

function handleReposition(updatedPositions: any) {
  useDataFormViewer.updateCardPositions(updatedPositions);
}

function getPositions() {
  return useDataFormViewer.getPositions();
}

function getCards() {
  return useDataFormViewer.getCards();
}

function buildDataFormViewer() {
  return useDataFormViewer.buildDataFormViewer();
}

const showSource: Ref<boolean> = ref(false);
const latestDataFormYaml = ref("");

function toggleSource() {
  showSource.value = !showSource.value;
}

watch(showSource, (newValue) => {
  if (newValue) {
    latestDataFormYaml.value = stringify(dataForm.value, { indent: 2 });
  } else {
    const dataFormYaml = parse(latestDataFormYaml.value) as DataForm;
    if (dataFormYaml.cards) {
      useDataFormViewer.updateCard(dataFormYaml.cards[0], true);
    }
  }
});

function updateYaml(value: string) {
  latestDataFormYaml.value = value;
}

function saveForm() {
  let dataFormToSave = dataForm.value;
  if (showSource.value) {
    dataFormToSave = parse(latestDataFormYaml.value);
  }

  appStore.projectStore.updateComponent(dataFormToSave).then(() => {
    notify({
      group: "generic",
      title: "Saved",
      text: "Data form layout saved",
    }, 3000);
  });
}

// List of cass validators
const cassValidators = [
  new MatchingAggregationValidator(),
  new BillChargesValidator(),
  new BillServiceValidator(),
  new BillReadingValidator(),
  new RequiredValidator(),
  new DateValidator(),
];

/**
 * Need to watch the scoped data objects for changes, if we have a required validator
 * we need to use it
 */
watchDebounced([dataObjects], () => {
  for (const currentDataObject of dataObjects.value) {
    const currentPath = currentDataObject.path;

    if (!currentPath) {
      continue; // Skip to the next iteration
    }

    const currentPathMapper = overrideExceptionMapper.value.get(currentPath);
    if (currentPathMapper) {
      const parent = currentDataObject.parent;

      const resolveException = currentPathMapper.find(overrideException => overrideException.parentUuid === parent.uuid && !overrideException.openFlag);
      if (resolveException) {
        continue;
      }
    }

    for (const validatorImplementation of cassValidators) {
      if (validatorImplementation.getTaxonPath() === currentDataObject.path) {
        validatorImplementation.callConfigure(currentDataObject, dataObjects.value);
        validatorImplementation.callValidation(currentDataObject);
        const exceptionUuids = validatorImplementation.callMissingExceptions(currentDataObject);
        useDataFormViewer.updateValidateExceptions(exceptionUuids);
      }
    }
  }
}, {
  debounce: 1000,
  maxWait: 500,
  deep: true,
  immediate: true,
});

const availableExports = [
  { icon: "code", text: "JSON" },
];

const openExport = function () {
  try {
    const hierarchicalData = buildSimplifiedDataObjects(dataObjects.value); // yourDataArray is the array of DataObject instances
    const text = JSON.stringify(hierarchicalData, undefined, 2);
    if (text) {
      appStore.workspaceStore.createTextViewer("JSON Representation", text, undefined, "json");
    }
  } catch (e) {
    console.error(e);
  }
};
</script>

<template>
  <div v-if="dataForm">
    <Toolbar v-if="!kioskMode" class="my-1 border-0 bg-white">
      <Button
        v-if="designMode" :svg-icon="codeIcon" title="Source" :togglable="true" :selected="showSource"
        @click="toggleSource"
      />
      <Button v-if="designMode" :svg-icon="plusIcon" title="Add Card" :togglable="false" @click="addCard()" />
      <DropDownList v-if="designMode" :data-items="['documentFamily', 'workspace']" :value="dataForm.entrypoint" />
      <Button v-if="designMode" :svg-icon="saveIcon" title="Save Layout" :togglable="false" @click="saveForm">
        &nbsp;Save Layout
      </Button>
      <SplitButton
        icon="code" :items="availableExports"
        @itemclick="openExport"
      />
    </Toolbar>
    <div v-if="showSource">
      <KodexaCodeEditor
        v-model="latestDataFormYaml"
        theme="vs"
        style="height: calc(100vh - 200px); width: 100%;"
        language="yaml"
        @change="updateYaml"
      />
    </div>
    <div v-else>
      <TileLayout
        v-if="designMode"
        :items="getCards()"
        :positions="getPositions()"
        :columns="12"
        :row-height="50"
        auto-flow="row"
        style="width: 100%;"
        data-item-key="id"
        :gap="{
          rows: 5,
          columns: 5,
        }" @reposition="handleReposition"
      >
        <template #tile="{ props }">
          <div style="overflow-y: scroll !important;">
            <KodexaFormCard
              :card="props.tile" :design-mode="designMode"
              :view-id="viewId"
              @add-card="addCard($event)"
              @select-card="selectCard($event)"
              @remove-card="removeCard($event)"
            />
          </div>
        </template>
      </TileLayout>
      <div v-else>
        <div style="overflow: scroll; height: calc(96vh - 10rem)">
          <KodexaRowLayout
            :rows="buildDataFormViewer()" :view-id="viewId as string"
          />
        </div>
      </div>
    </div>
  </div>
</template>
