<script lang="ts" setup>
import { onMounted } from "vue";
import type { PropType } from "vue";
import { storeToRefs } from "pinia";
import appStore from "~/store";
import { getContentObjectImage } from "~/api/stores/stores";
import { RefHelper } from "~/utils/ref-utils";
import { getDocumentFamily } from "~/api/document-families/document-families";

const props = defineProps({
  keyName: {
    type: String,
    required: true,
  },
  node: {
    type: Object as PropType<Node>,
  },
});

const { currentWorkspaceId } = storeToRefs(appStore.workspaceStore);

const pageImages = ref<{ [key: number]: string }>({});

function openPage(page) {
  if (!appStore.workspaceStore.sidecarPanelOpen) {
    appStore.workspaceStore.toggleSidecar();
  }
  nextTick(() => {
    const useSidecar = createSidecar(currentWorkspaceId.value);
    useSidecar.focusPage(props.node?.data.documentFamilyId, page);
  });
}
async function loadPageImage(page: number) {
  try {
    const documentFamily = await getDocumentFamily(props.node.data.documentFamilyId);
    const refHelper = new RefHelper(documentFamily.storeRef);
    const blob = await getContentObjectImage(
      refHelper.getOrgSlug(),
      refHelper.getSlug(),
      props.node?.data.documentFamilyId,
      page,
      { dpi: 5 },
    );
    const arrayBuffer = await blob.arrayBuffer();
    pageImages.value[page] = btoa(String.fromCharCode.apply(null, new Uint8Array(arrayBuffer)));
  } catch (e) {
    console.error("Error loading page image", e);
  }
}

onMounted(() => {
  if (props.node?.data.raw?.page_numbers) {
    props.node.data.raw.page_numbers.forEach((page) => {
      loadPageImage(page);
    });
  }
});
</script>

<template>
  <div class="page-preview-container py-4 justify-center">
    <div v-for="page in props.node.data.raw.page_numbers" :key="page" class="page-preview-wrapper px-2">
      <div class="page-preview" @click="openPage(page)">
        <KodexaFileIcon
          v-if="!pageImages[page]"
          :filename="props.node?.data.path || ''"
          class="size-12"
        />
        <img
          v-else
          :src="`data:image/png;base64,${pageImages[page]}`"
          width="50"
          alt="Page Preview"
          class="shadow"
        >
        <span class="page-number text-sm pt-0.5">Page {{ page + 1 }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.page-preview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.page-preview-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-preview {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-number {
  font-size: 0.8rem;
  margin-top: 4px;
}
</style>
