/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */

export type AssignmentRuleAssignmentType = typeof AssignmentRuleAssignmentType[keyof typeof AssignmentRuleAssignmentType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssignmentRuleAssignmentType = {
  USER: 'USER',
  DYNAMIC_USER: 'DYNAMIC_USER',
} as const;
