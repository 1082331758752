/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  ExceptionResponse,
  ListPlatformUsersParams,
  PageUser,
  ThrowableProblem,
  UpdateUserRolesParams,
  User,
  UserRoles
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get a resource with the provided ID
 */
export const getPlatformUser = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<User>(
      {url: `/api/users/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetPlatformUserQueryKey = (id: MaybeRef<string>,) => {
    return ['api','users',id] as const;
    }

    
export const getGetPlatformUserQueryOptions = <TData = Awaited<ReturnType<typeof getPlatformUser>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlatformUser>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetPlatformUserQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPlatformUser>>> = ({ signal }) => getPlatformUser(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPlatformUser>>, TError, TData> 
}

export type GetPlatformUserQueryResult = NonNullable<Awaited<ReturnType<typeof getPlatformUser>>>
export type GetPlatformUserQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetPlatformUser<TData = Awaited<ReturnType<typeof getPlatformUser>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlatformUser>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetPlatformUserQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the given instance
 */
export const updatePlatformUser = (
    id: MaybeRef<string>,
    user: MaybeRef<NonReadonly<User>>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
user = unref(user);
      
      return customAxios<User>(
      {url: `/api/users/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: user
    },
      options);
    }
  


export const getUpdatePlatformUserMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updatePlatformUser>>, TError,{id: string;data: NonReadonly<User>}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updatePlatformUser>>, TError,{id: string;data: NonReadonly<User>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updatePlatformUser>>, {id: string;data: NonReadonly<User>}> = (props) => {
          const {id,data} = props ?? {};

          return  updatePlatformUser(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdatePlatformUserMutationResult = NonNullable<Awaited<ReturnType<typeof updatePlatformUser>>>
    export type UpdatePlatformUserMutationBody = NonReadonly<User>
    export type UpdatePlatformUserMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdatePlatformUser = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updatePlatformUser>>, TError,{id: string;data: NonReadonly<User>}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updatePlatformUser>>,
        TError,
        {id: string;data: NonReadonly<User>},
        TContext
      > => {

      const mutationOptions = getUpdatePlatformUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deletePlatformUser = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/users/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeletePlatformUserMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePlatformUser>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deletePlatformUser>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deletePlatformUser>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deletePlatformUser(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeletePlatformUserMutationResult = NonNullable<Awaited<ReturnType<typeof deletePlatformUser>>>
    
    export type DeletePlatformUserMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeletePlatformUser = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePlatformUser>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deletePlatformUser>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeletePlatformUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Update the roles for a user
 */
export const updateUserRoles = (
    id: MaybeRef<string>,
    params: MaybeRef<UpdateUserRolesParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
params = unref(params);
      
      return customAxios<UserRoles>(
      {url: `/api/users/${id}/roles`, method: 'PUT',
        params: unref(params)
    },
      options);
    }
  


export const getUpdateUserRolesMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUserRoles>>, TError,{id: string;params: UpdateUserRolesParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateUserRoles>>, TError,{id: string;params: UpdateUserRolesParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateUserRoles>>, {id: string;params: UpdateUserRolesParams}> = (props) => {
          const {id,params} = props ?? {};

          return  updateUserRoles(id,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateUserRolesMutationResult = NonNullable<Awaited<ReturnType<typeof updateUserRoles>>>
    
    export type UpdateUserRolesMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateUserRoles = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUserRoles>>, TError,{id: string;params: UpdateUserRolesParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateUserRoles>>,
        TError,
        {id: string;params: UpdateUserRolesParams},
        TContext
      > => {

      const mutationOptions = getUpdateUserRolesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listPlatformUsers = (
    params?: MaybeRef<ListPlatformUsersParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageUser>(
      {url: `/api/users`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListPlatformUsersQueryKey = (params?: MaybeRef<ListPlatformUsersParams>,) => {
    return ['api','users', ...(params ? [params]: [])] as const;
    }

    
export const getListPlatformUsersQueryOptions = <TData = Awaited<ReturnType<typeof listPlatformUsers>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListPlatformUsersParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listPlatformUsers>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListPlatformUsersQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listPlatformUsers>>> = ({ signal }) => listPlatformUsers(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listPlatformUsers>>, TError, TData> 
}

export type ListPlatformUsersQueryResult = NonNullable<Awaited<ReturnType<typeof listPlatformUsers>>>
export type ListPlatformUsersQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useListPlatformUsers<TData = Awaited<ReturnType<typeof listPlatformUsers>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListPlatformUsersParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listPlatformUsers>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListPlatformUsersQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createPlatformUser = (
    user: MaybeRef<NonReadonly<User>>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      user = unref(user);
      
      return customAxios<User>(
      {url: `/api/users`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: user, signal
    },
      options);
    }
  


export const getCreatePlatformUserMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPlatformUser>>, TError,{data: NonReadonly<User>}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createPlatformUser>>, TError,{data: NonReadonly<User>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createPlatformUser>>, {data: NonReadonly<User>}> = (props) => {
          const {data} = props ?? {};

          return  createPlatformUser(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreatePlatformUserMutationResult = NonNullable<Awaited<ReturnType<typeof createPlatformUser>>>
    export type CreatePlatformUserMutationBody = NonReadonly<User>
    export type CreatePlatformUserMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreatePlatformUser = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPlatformUser>>, TError,{data: NonReadonly<User>}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof createPlatformUser>>,
        TError,
        {data: NonReadonly<User>},
        TContext
      > => {

      const mutationOptions = getCreatePlatformUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForPlatformUser = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/users/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForPlatformUserQueryKey = (id: MaybeRef<string>,) => {
    return ['api','users',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForPlatformUserQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForPlatformUser>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForPlatformUser>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForPlatformUserQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForPlatformUser>>> = ({ signal }) => getChangeSequenceForPlatformUser(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForPlatformUser>>, TError, TData> 
}

export type GetChangeSequenceForPlatformUserQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForPlatformUser>>>
export type GetChangeSequenceForPlatformUserQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetChangeSequenceForPlatformUser<TData = Awaited<ReturnType<typeof getChangeSequenceForPlatformUser>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForPlatformUser>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetChangeSequenceForPlatformUserQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



