import uFuzzy from "@leeoniya/ufuzzy";
import { defineStore, storeToRefs } from "pinia";

export interface IndexedContent {
  content: string;
  nodeUuid: string;
  page?: number;
}

export function createDocumentSearch(documentViewId: string) {
  return defineStore(`document-search-${documentViewId}`, () => {
    const viewId = documentViewId;
    const useDocumentStore = createDocumentViewerStore(viewId);
    const { documentLines } = storeToRefs(useDocumentStore);
    const lineContent = computed(() => {
      return documentLines.value.map(line => line.content);
    });

    function search(query: string): IndexedContent[] {
      // eslint-disable-next-line new-cap
      const uf = new uFuzzy({});
      const idxs = uf.filter(lineContent.value, query);
      if (!idxs) {
        return [];
      }
      const idxLimit = idxs.length > 100 ? idxs.slice(0, 100) : idxs;
      return idxLimit.map((idx) => {
        return {
          content: lineContent.value[idx],
          nodeUuid: documentLines.value[idx].uuid,
          page: 1,
        };
      });
    }

    return {
      viewId,
      search,
    };
  })();
}
