import { defineStore } from "pinia";
import { v4 as uuidv4 } from "uuid";
import { createConfirmDialog } from "vuejs-confirm-dialog";

import type {
  Dashboard,
  DashboardWidget,
  Label,
  Organization,
  OrganizationMemory,
  ProjectTag,
} from "~/model";
import {
  createDashboard,
  deleteDashboard,
  updateDashboard,
} from "~/api/dashboards/dashboards";
import {
  getAvailableProjectTags,
  getLabels,
  getOrganizationMemory,
  isFeatureFlagEnabled,
  updateOrganizationMemory,
  useGetAvailableCredit,
} from "~/api/organizations/organizations";
import { log } from "~/utils/logger";
import router from "~/router/router";
import { RefHelper } from "~/utils/ref-utils";
import appStore from "~/store/index";
import KodexaConfirm from "~/components/kodexa-confirm.vue";
import type { LoadingEvent } from "~/store/usePlatform";

export const useOrganization = defineStore("organization", () => {
  const currentOrganization = ref<Organization>();
  const labels = ref<Label[]>([]);
  const projectTags = ref<ProjectTag[]>([]);
  const dashboards = ref<Dashboard[]>([]);
  const organizationMemory = ref<OrganizationMemory>();
  const organizationLoading = ref<boolean>(false);
  const organizationDirty = ref<boolean>(false);
  const interval = ref(undefined);
  const { mutateAsync, status: mutateStatus, data: creditStatus, reset } = useGetAvailableCredit();
  const isZeroCredits = computed(() => {
    return creditStatus?.value?.status === "RED";
  });

  async function reloadLabels(organizationId: string) {
    labels.value = await getLabels(organizationId);
  }

  async function loadProjectTags(organizationId: string) {
    projectTags.value = await getAvailableProjectTags(organizationId);
  }

  async function loadDashboards() {
    log.info(`Loading dashboards for organization ${currentOrganization.value?.id}`);
    if (currentOrganization.value) {
      dashboards.value = [];
      if (organizationMemory.value?.orderedDashboards) {
        for await (const dashboardRef of organizationMemory.value?.orderedDashboards) {
          const refHelper = new RefHelper(dashboardRef);
          log.info(`Loading dashboard ${dashboardRef}`);
          try {
            const dashboard = await appStore.cacheStore.getObject(`dashboard://${refHelper.getOrgSlug()}/${refHelper.getSlug()}`);
            dashboards.value.push(dashboard);
          } catch (e) {
            log.error(`Error loading dashboard ${dashboardRef}`);
          }
        }
      }
    }
  }

  async function loadCredits(organizationId: string) {
    const featureEnabled = await isFeatureEnabled("organization.credits");

    clearInterval(interval.value);
    reset();
    if (!organizationId || !featureEnabled) {
      return;
    }

    log.info(`Loading credits for organization ${organizationId}`);
    await mutateAsync({ id: organizationId });
    interval.value = setInterval(() => {
      mutateAsync({ id: organizationId });
    }, 60000);
  }

  async function loadOrganization(organizationId: string): Promise<boolean> {
    log.info(`Loading organization ${organizationId}`);

    if (!organizationId) {
      return false;
    }

    if (currentOrganization.value && currentOrganization.value.id === organizationId) {
      log.info(`Already loaded organization ${organizationId}`);
      return true;
    } else {
      const loadingEvent = {
        id: organizationId,
        title: "Opening Organization",
        progressMessage: "Loading Organization Information",
        progress: 0,
        progressMax: 4,
      } as LoadingEvent;
      appStore.platformStore.addLoadingEvent(loadingEvent);
      organizationLoading.value = true;
      currentOrganization.value = await appStore.cacheStore.getObject(`organization://${organizationId}`);
      appStore.platformStore.incrementLoadingProgress(loadingEvent.id, 1, undefined, "Loading Dashboard");

      organizationMemory.value = await getOrganizationMemory(organizationId);
      if (!organizationMemory.value) {
        organizationMemory.value = {};
      }
      await loadDashboards();

      appStore.platformStore.incrementLoadingProgress(loadingEvent.id, 1, undefined, "Loading Project Label");
      await reloadLabels(organizationId);

      appStore.platformStore.incrementLoadingProgress(loadingEvent.id, 1, undefined, "Loading Project Tags");
      await loadProjectTags(organizationId);

      appStore.platformStore.incrementLoadingProgress(loadingEvent.id, 1, undefined, "Loading Organization Credit");
      await loadCredits(organizationId);

      organizationLoading.value = false;
      appStore.platformStore.removeLoadingEvent(loadingEvent);
      return true;
    }
  }

  async function clearCurrentOrganization() {
    log.info("Clearing current organization");

    if (organizationDirty.value) {
      const confirmLostChanges = createConfirmDialog(KodexaConfirm);
      const result = await confirmLostChanges.reveal({
        icon: "alert-circle-outline",
        title: "Unsaved Changes",
        message: "You have unsaved changes, if you continue they will be lost!",
        notes: "Do you want to discard the changes, or stay in the organization to save them?",
        confirmText: "Discard Changes",
        confirmIcon: "delete",
        cancelText: "Stay in Organization",
        cancelIcon: "close",
        type: "danger",
      });

      if (result.isCanceled) {
        return false;
      }
    }
    organizationDirty.value = false;
    currentOrganization.value = undefined;
    organizationMemory.value = undefined;
    dashboards.value = [];
    reset();
    clearInterval(interval.value);
    return true;
  }

  function setCurrentOrganization(org: Organization, flow = false) {
    if (currentOrganization.value && currentOrganization.value.id === org.id) {
      return;
    }

    if (flow) {
      router.push({ path: `/f/o/${org.id}/home` });
    } else {
      router.push({ path: `/a/o/${org.id}/home` });
    }
  }

  async function newDashboard(newDashboard: Dashboard) {
    if (currentOrganization.value && organizationMemory.value) {
      const dashboard = await createDashboard(currentOrganization.value.slug as string, newDashboard, {});
      appStore.cacheStore.setObject(`dashboard://${dashboard.ref}`, dashboard);
      if (organizationMemory.value.orderedDashboards === undefined) {
        organizationMemory.value.orderedDashboards = [];
      }
      organizationMemory.value.orderedDashboards.push(dashboard.ref as string);
      organizationMemory.value = await updateOrganizationMemory(currentOrganization.value.id as string, organizationMemory.value);
      dashboards.value = [...dashboards.value, dashboard];
    } else {
      log.warn("No current organization, or missing memory");
    }
  }

  function updateDashboardOrder(dashboard: Dashboard, offset: number) {
    if (currentOrganization.value) {
      log.info("Updating dashboard order");
      // We get the index of the dashboard and then move it to the new position
      // in the array based on the offset
      function arrayMove(arr, old_index, new_index) {
        if (new_index >= arr.length) {
          let k = new_index - arr.length + 1;
          while (k--) {
            arr.push(undefined);
          }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
      }

      const currentIndex = dashboards.value.findIndex(d => d.ref === dashboard.ref);
      dashboards.value = arrayMove(dashboards.value, currentIndex, currentIndex + offset);
      organizationDirty.value = true;
    }
  }

  async function updateDashboards() {
    // Update the dashboard order in the organization memory and save it
    // then save all the dashboards
    if (currentOrganization.value && organizationMemory.value) {
      log.info("Saving dashboards");
      if (!organizationMemory.value?.orderedDashboards) {
        organizationMemory.value.orderedDashboards = [];
      }
      organizationMemory.value.orderedDashboards = dashboards.value.map((d) => {
        return d.ref as string;
      });

      await updateOrganizationMemory(currentOrganization.value.id as string, organizationMemory.value);

      // Update all the dashboards
      for (const dashboard of dashboards.value) {
        const refHelper = new RefHelper(dashboard.ref);
        await updateDashboard(refHelper.getOrgSlug(), refHelper.getSlug(), dashboard);
      }
    }
  }

  async function saveAllChanges() {
    await updateDashboards();
    organizationDirty.value = false;
  }

  async function updateOrganizationDashboard(dashboard: Dashboard) {
    if (currentOrganization.value) {
      log.info("Updating dashboard");
      const refHelper = new RefHelper(dashboard.ref);
      const updatedDashboard = await updateDashboard(refHelper.getOrgSlug(), refHelper.getSlug(), dashboard);
      const updatedIndex = dashboards.value.findIndex(d => d.ref === updatedDashboard.ref);
      dashboards.value = dashboards.value.map((dashboard, index) =>
        index === updatedIndex ? updatedDashboard : dashboard,
      );
    }
  }

  async function cancelChanges() {
    if (!organizationDirty.value) {
      return true;
    }
    const confirmLostChanges = createConfirmDialog(KodexaConfirm);
    const result = await confirmLostChanges.reveal({
      icon: "alert-circle-outline",
      title: "Unsaved Changes",
      message: "You have unsaved changes, if you continue they will be lost!",
      notes: "Do you want to discard the changes, or keep configuring to save them?",
      confirmText: "Discard Changes",
      confirmIcon: "delete",
      cancelText: "Continue Customizing",
      cancelIcon: "close",
      type: "danger",
    });

    if (result.isCanceled) {
      return false;
    }
    const loadingEvent = {
      id: currentOrganization?.value?.id,
      title: "Reloading Organization",
      progressMessage: "Loading Organization Dashboard",
      progress: 1,
      progressMax: 1,
    } as LoadingEvent;
    appStore.platformStore.addLoadingEvent(loadingEvent);
    organizationLoading.value = true;
    appStore.platformStore.incrementLoadingProgress(loadingEvent.id, 1, undefined, "Loading Dashboard");
    await loadDashboards();
    organizationLoading.value = false;
    appStore.platformStore.removeLoadingEvent(loadingEvent);
    organizationDirty.value = false;
    return true;
  }

  async function deleteOrganizationDashboard(dashboard: Dashboard) {
    const refHelper = new RefHelper(dashboard.ref);
    await deleteDashboard(refHelper.getOrgSlug(), refHelper.getSlug());
  }

  function addWidgetToDashboard(dashboard: Dashboard, widget: any) {
    widget.id = uuidv4();
    if (!widget?.properties?.defaultPosition) {
      widget.properties = widget.properties || {};

      // Create defaultPosition object if it doesn't exist
      widget.properties.defaultPosition = {
        col: 1,
        colSpan: 3,
        rowSpan: 2,
      };
    }
    const realDashboard = dashboards.value.find(d => d.ref === dashboard.ref);
    organizationDirty.value = true;
    if (realDashboard?.widgets) {
      realDashboard.widgets.push(widget);
    } else if (realDashboard) {
      realDashboard.widgets = [widget];
    }
  }

  /**
   * Function for deleting a widget from a dashboard
   * @param dashboard
   * @param widget
   */
  function deleteWidgetFromDashboard(dashboard: Dashboard, widget: DashboardWidget) {
    // Find the dashboard
    const realDashboard = dashboards.value.find(d => d.ref === dashboard.ref);

    if (!realDashboard) {
      log.warn(`Dashboard not found with ref ${dashboard.ref}`);
      return;
    }

    if (!realDashboard?.widgets) {
      realDashboard.widgets = [];
    }
    organizationDirty.value = true;
    // Filter the widget and delete it from the dashboard
    const realWidget: DashboardWidget | undefined = realDashboard.widgets.find(w => w.id === widget.id);
    if (realWidget) {
      realDashboard.widgets = realDashboard.widgets.filter(w => w.id !== widget.id);
    }
  }

  async function updateWidgetInDashboard(dashboard: Dashboard, widget: any) {
    const realDashboard = dashboards.value.find(d => d.ref === dashboard.ref);

    if (!realDashboard) {
      log.warn(`Dashboard not found with ref ${dashboard.ref}`);
      return;
    }

    organizationDirty.value = true;
    if (!realDashboard?.widgets) {
      realDashboard.widgets = [];
    }
    const realWidget: DashboardWidget | undefined = realDashboard.widgets.find(w => w.id === widget.id);
    if (realWidget) {
      realWidget.properties = { ...widget.properties };
    } else {
      realDashboard.widgets.push({ ...widget });
    }
  }

  async function isFeatureEnabled(feature: string): Promise<boolean> {
    if (!currentOrganization.value?.id) {
      return false;
    }
    return await isFeatureFlagEnabled(currentOrganization.value.id, feature);
  }

  return {
    creditStatus,
    currentOrganization,
    loadOrganization,
    clearCurrentOrganization,
    labels,
    projectTags,
    loadProjectTags,
    setCurrentOrganization,
    dashboards,
    newDashboard,
    updateDashboardOrder,
    updateDashboards,
    updateOrganizationDashboard,
    updateWidgetInDashboard,
    saveAllChanges,
    cancelChanges,
    deleteWidgetFromDashboard,
    addWidgetToDashboard,
    organizationDirty,
    organizationLoading,
    mutateStatus,
    isFeatureEnabled,
    isZeroCredits,
    deleteOrganizationDashboard,
  };
});
