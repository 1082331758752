<script lang="ts" setup>
import { ref, watch } from "vue";
import type { GuidanceSet } from "~/model";
import KodexaClipboardable from "~/components/util/kodexa-clipboardable.vue";
import appStore from "~/store";
import { log } from "~/utils/logger";

const props = defineProps<{
  guidanceSet: GuidanceSet;
}>();

const localGuidanceSet = ref({ ...props.guidanceSet });

if (!localGuidanceSet.value.storage) {
  localGuidanceSet.value.storage = {};
}

watch(
  () => props.guidanceSet,
  (newValue) => {
    // If they are the same object, don't update
    if (JSON.stringify(localGuidanceSet.value) === JSON.stringify(newValue)) {
      return;
    }

    localGuidanceSet.value = { ...newValue };
    if (!localGuidanceSet.value.storage) {
      localGuidanceSet.value.storage = {};
    }
  },
  { deep: true },
);

watch(
  localGuidanceSet,
  (newValue) => {
    const newGuidanceSet = { ...newValue };
    log.info("Updating guidance set");
    appStore.projectStore.updateGuidanceSet(newGuidanceSet);
  },
  { deep: true },
);

const options = [
  {
    name: "embeddingModelId",
    label: "Embedding Model",
    required: true,
    type: "cloudEmbedding",
    displayProperties: {},
    default: "amazon.titan-embed-text-v1",
    description: "The embedding model to use to build embeddings for the guidance set",
    possibleValues: [],
  },
  {
    name: "summarizationModelId",
    label: "Summarization Model",
    required: true,
    type: "cloudModel",
    displayProperties: {},
    default: "anthropic.claude-3-5-sonnet-20240620-v1:0",
    description: "The summarization model to use to build summaries for advanced embedding",
    possibleValues: [],
  },
  {
    name: "useCustomSummarizePrompt",
    label: "Use Custom Summarize Prompt",
    required: true,
    type: "boolean",
    displayProperties: {},
    default: false,
    description: "Use a custom prompt for summarization",
    possibleValues: [],
  },
  {
    name: "summarizePrompt",
    label: "Summarize Prompt",
    required: true,
    type: "string",
    showIf: "this.useCustomSummarizePrompt",
    description: "Custom prompt for summarization",
  },
  {
    name: "summarizePrompt",
    label: "Summarize Prompt",
    required: true,
    type: "string",
    showIf: "this.useCustomSummarizePrompt",
    description: "Custom prompt for summarization",
  },
  {
    name: "embeddingTypes",
    label: "Embedding Types",
    type: "list",
    listType: "string",
    description: "The types of embeddings to build",
    possibleValues: [
      { value: "TFIDF", label: "TF-IDF" },
      { value: "SUMMARY", label: "Summary" },
      { value: "CONTENT", label: "Content" },
    ],
  },
];

const isOpen = ref(false);

function toggleOpen() {
  isOpen.value = !isOpen.value;
}

const storage = computed({
  get: () => localGuidanceSet.value.storage,
  set: (value) => {
    localGuidanceSet.value.storage = value;
  },
});

watch(
  storage,
  (newValue) => {
    localGuidanceSet.value.storage = newValue;
  },
  { deep: true },
);
</script>

<template>
  <div>
    <div>
      <KodexaTextInput
        v-model="localGuidanceSet.name"
        label="Name"
        name="guidanceSetName"
        class="w-full"
        hint="The name of the guidance set"
      />
    </div>
    <KodexaClipboardable
      :content="guidanceSet.ref"
      :show-content="false"
      class="text-xs"
      message="Copy Guidance Reference"
      name="ref"
      color="black"
      label="Ref"
    />
    <div>
      <div class="cursor-pointer flex items-center" @click="toggleOpen">
        <MaterialDesignIcon
          :name="isOpen ? 'chevron-up' : 'chevron-down'"
          class="text-gray-400 mr-2 transition-transform duration-300"
          :class="{ 'transform rotate-180': isOpen }"
          size="20"
        />
        <span>{{ isOpen ? 'Hide' : 'Show' }} options</span>
      </div>
      <Transition name="fade">
        <div v-if="isOpen">
          <KodexaCheckbox
            v-model="localGuidanceSet.activeStore"
            name="activeStore"
            hint="An active memory store is used to manage large amounts of guidance and build embeddings to allow for classification and active memory"
            label="Active Memory Store"
          />
          <div>
            <ConfigurationOption v-for="option in options" :key="option.name" v-model="storage" :item="option" />
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
