<script setup lang="ts">
import {type PropType, ref} from "vue";
import {storeToRefs} from "pinia";
import {DropDownList} from "@progress/kendo-vue-dropdowns";
import type {DocumentFamily, User} from "~/model";
import appStore from "~/store";
import {useListMemberships} from "~/api/memberships/memberships";
import {removeAssigneesWithVersion, updateAssigneesWithVersion} from "~/api/stores/stores";
import {RefHelper} from "~/utils/ref-utils";

const props = defineProps({
  documentFamily: {
    type: Object as PropType<DocumentFamily>,
    required: true,
  },
});
const emit = defineEmits(["change"]);
const {currentOrganization} = storeToRefs(appStore.organizationStore);

const gridHelper = createGridHelper(`membership-${currentOrganization.value.id}`, {
  page: 1,
  pageSize: 99,
  filter: "",
  query: "",
  sort: "",
}, `organization.id:'${currentOrganization.value.id}'`);

const {
  gridQuery,
} = storeToRefs(gridHelper);

const {
  isLoading,
  data,
} = useListMemberships(gridQuery.value);

const assignee = computed({
  get: () => props.documentFamily?.assignment || null,
  set: async (value: User | null) => {
    const storeRef = new RefHelper(props.documentFamily.storeRef);
    if (!value?.id) {
      await removeAssigneesWithVersion(storeRef.getOrgSlug(), storeRef.getSlug(), storeRef.getVersion(), props.documentFamily.id);
    } else {
      await updateAssigneesWithVersion(storeRef.getOrgSlug(), storeRef.getSlug(), storeRef.getVersion(), props.documentFamily.id, value);
    }
    emit("change");
  },
});

const users = computed(() => {
  const userList = data?.value?.content
    ? data.value.content
      .filter(u => u.user?.id !== assignee.value?.id)
      .map(u => ({
        ...u,
        user: {
          ...u.user,
          firstName: u.user?.firstName || u.user?.email || "Unknown",
        },
      }))
      .map(u => u.user)
    : [];

  return [{id: null, firstName: "No Assignee", lastName: "", email: ""}, ...userList];
});

const dropdownRef = ref<InstanceType<typeof DropDownList> | null>(null);

function selectItem(item: User | null) {
  assignee.value = item?.id ? item : null;
  dropdownRef.value?.handleBlur();
}
</script>

<template>
  <DropDownList
    ref="dropdownRef"
    class="w-full truncate overflow-ellipsis dropdown-input"
    placeholder="Select assignee"
    :value="assignee"
    :data-items="users"
    rounded="large"
    item-render="itemRender"
    value-render="valueRender"
  >
    <template #itemRender="{ props }">
      <div
        v-if="props.dataItem"
        class="cursor-pointer mx-2 hover:bg-gray-200 item-text-container"
        @click="selectItem(!props.dataItem.id ? null : props.dataItem)"
      >
        <div v-if="!props.dataItem.id">
          <span>No Assignee</span>
        </div>
        <div v-else>
          <KodexaUserAvatar :user="props.dataItem" :show-name="true"/>
        </div>
      </div>
    </template>
    <template #valueRender="{ props }">
      <div v-if="props.value?.user" class="mx-2 my-1 item-text-container">
        <KodexaUserAvatar :user="props.value.user" :show-name="true"/>
      </div>
      <div v-else class="text-gray-400 item-text-container mx-2 my-1">
        <span>No Assignee</span>
      </div>
    </template>
  </DropDownList>
</template>

<style scoped>
.item-text-container {
  height: 32px !important;
  padding-top: 0 !important;
  display: flex;
  width: 100%;
  margin-top:3px;
  margin-bottom: 3px;
  align-items: center;
}
</style>
