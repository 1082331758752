import ActivityTimeline from "~/components/dashboard/widgets/activity/activity-timeline.vue";
import ManageUsers from "~/components/dashboard/widgets/app/access/manage-users.vue";
import ManageBilling from "~/components/dashboard/widgets/app/billing/manage-billing.vue";
import ManageContent from "~/components/dashboard/widgets/app/content/manage-content.vue";
import ManageCredits from "~/components/dashboard/widgets/app/credits/manage-credits.vue";
import EventStream from "~/components/dashboard/widgets/app/events/event-stream.vue";
import ManageExtensionPacks from "~/components/dashboard/widgets/app/extensions/manage-extension-packs.vue";
import ManageOrganizations from "~/components/dashboard/widgets/app/organizations/manage-organizations.vue";
import ManageProductGroup from "~/components/dashboard/widgets/app/product-group/manage-product-group.vue";
import ManageProduct from "~/components/dashboard/widgets/app/product/manage-product.vue";
import CreditsCount from "~/components/dashboard/widgets/credits/credits-count.vue";
import DataWidget from "~/components/dashboard/widgets/data-widget.vue";
import EchartWidget from "~/components/dashboard/widgets/general/echart-widget.vue";
import MarkdownWidget from "~/components/dashboard/widgets/general/markdown-widget.vue";
import AppHomeWidget from "~/components/dashboard/widgets/home/app-home-widget.vue";
import KodexaWelcomeWidget from "~/components/dashboard/widgets/home/kodexa-welcome-widget.vue";
import KioskWidget from "~/components/dashboard/widgets/kiosk-widget.vue";
import ManageDocumentTags from "~/components/dashboard/widgets/organization/manage-document-tags.vue";
import ManageMembers from "~/components/dashboard/widgets/organization/manage-members.vue";
import ManageProjectStatus from "~/components/dashboard/widgets/organization/manage-project-status.vue";
import ManageProjectTags from "~/components/dashboard/widgets/organization/manage-project-tags.vue";
import ManageSubscriptions from "~/components/dashboard/widgets/organization/manage-subscriptions.vue";
import ManageTeams from "~/components/dashboard/widgets/organization/manage-teams.vue";
import OrganizationManage from "~/components/dashboard/widgets/organization/organization-manage.vue";
import OrganizationResources from "~/components/dashboard/widgets/organization/resources/organization-resources.vue";
import OrganizationWelcome from "~/components/dashboard/widgets/organizations/organization-welcome.vue";
import ProjectActivity from "~/components/dashboard/widgets/project/project-activity.vue";
import ProjectChat from "~/components/dashboard/widgets/project/project-chat.vue";
import ProjectData from "~/components/dashboard/widgets/project/project-data.vue";
import ProjectDataflow from "~/components/dashboard/widgets/project/project-dataflow.vue";
import ProjectDataforms from "~/components/dashboard/widgets/project/project-dataforms.vue";
import ProjectDocumentAssignment from "~/components/dashboard/widgets/project/project-document-assignment.vue";
import ProjectDocumentStatus from "~/components/dashboard/widgets/project/project-document-status.vue";
import ProjectDocuments from "~/components/dashboard/widgets/project/project-documents.vue";
import ProjectEmailNotification from "~/components/dashboard/widgets/project/project-email-notification.vue";
import ProjectForms from "~/components/dashboard/widgets/project/project-forms.vue";
import ProjectGuidance from "~/components/dashboard/widgets/project/project-guidance.vue";
import ProjectInformation from "~/components/dashboard/widgets/project/project-information.vue";
import ProjectKiosks from "~/components/dashboard/widgets/project/project-kiosks.vue";
import ProjectManage from "~/components/dashboard/widgets/project/project-manage.vue";
import ProjectModelTrainings from "~/components/dashboard/widgets/project/project-model-trainings.vue";
import ProjectOptions from "~/components/dashboard/widgets/project/project-options.vue";
import ProjectOverview from "~/components/dashboard/widgets/project/project-overview.vue";
import ProjectResources from "~/components/dashboard/widgets/project/project-resources.vue";
import ProjectTaskStatus from "~/components/dashboard/widgets/project/project-task-status.vue";
import ProjectTaskTemplate from "~/components/dashboard/widgets/project/project-task-template.vue";
import ProjectTasks from "~/components/dashboard/widgets/project/project-tasks.vue";
import ProjectTaxonomies from "~/components/dashboard/widgets/project/project-taxonomies.vue";
import ProjectTemplateCreate from "~/components/dashboard/widgets/project/project-template-create.vue";
import ProjectWorkspaces from "~/components/dashboard/widgets/project/project-workspaces.vue";
import ActivityByDay from "~/components/dashboard/widgets/projects/activity-by-day.vue";
import FavoriteProjects from "~/components/dashboard/widgets/projects/favorite-projects.vue";
import ProjectsCount from "~/components/dashboard/widgets/projects/projects-count.vue";
import ProjectsList from "~/components/dashboard/widgets/projects/projects-list.vue";
import ProjectsMostRecent from "~/components/dashboard/widgets/projects/projects-most-recent.vue";
import TasksCount from "~/components/dashboard/widgets/tasks/tasks-count.vue";
import TasksList from "~/components/dashboard/widgets/tasks/tasks-list.vue";
import WorkspaceWidget from "~/components/dashboard/widgets/workspace/workspace-widget.vue";

export const availableWidgets = [
  {
    type: "eChartWidget",
    name: "eChart Widget",
    label: "eChart Widget",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "An eChart widget",
    options: [
      { name: "title", label: "Title", type: "string" },
      { name: "chartOptions", label: "Chart JSON", type: "string", properties: { lines: 8 } },
    ],
    implementation: EchartWidget,
  },
  {
    type: "projectDocumentStatus",
    name: "Project Document Status",
    label: "Project Document Status",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Project Document Status",
    implementation: ProjectDocumentStatus,
  },
  {
    type: "projectTaskStatus",
    name: "Project Task Status",
    label: "Project Task Status",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Project Task Status",
    implementation: ProjectTaskStatus,
  },
  {
    type: "tasksList",
    name: "Tasks List",
    label: "Tasks List",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Tasks List",
    implementation: TasksList,
  },
  {
    type: "eventStream",
    name: "Event Stream",
    label: "Event Stream",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Event Stream",
    implementation: EventStream,
  },
  {
    type: "markdownWidget",
    name: "Markdown Widget",
    label: "Markdown Widget",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "A markdown widget",
    options: [
      { name: "content", label: "Markdown", type: "string", properties: { lines: 12 } },
    ],
    implementation: MarkdownWidget,
  },
  {
    type: "projectOptions",
    name: "Project Options",
    hidden: true,
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "A widget that takes the current project and creates a template",
    options: [],
    implementation: ProjectOptions,
  },
  {
    type: "projectGuidance",
    name: "Project Guidance",
    hidden: true,
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "A widget that takes the current project and creates a template",
    options: [],
    implementation: ProjectGuidance,
  },
  {
    type: "projectDataForms",
    name: "Project Data Forms",
    hidden: true,
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "A widget that takes the current project and creates a template",
    options: [],
    implementation: ProjectDataforms,
  },

  {
    type: "mostRecentProject",
    name: "Most Recent Project",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Most Recent Project",
    hidden: true,
    options: [],
    implementation: ProjectsMostRecent,
  },
  {
    type: "activityByDay",
    name: "Activity By Day",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Activity By Day",
    hidden: true,
    options: [],
    implementation: ActivityByDay,
  },
  {
    type: "recentActivity",
    name: "Recent Activity",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Recent Activity",
    dashboard: true,
    options: [],
    implementation: ActivityTimeline,
  },
  {
    type: "favoriteProject",
    name: "Favorite Projects",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Favorite Projects",
    hidden: true,
    options: [],
    implementation: FavoriteProjects,
  },
  {
    type: "projects",
    name: "Projects",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Projects",
    hidden: false,
    options: [],
    implementation: ProjectsList,
  },
  {
    type: "projectDataFlow",
    name: "Project Data Flow",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Project Data Flow",
    dashboard: true,
    hidden: true,
    options: [],
    implementation: ProjectDataflow,
  },
  {
    type: "projectData",
    name: "Project Data",
    dashboard: true,
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Project Data",
    options: [
      { name: "dataStoreRef", label: "Data Store", type: "tableStore" },
    ],
    implementation: ProjectData,
  },
  {
    type: "projectDocuments",
    name: "Project Documents",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Project Documents",
    options: [
      { name: "documentStoreRef", label: "Document Store", type: "documentStore" },
    ],
    implementation: ProjectDocuments,
  },
  {
    type: "projectTasks",
    hidden: false,
    name: "Project Tasks",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Project Tasks",
    options: [],
    implementation: ProjectTasks,
  },
  {
    type: "projectActivity",
    name: "Project Activity",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Project Activity",
    options: [],
    implementation: ProjectActivity,
  },
  {
    type: "projectInformation",
    hidden: true,
    name: "Project Documents",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Project Information",
    options: [],
    implementation: ProjectInformation,
  },
  {
    type: "projectResources",
    name: "Project Resources",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Project Resources",
    options: [],
    implementation: ProjectResources,
    hidden: true,
  },
  {
    type: "projectTemplateCreate",
    name: "Project Template Create",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Project Template Create",
    options: [],
    implementation: ProjectTemplateCreate,
    hidden: true,
  },
  {
    type: "projectTaskTemplate",
    name: "Project Task Template",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Project Task Template",
    options: [],
    implementation: ProjectTaskTemplate,
    hidden: true,
  },
  {
    type: "projectDocumentAssignment",
    name: "Project Document Assignment",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Project Document Assignment",
    options: [],
    implementation: ProjectDocumentAssignment,
    hidden: true,
  },
  {
    type: "projectKiosks",
    name: "Project Kiosks",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Project Kiosks",
    options: [],
    implementation: ProjectKiosks,
    hidden: true,
  },
  {
    type: "projectEmailNotification",
    name: "Project Email Notification",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Project Email Notification",
    options: [],
    implementation: ProjectEmailNotification,
    hidden: true,
  },
  {
    type: "projectWorkspaces",
    name: "Project Workspaces",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Project Workspaces",
    options: [],
    implementation: ProjectWorkspaces,
  },
  {
    type: "projectsCount",
    name: "Project Count",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Project Count",
    hidden: true,
    options: [],
    implementation: ProjectsCount,
  },
  {
    type: "tasksCount",
    name: "Tasks Count",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Tasks Count",
    hidden: true,
    options: [],
    implementation: TasksCount,
  },
  {
    type: "creditsCount",
    name: "Credits Count",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Credits Available",
    hidden: true,
    options: [],
    implementation: CreditsCount,
  },
  {
    type: "kiosk",
    name: "Data Form Kiosk",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Data Form Kiosk",
    implementation: KioskWidget,
    options: [
      { name: "workspace", label: "Workspace", type: "workspace" },
      { name: "documentStoreRef", label: "Document Store", type: "documentStore" },
      { name: "dataFormRef", label: "Data Form", type: "dataForm" },
      { name: "filters", label: "Document Filters", type: "string" },
    ],
    isSingle: true,
  },
  {
    type: "organizationWelcome",
    name: "Organization Welcome",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Organization Welcome",
    implementation: OrganizationWelcome,
    hidden: true,
  },
  {
    type: "kodexaWelcome",
    name: "Kodexa Welcome",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Kodexa Welcome",
    implementation: KodexaWelcomeWidget,
    hidden: true,
  },
  {
    type: "projectChat",
    name: "Project Chat",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Project Chat",
    implementation: ProjectChat,
  },
  {
    type: "dataWidget",
    name: "Data Widget",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Data Widget",
    implementation: DataWidget,
    options: [
      { name: "title", label: "Title", type: "string" },
      {
        name: "dataSource",
        label: "Data Source",
        type: "string",
        default: "platform",
        possibleValues: [
          { label: "Organization Data", value: "organization" },
          { label: "Extracted Data", value: "extracted" },
        ],
      },
      { name: "query", label: "Query", type: "string", properties: { lines: 4 } },
      { name: "hql", label: "HQL", type: "string", developerOnly: true, properties: { lines: 4 } },
    ],
  },
  {
    type: "appHome",
    name: "App Home",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    hidden: true,
    options: [],
    implementation: AppHomeWidget,
  },
  {
    type: "projectForms",
    name: "Project Forms",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Project Forms",
    hidden: true,
    options: [],
    implementation: ProjectForms,
  },
  {
    type: "projectModelTrainings",
    name: "Project Model Trainings",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Project Model Trainings",
    hidden: true,
    options: [],
    implementation: ProjectModelTrainings,
  },
  // {
  //   type: "manageSubscriptions",
  //   name: "Manage Subscriptions",
  //   imageUrl: "/assets/header_icons/platformmanagement.png",
  //   description: "Manage Subscriptions",
  //   hidden: true,
  //   options: [],
  //   implementation: ManageSubscriptions,
  // },
  {
    type: "projectOverview",
    name: "Project Overview",
    description: "Project Overview",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    hidden: true,
    options: [
      { name: "hideOpenWorkspace", label: "Hide Open Workspace", type: "boolean" },
    ],
    implementation: ProjectOverview,
  },
  {
    type: "projectManage",
    name: "Project Manage",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    hidden: true,
    options: [],
    implementation: ProjectManage,
  },
  {
    type: "organizationManage",
    name: "Organization Manage",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    hidden: true,
    options: [],
    implementation: OrganizationManage,
  },
  {
    type: "manageSubscriptions",
    name: "Manage Subscriptions",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    hidden: true,
    options: [],
    implementation: ManageSubscriptions,
  },
  {
    type: "manageTeams",
    name: "Manage Teams",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    hidden: true,
    options: [],
    implementation: ManageTeams,
  },
  {
    type: "manageMembers",
    name: "Manage Members",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    hidden: true,
    options: [],
    implementation: ManageMembers,
  },
  {
    type: "organizationResources",
    name: "Organization Resources",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    hidden: true,
    options: [],
    implementation: OrganizationResources,
  },
  {
    type: "manageProjectStatus",
    name: "Manage Project Status",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    hidden: true,
    options: [],
    implementation: ManageProjectStatus,
  },
  {
    type: "manageProjectTags",
    name: "Manage Project Status",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    hidden: true,
    options: [],
    implementation: ManageProjectTags,
  },
  {
    type: "manageDocumentTags",
    name: "Manage Project Status",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    hidden: true,
    options: [],
    implementation: ManageDocumentTags,
  },
  {
    type: "manageUsers",
    name: "Users",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    hidden: true,
    options: [],
    implementation: ManageUsers,
  },
  {
    type: "manageExtensionPacks",
    name: "Extension Packs",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    hidden: true,
    implementation: ManageExtensionPacks,
  },
  {
    type: "projectTaxonomies",
    name: "Project Taxonomies",
    label: "Project Taxonomies",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    hidden: false,
    implementation: ProjectTaxonomies,
  },
  {
    type: "workspace",
    name: "Workspace",
    description: "Workspace",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    hidden: false,
    implementation: WorkspaceWidget,
    options: [
      { name: "workspace", label: "Workspace", type: "workspace" },
      { name: "documentStoreRef", label: "Document Store", type: "documentStore" },
      { name: "dataFormRef", label: "Data Form", type: "dataForm" },
      { name: "openSidecar", label: "Open Sidecar", type: "boolean" },
      { name: "hideToolbar", label: "Hide Toolbar", type: "boolean" },
    ],
  },
  {
    type: "manageOrganizations",
    name: "Manage Organizations",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Manage Organizations",
    implementation: ManageOrganizations,
    hidden: true,
  },
  {
    type: "manageProduct",
    name: "Products",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Manage Product",
    implementation: ManageProduct,
    hidden: true,
  },
  {
    type: "manageProductGroups",
    name: "Product Groups",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Manage Product Groups",
    implementation: ManageProductGroup,
    hidden: true,
  },
  {
    type: "manageBilling",
    name: "Billing",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Manage Billing",
    implementation: ManageBilling,
    hidden: true,
  },
  {
    type: "manageCredits",
    name: "Credits",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Manage Credits",
    implementation: ManageCredits,
    hidden: true,
  },
  {
    type: "manageContent",
    name: "Content",
    imageUrl: "/assets/header_icons/platformmanagement.png",
    description: "Manage Content",
    implementation: ManageContent,
    hidden: true,
  },

];
