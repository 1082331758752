<script setup lang="ts">
import type { PropType, Ref } from "vue";
import { storeToRefs } from "pinia";
import appStore from "~/store";
import type { DashboardWidget, PlatformStatistics } from "~/model";
import { getPlatformStatistics } from "~/api/platform-overview/platform-overview";

defineProps({
  widget: {
    type: Object as PropType<DashboardWidget>,
    required: false,
  },
});

const platformStats: Ref<PlatformStatistics | null> = ref(null);
const { currentOrganization } = storeToRefs(appStore.organizationStore);
const { user } = storeToRefs(appStore.userStore);

watch([currentOrganization, user], () => {
  if (!user) {
    return;
  }

  if (!currentOrganization.value) {
    getPlatformStatistics().then((response) => {
      platformStats.value = response;
    });
  } else {
    getPlatformStatistics({ organizationId: currentOrganization.value.id }).then((response) => {
      platformStats.value = response;
    });
  }
}, {
  immediate: true,
  deep: true,
});

const stats = computed(() => {
  if (!platformStats.value) {
    return [];
  }

  return [
    {
      id: 1,
      header: "Projects",
      value: platformStats.value.totalProjects.toLocaleString(),
    },
    {
      id: 2,
      header: "Documents",
      value: platformStats.value.totalDocuments.toLocaleString(),
    },
    {
      id: 3,
      header: "Users",
      value: platformStats.value.totalUsers.toLocaleString(),
    },
  ];
});
</script>

<template>
  <div>
    <div>
      <h2 class="text-2xl font-bold mb-4">
        {{ title }}
      </h2>
      <ul class="space-y-4">
        <li v-for="stat in stats" :key="stat.id" class="flex flex-col">
          <span class="text-sm font-medium text-gray-500">{{ stat.header }}</span>
          <span class="text-lg font-semibold">{{ stat.value }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>

</style>
