/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  ExceptionResponse,
  Execution,
  GetExecutionLogsParams,
  GetStatus200,
  ListExecutionEventsForExecutionIdParams,
  ListExecutionsParams,
  PageExecution,
  PageExecutionEvent,
  PageExecutionLogEntry,
  ThrowableProblem,
  UpdateExecutionStatusBody
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get status message for a specific execution
 */
export const getStatus = (
    executionId: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      executionId = unref(executionId);
      
      return customAxios<GetStatus200>(
      {url: `/api/executions/${executionId}/status`, method: 'GET', signal
    },
      options);
    }
  

export const getGetStatusQueryKey = (executionId: MaybeRef<string>,) => {
    return ['api','executions',executionId,'status'] as const;
    }

    
export const getGetStatusQueryOptions = <TData = Awaited<ReturnType<typeof getStatus>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(executionId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStatus>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetStatusQueryKey(executionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getStatus>>> = ({ signal }) => getStatus(executionId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(executionId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getStatus>>, TError, TData> 
}

export type GetStatusQueryResult = NonNullable<Awaited<ReturnType<typeof getStatus>>>
export type GetStatusQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetStatus<TData = Awaited<ReturnType<typeof getStatus>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 executionId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStatus>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetStatusQueryOptions(executionId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update status message for a specific execution
 */
export const updateExecutionStatus = (
    executionId: MaybeRef<string>,
    updateExecutionStatusBody: MaybeRef<UpdateExecutionStatusBody>,
 options?: SecondParameter<typeof customAxios>,) => {
      executionId = unref(executionId);
updateExecutionStatusBody = unref(updateExecutionStatusBody);
      
      return customAxios<void>(
      {url: `/api/executions/${executionId}/status`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateExecutionStatusBody
    },
      options);
    }
  


export const getUpdateExecutionStatusMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateExecutionStatus>>, TError,{executionId: string;data: UpdateExecutionStatusBody}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateExecutionStatus>>, TError,{executionId: string;data: UpdateExecutionStatusBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateExecutionStatus>>, {executionId: string;data: UpdateExecutionStatusBody}> = (props) => {
          const {executionId,data} = props ?? {};

          return  updateExecutionStatus(executionId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateExecutionStatusMutationResult = NonNullable<Awaited<ReturnType<typeof updateExecutionStatus>>>
    export type UpdateExecutionStatusMutationBody = UpdateExecutionStatusBody
    export type UpdateExecutionStatusMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateExecutionStatus = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateExecutionStatus>>, TError,{executionId: string;data: UpdateExecutionStatusBody}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateExecutionStatus>>,
        TError,
        {executionId: string;data: UpdateExecutionStatusBody},
        TContext
      > => {

      const mutationOptions = getUpdateExecutionStatusMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Cancel the specific execution
 */
export const cancelExecution = (
    executionId: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      executionId = unref(executionId);
      
      return customAxios<Execution>(
      {url: `/api/executions/${executionId}/cancel`, method: 'PUT'
    },
      options);
    }
  


export const getCancelExecutionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cancelExecution>>, TError,{executionId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof cancelExecution>>, TError,{executionId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cancelExecution>>, {executionId: string}> = (props) => {
          const {executionId} = props ?? {};

          return  cancelExecution(executionId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CancelExecutionMutationResult = NonNullable<Awaited<ReturnType<typeof cancelExecution>>>
    
    export type CancelExecutionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCancelExecution = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cancelExecution>>, TError,{executionId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof cancelExecution>>,
        TError,
        {executionId: string},
        TContext
      > => {

      const mutationOptions = getCancelExecutionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Query the executions in the platform
 */
export const listExecutions = (
    params?: MaybeRef<ListExecutionsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageExecution>(
      {url: `/api/executions`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListExecutionsQueryKey = (params?: MaybeRef<ListExecutionsParams>,) => {
    return ['api','executions', ...(params ? [params]: [])] as const;
    }

    
export const getListExecutionsQueryOptions = <TData = Awaited<ReturnType<typeof listExecutions>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListExecutionsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listExecutions>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListExecutionsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listExecutions>>> = ({ signal }) => listExecutions(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listExecutions>>, TError, TData> 
}

export type ListExecutionsQueryResult = NonNullable<Awaited<ReturnType<typeof listExecutions>>>
export type ListExecutionsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useListExecutions<TData = Awaited<ReturnType<typeof listExecutions>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListExecutionsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listExecutions>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListExecutionsQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Get a specific execution
 */
export const getExecution = (
    executionId: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      executionId = unref(executionId);
      
      return customAxios<Execution>(
      {url: `/api/executions/${executionId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetExecutionQueryKey = (executionId: MaybeRef<string>,) => {
    return ['api','executions',executionId] as const;
    }

    
export const getGetExecutionQueryOptions = <TData = Awaited<ReturnType<typeof getExecution>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(executionId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getExecution>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetExecutionQueryKey(executionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExecution>>> = ({ signal }) => getExecution(executionId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(executionId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getExecution>>, TError, TData> 
}

export type GetExecutionQueryResult = NonNullable<Awaited<ReturnType<typeof getExecution>>>
export type GetExecutionQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetExecution<TData = Awaited<ReturnType<typeof getExecution>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 executionId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getExecution>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetExecutionQueryOptions(executionId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Get the specific session
 */
export const getExecutionLogs = (
    executionId: MaybeRef<string>,
    params?: MaybeRef<GetExecutionLogsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      executionId = unref(executionId);
params = unref(params);
      
      return customAxios<PageExecutionLogEntry>(
      {url: `/api/executions/${executionId}/logs`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getGetExecutionLogsQueryKey = (executionId: MaybeRef<string>,
    params?: MaybeRef<GetExecutionLogsParams>,) => {
    return ['api','executions',executionId,'logs', ...(params ? [params]: [])] as const;
    }

    
export const getGetExecutionLogsQueryOptions = <TData = Awaited<ReturnType<typeof getExecutionLogs>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(executionId: MaybeRef<string>,
    params?: MaybeRef<GetExecutionLogsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getExecutionLogs>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetExecutionLogsQueryKey(executionId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExecutionLogs>>> = ({ signal }) => getExecutionLogs(executionId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(executionId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getExecutionLogs>>, TError, TData> 
}

export type GetExecutionLogsQueryResult = NonNullable<Awaited<ReturnType<typeof getExecutionLogs>>>
export type GetExecutionLogsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetExecutionLogs<TData = Awaited<ReturnType<typeof getExecutionLogs>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 executionId: MaybeRef<string>,
    params?: MaybeRef<GetExecutionLogsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getExecutionLogs>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetExecutionLogsQueryOptions(executionId,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Get events for a specific execution
 */
export const listExecutionEventsForExecutionId = (
    executionId: MaybeRef<string>,
    params: MaybeRef<ListExecutionEventsForExecutionIdParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      executionId = unref(executionId);
params = unref(params);
      
      return customAxios<PageExecutionEvent>(
      {url: `/api/executions/${executionId}/events`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListExecutionEventsForExecutionIdQueryKey = (executionId: MaybeRef<string>,
    params: MaybeRef<ListExecutionEventsForExecutionIdParams>,) => {
    return ['api','executions',executionId,'events', ...(params ? [params]: [])] as const;
    }

    
export const getListExecutionEventsForExecutionIdQueryOptions = <TData = Awaited<ReturnType<typeof listExecutionEventsForExecutionId>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(executionId: MaybeRef<string>,
    params: MaybeRef<ListExecutionEventsForExecutionIdParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listExecutionEventsForExecutionId>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListExecutionEventsForExecutionIdQueryKey(executionId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listExecutionEventsForExecutionId>>> = ({ signal }) => listExecutionEventsForExecutionId(executionId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(executionId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listExecutionEventsForExecutionId>>, TError, TData> 
}

export type ListExecutionEventsForExecutionIdQueryResult = NonNullable<Awaited<ReturnType<typeof listExecutionEventsForExecutionId>>>
export type ListExecutionEventsForExecutionIdQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useListExecutionEventsForExecutionId<TData = Awaited<ReturnType<typeof listExecutionEventsForExecutionId>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 executionId: MaybeRef<string>,
    params: MaybeRef<ListExecutionEventsForExecutionIdParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listExecutionEventsForExecutionId>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListExecutionEventsForExecutionIdQueryOptions(executionId,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



