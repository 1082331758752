<script setup lang="ts">
import { storeToRefs } from "pinia";
import { Ref, ref } from "vue";
import type { PropType } from "vue/dist/vue";
import appStore from "~/store";
import type { TagMetadata } from "~/store/useProject";
import type { Card, DataObject } from "~/model";

const props = defineProps({
  card: {
    type: Object as PropType<Card>,
    required: true,
  },
  designMode: {
    type: Boolean,
    required: false,
    default: false,
  },
  viewId: {
    type: String,
    required: true,
  },
});

const { tagMetadataMap } = storeToRefs(appStore.projectStore);

const { dataObjects } = storeToRefs(appStore.workspaceStore);

const groupTaxons: Ref<TagMetadata[]> = computed(() => {
  return Array.from(tagMetadataMap.value.values()).filter((tagMetadata: any) => {
    return tagMetadata.taxon.group && tagMetadata.taxonomy.taxonomyType === "CONTENT" && !tagMetadata.taxon.path.includes("/")
  }) as TagMetadata[];
});

const currentTaxon = ref<TagMetadata | undefined>(undefined);

watch(groupTaxons, () => {
  if (groupTaxons.value.length > 0) {
    currentTaxon.value = groupTaxons.value[0] as TagMetadata;
  }
}, { immediate: true, deep: true });

const rawDataObjects: Ref<DataObject[]> = computed(() => {
  return dataObjects.value ? Array.from(dataObjects.value.values()) : [];
});
</script>

<template>
  <div>
    <div>
      <KodexaWorkspaceDataObjectTreeContainer
        v-if="currentTaxon"
        :key="currentTaxon?.taxon.id"
        :view-id="viewId"
        :data-objects="rawDataObjects"
        :group-taxon-metadata="currentTaxon"
        class="mt-1"
        :group-taxons="groupTaxons"
      />
    </div>
  </div>
</template>

<style scoped>
</style>
