<script setup lang="ts">
import { filterIcon } from "@progress/kendo-svg-icons";
import { Button, Toolbar, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import { storeToRefs } from "pinia";
import { useListPlatformEvents } from "~/api/platform-events/platform-events";
import { createGridHelper } from "~/store/useGridHelper";

const gridHelper = createGridHelper("event-stream-list", {
  page: 1,
  pageSize: 10,
  filter: "",
  query: "",
  sort: "dateTime:desc",
});

const {
  pageSettings,
  sort,
  showFilter,
  filter,
  gridQuery,
} = storeToRefs(gridHelper);

// Query
const {
  isLoading,
  isError,
  data,
  error,
  refetch,
} = useListPlatformEvents(gridQuery.value);

watch(data, (newValue) => {
  if (newValue && newValue.totalElements) {
    gridHelper.setTotal(newValue.totalElements);
    gridHelper.saveState();
  }
});

const columns = [
  {
    title: "ID",
    field: "id",
  },
  {
    title: "Date/Time",
    field: "dateTime",
  },
  {
    title: "Type",
    field: "eventDetail",
    cell: "eventDetail",
  },
];
</script>

<template>
  <div style="height: calc(100vh - 69px);">
    <div class="mx-4">
      <Toolbar class="border-0 bg-white">
        <Button :svg-icon="filterIcon" title="Filter" :togglable="true" @click="gridHelper.toggleFilter()" />
        <ToolbarSpacer />
      </Toolbar>
      <Grid
        ref="grid"
        style="height: calc(100vh - 15rem);"
        :style="{ height: '520px' }"
        :data-items="data ? data.content : []"
        :pageable="pageSettings"
        :loader="isLoading"
        :sort="sort"
        :skip="pageSettings.skip as number"
        :total="pageSettings.total as number"
        :page-size="pageSettings.take as number"
        :columns="columns"
        :filterable="showFilter"
        :filter="filter"
        :resizable="true"
        :sortable="true"
        @pagechange="gridHelper.pageChangeHandler($event)"
        @filterchange="gridHelper.filterChange($event)"
        @sortchange="gridHelper.sortChange($event)"
      >
        <GridNoRecords>
          <KodexaGridNoRecords :is-error="isError" :is-loading="isLoading" />
        </GridNoRecords>
        <template #eventDetail="{ props }">
          <td>
            {{ props.dataItem.eventDetail.type }}
          </td>
        </template>
      </Grid>
    </div>
  </div>
</template>
