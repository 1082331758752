<!--
  - Copyright (C) 2024 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import { storeToRefs } from "pinia";
import type { PropType } from "vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import KodexaFormCardExceptions from "./kodexa-form-card-exceptions.vue";
import KodexaFormCardDataAttribute from "~/components/dataForm/cards/kodexa-form-card-data-attribute.vue";
import KodexaFormCardGrid from "~/components/dataForm/cards/kodexa-form-card-grid.vue";
import KodexaFormCardLabel from "~/components/dataForm/cards/kodexa-form-card-label.vue";
import KodexaFormCardPanel from "~/components/dataForm/cards/kodexa-form-card-panel.vue";
import KodexaFormCardTabs from "~/components/dataForm/cards/kodexa-form-card-tabs.vue";
import KodexaFormTransposedGrid from "~/components/dataForm/cards/kodexa-form-transposed-grid.vue";
import KodexaDeleteConfirm from "~/components/kodexa-confirm.vue";
import appStore from "~/store";
import { createDataFormViewerStore } from "~/store/useDataFormView";
import type { TagMetadata } from "~/store/useProject";
import KodexaDataObjectMenu from "~/components/dataObject/kodexa-data-object-menu.vue";
import KodexaFormDataStoreGrid from "~/components/dataForm/cards/kodexa-form-data-store-grid.vue";
import KodexaFormDataWorkspaceGrid from "~/components/dataForm/cards/kodexa-form-data-workspace-grid.vue";
import KodexaFormCardTaxonTabs from "~/components/dataForm/cards/kodexa-form-card-taxon-tabs.vue";
import type { Card, DataObject } from "~/model";
import KodexaFormDataWorkspaceTreeView from "~/components/dataForm/cards/kodexa-form-data-workspace-tree-view.vue";

const props = defineProps({
  card: {
    type: Object as PropType<Card>,
    required: true,
  },
  designMode: {
    type: Boolean,
    required: false,
    default: false,
  },
  viewId: {
    type: String,
    required: true,
  },
  parentDataObject: {
    type: Object as PropType<DataObject>,
    required: false,
    default: undefined,
  },
  parentDataObjectGroups: {
    type: Map as PropType<Map<string, DataObject>>,
    required: false,
    default: undefined,
  },
  parentTagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: false,
    default: undefined,
  },
});

const emit = defineEmits(["addCard", "selectCard", "removeCard", "addDataObject", "deleteDataObject", "moveDataObject"]);

const { currentSidebar } = storeToRefs(appStore.platformStore);

const deleteConfirmDialog = createConfirmDialog(KodexaDeleteConfirm);
const useDataFormViewer = createDataFormViewerStore(props.viewId);

const cardTypes = {
  label: KodexaFormCardLabel,
  dataAttributeEditor: KodexaFormCardDataAttribute,
  cardPanel: KodexaFormCardPanel,
  dataObjectGrid: KodexaFormCardGrid,
  transposedGrid: KodexaFormTransposedGrid,
  tabs: KodexaFormCardTabs,
  exceptionDetails: KodexaFormCardExceptions,
  moveCardMenu: KodexaDataObjectMenu,
  dataStoreGrid: KodexaFormDataStoreGrid,
  workspaceDataGrid: KodexaFormDataWorkspaceGrid,
  taxonTabs: KodexaFormCardTaxonTabs,
  dataObjectTree: KodexaFormDataWorkspaceTreeView,
};

async function removeCard() {
  const {
    data,
    isCanceled,
  } = await deleteConfirmDialog.reveal({
    title: "Delete card?",
    message: "Are you sure you want to delete this card?",
  });
  if (!isCanceled) {
    emit("removeCard", { card: props.card });
  }
}

function clickEmit() {
  emit("selectCard", props.card);
  currentSidebar.value = "properties";
}

function emitRemoveCard(removeArgs: any) {
  if (removeArgs.parent !== undefined) {
    emit("removeCard", removeArgs);
  } else {
    removeArgs.parentCard = props.card;
    emit("removeCard", removeArgs);
  }
}

function orderDown() {
  const cardCopy = JSON.parse(JSON.stringify(props.card));
  if (cardCopy.properties) {
    // @ts-expect-error OpenAPI issue
    const currentOrder: number = props.card?.properties?.order || 0;
    cardCopy.properties.order = currentOrder - 1;
    useDataFormViewer.updateCard(cardCopy);
  }
}

function orderUp() {
  const cardCopy = JSON.parse(JSON.stringify(props.card));
  if (cardCopy.properties) {
    // @ts-expect-error OpenAPI issue
    const currentOrder: number = props.card?.properties?.order || 0;
    cardCopy.properties.order = currentOrder + 1;
    useDataFormViewer.updateCard(cardCopy);
  }
}
</script>

<template>
  <div :class="designMode ? ['my-2', 'mx-3'] : []">
    <MaterialDesignIcon
      v-if="designMode" tooltip="Move card down"
      class="mx-1" name="upArrow" style="color: black; float: right; cursor: pointer !important;"
      size="20"
      @click="orderDown"
    />
    <MaterialDesignIcon
      v-if="designMode" tooltip="Move card up"
      class="mx-1" name="downArrow" style="color: black; float: right; cursor: pointer !important;"
      size="20"
      @click="orderUp"
    />
    <MaterialDesignIcon
      v-if="designMode" tooltip="Delete card"
      class="mx-1" name="delete" style="color: red; float: right; cursor: pointer !important;"
      size="20"
      @click="removeCard"
    />
    <MaterialDesignIcon
      v-if="designMode" tooltip="View Properties"
      class="ml-3 mr-1" name="properties" style="color: black; float: right; cursor: pointer !important;"
      size="20"
      @click="clickEmit"
    />
    <Component
      :is="cardTypes[card.type]" :card="card" :design-mode="designMode"
      :view-id="props.viewId"
      :parent-data-object="props.parentDataObject"
      :parent-tag-metadata="props.parentTagMetadata"
      :parent-data-object-groups="parentDataObjectGroups"
      @add-card="emit('addCard', $event)"
      @select-card="emit('selectCard', $event)"
      @remove-card="emitRemoveCard($event)"
      @delete-data-object="emit('deleteDataObject', $event)"
      @add-data-object="emit('addDataObject', $event)"
      @move-data-object="emit('moveDataObject', $event)"
    />
  </div>
</template>

<style scoped>
</style>
