<script setup lang="ts">
import { Toolbar, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { Grid } from "@progress/kendo-vue-grid";
import { storeToRefs } from "pinia";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import KodexaDeleteConfirm from "~/components/kodexa-confirm.vue";
import appStore from "~/store";

const {
  project,
} = storeToRefs(appStore.projectStore);

const deleteConfirmDialog = createConfirmDialog(KodexaDeleteConfirm);
const editID = ref(null);

const localProject = ref(undefined);

watch(project, (newValue) => {
  localProject.value = newValue;
}, { deep: true, immediate: true });

watch(localProject, (newValue) => {
  if (newValue) {
    appStore.projectStore.updateCurrentProject(newValue);
  }
}, {
  deep: true,
});

const taskStatuses = computed({
  get: () => {
    return localProject.value?.taskStatuses || [];
  },
  set: (value) => {
    localProject.value.taskStatuses = value;
  },
});

const columns = ref([
  {
    field: "label",
    title: "Label",
    cell: "label",
  },
  {
    field: "color",
    title: "Color",
    cell: "color",
  },
  {
    field: "icon",
    title: "Icon",
    cell: "icon",
  },
  {
    field: "statusType",
    title: "Status Type",
    cell: "statusType",
  },
  {
    title: " ",
    field: "actions",
    cell: "actions",
    width: 200,
  },
]);

function addNewStatus() {
  taskStatuses.value = [...taskStatuses.value, {
    label: "New Status",
    color: "#000000",
    icon: "flag",
    statusType: "IN_PROGRESS",
  }];
  editID.value = Date.now();
}

async function removeStatus(statusToRemove) {
  const { isCanceled } = await deleteConfirmDialog.reveal({
    title: "Delete Status",
    message: "Are you sure you want to delete this status?",
  });

  if (!isCanceled) {
    taskStatuses.value = taskStatuses.value.filter(status => status.id !== statusToRemove.id);
  }
}

function onEdit(event) {
  editID.value = event.dataItem.id;
}

function onCancel() {
  editID.value = null;
}

function onSave() {
  editID.value = null;
}
</script>

<template>
  <div>
    <div class="lg:grid lg:grid-cols-12 lg:gap-x-5 ">
      <div class="space-y-6 sm:px-6 lg:col-span-12 lg:px-0">
        <div>
          <div class="space-y-6 bg-white px-4 py-6 sm:p-6" style="height: calc(100vh - 250px)">
            <Toolbar class="border-0 bg-white">
              <KodexaArticle article-id="9787707" text="Learn more about task status" :slide="false" />
              <ToolbarSpacer />
              <KodexaButton id="createNewNotification" icon="plus" @click="addNewStatus">
                Add Task Status
              </KodexaButton>
            </Toolbar>

            <Grid
              :data-items="taskStatuses"
              :columns="columns"
              edit-field="inEdit"
            >
              <template #label="{ props }">
                <td>
                  <div>
                    <div v-if="editID === props.dataItem.id">
                      <KodexaTextInput
                        v-model="props.dataItem.label"
                        name="label"
                      />
                    </div>
                    <div v-else>
                      {{ props.dataItem.label }}
                    </div>
                  </div>
                </td>
              </template>

              <template #color="{ props }">
                <td>
                  <div>
                    <div v-if="editID === props.dataItem.id">
                      <KodexaTextInput
                        v-model="props.dataItem.color"
                        name="color"
                      />
                    </div>
                    <div v-else>
                      <div class="w-6 h-6 rounded" :style="{ backgroundColor: props.dataItem.color }" />
                    </div>
                  </div>
                </td>
              </template>

              <template #icon="{ props }">
                <td>
                  <div>
                    <div v-if="editID === props.dataItem.id">
                      <KodexaTextInput
                        v-model="props.dataItem.icon"
                        name="icon"
                      />
                    </div>
                    <div v-else>
                      <i :class="props.dataItem.icon" />
                    </div>
                  </div>
                </td>
              </template>

              <template #statusType="{ props }">
                <td>
                  <div>
                    <div v-if="editID === props.dataItem.id">
                      <select
                        :value="props.dataItem.statusType"
                        class="k-input k-input-md k-rounded-md"
                        @change="e => props.dataItem.statusType = e.target.value"
                      >
                        <option value="TODO">
                          To Do
                        </option>
                        <option value="IN_PROGRESS">
                          In Progress
                        </option>
                        <option value="DONE">
                          Done
                        </option>
                        <option value="BLOCKED">
                          Blocked
                        </option>
                      </select>
                    </div>
                    <div v-else>
                      {{ props.dataItem.statusType }}
                    </div>
                  </div>
                </td>
              </template>

              <template #actions="{ props }">
                <td>
                  <div v-if="editID === props.dataItem.id">
                    <KodexaButton
                      type="primary" icon="content-save" size="small" class="mr-1"
                      @click="onSave"
                    >
                      Save
                    </KodexaButton>
                    <KodexaButton
                      type="secondary" icon="close" size="small"
                      class="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                      @click="onCancel"
                    >
                      Cancel
                    </KodexaButton>
                  </div>
                  <div v-else>
                    <KodexaButton
                      type="primary" icon="pencil" size="small" class="mr-1"
                      @click="onEdit({ dataItem: props.dataItem })"
                    >
                      Edit
                    </KodexaButton>
                    <KodexaButton
                      type="danger" icon="delete" size="small"
                      @click="removeStatus(props.dataItem)"
                    >
                      Delete
                    </KodexaButton>
                  </div>
                </td>
              </template>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
