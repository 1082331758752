/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  CreateProjectTemplateParams,
  DeleteProjectTemplateParams,
  DeleteVersionProjectTemplateParams,
  ExceptionResponse,
  ListProjectTemplatesForOrganizationParams,
  ListProjectTemplatesParams,
  PageProjectTemplate,
  ProjectTemplate,
  StreamingResponseBody,
  ThrowableProblem
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get the current version of the object with given slug
 */
export const getProjectTemplate = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
      
      return customAxios<ProjectTemplate>(
      {url: `/api/projectTemplates/${organizationSlug}/${slug}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetProjectTemplateQueryKey = (organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,) => {
    return ['api','projectTemplates',organizationSlug,slug] as const;
    }

    
export const getGetProjectTemplateQueryOptions = <TData = Awaited<ReturnType<typeof getProjectTemplate>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectTemplate>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetProjectTemplateQueryKey(organizationSlug,slug);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectTemplate>>> = ({ signal }) => getProjectTemplate(organizationSlug,slug, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug) && unref(slug))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProjectTemplate>>, TError, TData> 
}

export type GetProjectTemplateQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectTemplate>>>
export type GetProjectTemplateQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetProjectTemplate<TData = Awaited<ReturnType<typeof getProjectTemplate>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectTemplate>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetProjectTemplateQueryOptions(organizationSlug,slug,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the current version object with given slug in the organization
 */
export const updateProjectTemplate = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    projectTemplate: MaybeRef<ProjectTemplate>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
projectTemplate = unref(projectTemplate);
      
      return customAxios<ProjectTemplate>(
      {url: `/api/projectTemplates/${organizationSlug}/${slug}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: projectTemplate
    },
      options);
    }
  


export const getUpdateProjectTemplateMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProjectTemplate>>, TError,{organizationSlug: string;slug: string;data: ProjectTemplate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateProjectTemplate>>, TError,{organizationSlug: string;slug: string;data: ProjectTemplate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateProjectTemplate>>, {organizationSlug: string;slug: string;data: ProjectTemplate}> = (props) => {
          const {organizationSlug,slug,data} = props ?? {};

          return  updateProjectTemplate(organizationSlug,slug,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateProjectTemplateMutationResult = NonNullable<Awaited<ReturnType<typeof updateProjectTemplate>>>
    export type UpdateProjectTemplateMutationBody = ProjectTemplate
    export type UpdateProjectTemplateMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateProjectTemplate = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProjectTemplate>>, TError,{organizationSlug: string;slug: string;data: ProjectTemplate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateProjectTemplate>>,
        TError,
        {organizationSlug: string;slug: string;data: ProjectTemplate},
        TContext
      > => {

      const mutationOptions = getUpdateProjectTemplateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the current version of the given object
 */
export const deleteProjectTemplate = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    params?: MaybeRef<DeleteProjectTemplateParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
params = unref(params);
      
      return customAxios<StreamingResponseBody>(
      {url: `/api/projectTemplates/${organizationSlug}/${slug}`, method: 'DELETE',
        params: unref(params)
    },
      options);
    }
  


export const getDeleteProjectTemplateMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProjectTemplate>>, TError,{organizationSlug: string;slug: string;params?: DeleteProjectTemplateParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteProjectTemplate>>, TError,{organizationSlug: string;slug: string;params?: DeleteProjectTemplateParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteProjectTemplate>>, {organizationSlug: string;slug: string;params?: DeleteProjectTemplateParams}> = (props) => {
          const {organizationSlug,slug,params} = props ?? {};

          return  deleteProjectTemplate(organizationSlug,slug,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteProjectTemplateMutationResult = NonNullable<Awaited<ReturnType<typeof deleteProjectTemplate>>>
    
    export type DeleteProjectTemplateMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteProjectTemplate = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProjectTemplate>>, TError,{organizationSlug: string;slug: string;params?: DeleteProjectTemplateParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteProjectTemplate>>,
        TError,
        {organizationSlug: string;slug: string;params?: DeleteProjectTemplateParams},
        TContext
      > => {

      const mutationOptions = getDeleteProjectTemplateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the specific version of the object with given slug
 */
export const getVersionProjectTemplate = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
      
      return customAxios<ProjectTemplate>(
      {url: `/api/projectTemplates/${organizationSlug}/${slug}/${version}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetVersionProjectTemplateQueryKey = (organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,) => {
    return ['api','projectTemplates',organizationSlug,slug,version] as const;
    }

    
export const getGetVersionProjectTemplateQueryOptions = <TData = Awaited<ReturnType<typeof getVersionProjectTemplate>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getVersionProjectTemplate>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetVersionProjectTemplateQueryKey(organizationSlug,slug,version);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getVersionProjectTemplate>>> = ({ signal }) => getVersionProjectTemplate(organizationSlug,slug,version, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug) && unref(slug) && unref(version))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getVersionProjectTemplate>>, TError, TData> 
}

export type GetVersionProjectTemplateQueryResult = NonNullable<Awaited<ReturnType<typeof getVersionProjectTemplate>>>
export type GetVersionProjectTemplateQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetVersionProjectTemplate<TData = Awaited<ReturnType<typeof getVersionProjectTemplate>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getVersionProjectTemplate>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetVersionProjectTemplateQueryOptions(organizationSlug,slug,version,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the object with given slug and version in the organization
 */
export const updateVersionProjectTemplate = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    projectTemplate: MaybeRef<ProjectTemplate>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
projectTemplate = unref(projectTemplate);
      
      return customAxios<ProjectTemplate>(
      {url: `/api/projectTemplates/${organizationSlug}/${slug}/${version}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: projectTemplate
    },
      options);
    }
  


export const getUpdateVersionProjectTemplateMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateVersionProjectTemplate>>, TError,{organizationSlug: string;slug: string;version: string;data: ProjectTemplate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateVersionProjectTemplate>>, TError,{organizationSlug: string;slug: string;version: string;data: ProjectTemplate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateVersionProjectTemplate>>, {organizationSlug: string;slug: string;version: string;data: ProjectTemplate}> = (props) => {
          const {organizationSlug,slug,version,data} = props ?? {};

          return  updateVersionProjectTemplate(organizationSlug,slug,version,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateVersionProjectTemplateMutationResult = NonNullable<Awaited<ReturnType<typeof updateVersionProjectTemplate>>>
    export type UpdateVersionProjectTemplateMutationBody = ProjectTemplate
    export type UpdateVersionProjectTemplateMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateVersionProjectTemplate = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateVersionProjectTemplate>>, TError,{organizationSlug: string;slug: string;version: string;data: ProjectTemplate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateVersionProjectTemplate>>,
        TError,
        {organizationSlug: string;slug: string;version: string;data: ProjectTemplate},
        TContext
      > => {

      const mutationOptions = getUpdateVersionProjectTemplateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the specified version of the given object
 */
export const deleteVersionProjectTemplate = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<DeleteVersionProjectTemplateParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
params = unref(params);
      
      return customAxios<boolean>(
      {url: `/api/projectTemplates/${organizationSlug}/${slug}/${version}`, method: 'DELETE',
        params: unref(params)
    },
      options);
    }
  


export const getDeleteVersionProjectTemplateMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteVersionProjectTemplate>>, TError,{organizationSlug: string;slug: string;version: string;params?: DeleteVersionProjectTemplateParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteVersionProjectTemplate>>, TError,{organizationSlug: string;slug: string;version: string;params?: DeleteVersionProjectTemplateParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteVersionProjectTemplate>>, {organizationSlug: string;slug: string;version: string;params?: DeleteVersionProjectTemplateParams}> = (props) => {
          const {organizationSlug,slug,version,params} = props ?? {};

          return  deleteVersionProjectTemplate(organizationSlug,slug,version,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteVersionProjectTemplateMutationResult = NonNullable<Awaited<ReturnType<typeof deleteVersionProjectTemplate>>>
    
    export type DeleteVersionProjectTemplateMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteVersionProjectTemplate = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteVersionProjectTemplate>>, TError,{organizationSlug: string;slug: string;version: string;params?: DeleteVersionProjectTemplateParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteVersionProjectTemplate>>,
        TError,
        {organizationSlug: string;slug: string;version: string;params?: DeleteVersionProjectTemplateParams},
        TContext
      > => {

      const mutationOptions = getDeleteVersionProjectTemplateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a paginated list of the resources for an organization
 */
export const listProjectTemplatesForOrganization = (
    organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListProjectTemplatesForOrganizationParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
params = unref(params);
      
      return customAxios<PageProjectTemplate>(
      {url: `/api/projectTemplates/${organizationSlug}`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListProjectTemplatesForOrganizationQueryKey = (organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListProjectTemplatesForOrganizationParams>,) => {
    return ['api','projectTemplates',organizationSlug, ...(params ? [params]: [])] as const;
    }

    
export const getListProjectTemplatesForOrganizationQueryOptions = <TData = Awaited<ReturnType<typeof listProjectTemplatesForOrganization>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListProjectTemplatesForOrganizationParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listProjectTemplatesForOrganization>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListProjectTemplatesForOrganizationQueryKey(organizationSlug,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listProjectTemplatesForOrganization>>> = ({ signal }) => listProjectTemplatesForOrganization(organizationSlug,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listProjectTemplatesForOrganization>>, TError, TData> 
}

export type ListProjectTemplatesForOrganizationQueryResult = NonNullable<Awaited<ReturnType<typeof listProjectTemplatesForOrganization>>>
export type ListProjectTemplatesForOrganizationQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useListProjectTemplatesForOrganization<TData = Awaited<ReturnType<typeof listProjectTemplatesForOrganization>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListProjectTemplatesForOrganizationParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listProjectTemplatesForOrganization>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListProjectTemplatesForOrganizationQueryOptions(organizationSlug,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Create a new instance of the object in the organization using the template with given ref
 */
export const createProjectTemplate = (
    organizationSlug: MaybeRef<string>,
    projectTemplate: MaybeRef<ProjectTemplate>,
    params: MaybeRef<CreateProjectTemplateParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
projectTemplate = unref(projectTemplate);
params = unref(params);
      
      return customAxios<ProjectTemplate>(
      {url: `/api/projectTemplates/${organizationSlug}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: projectTemplate,
        params: unref(params), signal
    },
      options);
    }
  


export const getCreateProjectTemplateMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createProjectTemplate>>, TError,{organizationSlug: string;data: ProjectTemplate;params: CreateProjectTemplateParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createProjectTemplate>>, TError,{organizationSlug: string;data: ProjectTemplate;params: CreateProjectTemplateParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createProjectTemplate>>, {organizationSlug: string;data: ProjectTemplate;params: CreateProjectTemplateParams}> = (props) => {
          const {organizationSlug,data,params} = props ?? {};

          return  createProjectTemplate(organizationSlug,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateProjectTemplateMutationResult = NonNullable<Awaited<ReturnType<typeof createProjectTemplate>>>
    export type CreateProjectTemplateMutationBody = ProjectTemplate
    export type CreateProjectTemplateMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateProjectTemplate = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createProjectTemplate>>, TError,{organizationSlug: string;data: ProjectTemplate;params: CreateProjectTemplateParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof createProjectTemplate>>,
        TError,
        {organizationSlug: string;data: ProjectTemplate;params: CreateProjectTemplateParams},
        TContext
      > => {

      const mutationOptions = getCreateProjectTemplateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a paginated list of all resources
 */
export const listProjectTemplates = (
    params?: MaybeRef<ListProjectTemplatesParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageProjectTemplate>(
      {url: `/api/projectTemplates`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListProjectTemplatesQueryKey = (params?: MaybeRef<ListProjectTemplatesParams>,) => {
    return ['api','projectTemplates', ...(params ? [params]: [])] as const;
    }

    
export const getListProjectTemplatesQueryOptions = <TData = Awaited<ReturnType<typeof listProjectTemplates>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListProjectTemplatesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listProjectTemplates>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListProjectTemplatesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listProjectTemplates>>> = ({ signal }) => listProjectTemplates(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listProjectTemplates>>, TError, TData> 
}

export type ListProjectTemplatesQueryResult = NonNullable<Awaited<ReturnType<typeof listProjectTemplates>>>
export type ListProjectTemplatesQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useListProjectTemplates<TData = Awaited<ReturnType<typeof listProjectTemplates>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListProjectTemplatesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listProjectTemplates>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListProjectTemplatesQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Get the change sequence for resource with the provided slug and version
 */
export const getModifiedForProjectTemplate = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
      
      return customAxios<number>(
      {url: `/api/projectTemplates/${organizationSlug}/${slug}/${version}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetModifiedForProjectTemplateQueryKey = (organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,) => {
    return ['api','projectTemplates',organizationSlug,slug,version,'sequence'] as const;
    }

    
export const getGetModifiedForProjectTemplateQueryOptions = <TData = Awaited<ReturnType<typeof getModifiedForProjectTemplate>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getModifiedForProjectTemplate>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetModifiedForProjectTemplateQueryKey(organizationSlug,slug,version);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getModifiedForProjectTemplate>>> = ({ signal }) => getModifiedForProjectTemplate(organizationSlug,slug,version, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug) && unref(slug) && unref(version))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getModifiedForProjectTemplate>>, TError, TData> 
}

export type GetModifiedForProjectTemplateQueryResult = NonNullable<Awaited<ReturnType<typeof getModifiedForProjectTemplate>>>
export type GetModifiedForProjectTemplateQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetModifiedForProjectTemplate<TData = Awaited<ReturnType<typeof getModifiedForProjectTemplate>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getModifiedForProjectTemplate>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetModifiedForProjectTemplateQueryOptions(organizationSlug,slug,version,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



