<script setup lang="ts">
const props = defineProps({
  currentTab: {
    type: Object,
    required: true
  }
});

const emit = defineEmits(['update:currentTab']);

const orderedTabs = computed(() => {
  return [
    {
      ref: "taskManage",
      name: "Overview",
      singleWidget: {
        type: "taskManage",
      },
    },
    {
      ref: "taskWorkspace",
      name: "Workspace",
      singleWidget: {
        type: "taskWorkspace",
      },
    },
  ] as any[];
});
</script>

<template>
  <div class="border-b border-gray-200 dark:border-gray-700">
    <ul class="-mb-px ml-2 flex flex-wrap text-center text-sm font-medium text-gray-500 dark:text-gray-400">
      <li
        v-for="item in orderedTabs"
        :key="item.ref"
        class="mr-2"
        @click="emit('update:currentTab', item)"
      >
        <a
          :id="item.ref"
          :class="[
            item.ref === currentTab.ref
              ? 'inline-flex items-center justify-center p-4 text-theme-primary border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group'
              : 'inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'
          ]"
          class="text-md"
        >
          <MaterialDesignIcon
            v-if="item?.icon"
            :name="item.icon"
            class="text-theme-primary mr-3"
            size="24"
          />
          {{ item.name }}
        </a>
      </li>
    </ul>
  </div>
</template>
