/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  AssignmentRule,
  ExceptionResponse,
  ListAssignmentRulesParams,
  PageAssignmentRule,
  ThrowableProblem
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get a resource with the provided ID
 */
export const getAssignmentRule = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<AssignmentRule>(
      {url: `/api/assignmentRules/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAssignmentRuleQueryKey = (id: MaybeRef<string>,) => {
    return ['api','assignmentRules',id] as const;
    }

    
export const getGetAssignmentRuleQueryOptions = <TData = Awaited<ReturnType<typeof getAssignmentRule>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAssignmentRule>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetAssignmentRuleQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAssignmentRule>>> = ({ signal }) => getAssignmentRule(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAssignmentRule>>, TError, TData> 
}

export type GetAssignmentRuleQueryResult = NonNullable<Awaited<ReturnType<typeof getAssignmentRule>>>
export type GetAssignmentRuleQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetAssignmentRule<TData = Awaited<ReturnType<typeof getAssignmentRule>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAssignmentRule>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetAssignmentRuleQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the given instance
 */
export const updateAssignmentRule = (
    id: MaybeRef<string>,
    assignmentRule: MaybeRef<AssignmentRule>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
assignmentRule = unref(assignmentRule);
      
      return customAxios<AssignmentRule>(
      {url: `/api/assignmentRules/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: assignmentRule
    },
      options);
    }
  


export const getUpdateAssignmentRuleMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAssignmentRule>>, TError,{id: string;data: AssignmentRule}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateAssignmentRule>>, TError,{id: string;data: AssignmentRule}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAssignmentRule>>, {id: string;data: AssignmentRule}> = (props) => {
          const {id,data} = props ?? {};

          return  updateAssignmentRule(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateAssignmentRuleMutationResult = NonNullable<Awaited<ReturnType<typeof updateAssignmentRule>>>
    export type UpdateAssignmentRuleMutationBody = AssignmentRule
    export type UpdateAssignmentRuleMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateAssignmentRule = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAssignmentRule>>, TError,{id: string;data: AssignmentRule}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateAssignmentRule>>,
        TError,
        {id: string;data: AssignmentRule},
        TContext
      > => {

      const mutationOptions = getUpdateAssignmentRuleMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteAssignmentRule = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/assignmentRules/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteAssignmentRuleMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAssignmentRule>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteAssignmentRule>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAssignmentRule>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteAssignmentRule(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteAssignmentRuleMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAssignmentRule>>>
    
    export type DeleteAssignmentRuleMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteAssignmentRule = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAssignmentRule>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteAssignmentRule>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteAssignmentRuleMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listAssignmentRules = (
    params?: MaybeRef<ListAssignmentRulesParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageAssignmentRule>(
      {url: `/api/assignmentRules`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListAssignmentRulesQueryKey = (params?: MaybeRef<ListAssignmentRulesParams>,) => {
    return ['api','assignmentRules', ...(params ? [params]: [])] as const;
    }

    
export const getListAssignmentRulesQueryOptions = <TData = Awaited<ReturnType<typeof listAssignmentRules>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListAssignmentRulesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listAssignmentRules>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListAssignmentRulesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listAssignmentRules>>> = ({ signal }) => listAssignmentRules(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listAssignmentRules>>, TError, TData> 
}

export type ListAssignmentRulesQueryResult = NonNullable<Awaited<ReturnType<typeof listAssignmentRules>>>
export type ListAssignmentRulesQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useListAssignmentRules<TData = Awaited<ReturnType<typeof listAssignmentRules>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListAssignmentRulesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listAssignmentRules>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListAssignmentRulesQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createAssignmentRule = (
    assignmentRule: MaybeRef<AssignmentRule>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      assignmentRule = unref(assignmentRule);
      
      return customAxios<AssignmentRule>(
      {url: `/api/assignmentRules`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: assignmentRule, signal
    },
      options);
    }
  


export const getCreateAssignmentRuleMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAssignmentRule>>, TError,{data: AssignmentRule}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createAssignmentRule>>, TError,{data: AssignmentRule}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAssignmentRule>>, {data: AssignmentRule}> = (props) => {
          const {data} = props ?? {};

          return  createAssignmentRule(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateAssignmentRuleMutationResult = NonNullable<Awaited<ReturnType<typeof createAssignmentRule>>>
    export type CreateAssignmentRuleMutationBody = AssignmentRule
    export type CreateAssignmentRuleMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateAssignmentRule = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAssignmentRule>>, TError,{data: AssignmentRule}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof createAssignmentRule>>,
        TError,
        {data: AssignmentRule},
        TContext
      > => {

      const mutationOptions = getCreateAssignmentRuleMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForAssignmentRule = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/assignmentRules/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForAssignmentRuleQueryKey = (id: MaybeRef<string>,) => {
    return ['api','assignmentRules',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForAssignmentRuleQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForAssignmentRule>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForAssignmentRule>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForAssignmentRuleQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForAssignmentRule>>> = ({ signal }) => getChangeSequenceForAssignmentRule(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForAssignmentRule>>, TError, TData> 
}

export type GetChangeSequenceForAssignmentRuleQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForAssignmentRule>>>
export type GetChangeSequenceForAssignmentRuleQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetChangeSequenceForAssignmentRule<TData = Awaited<ReturnType<typeof getChangeSequenceForAssignmentRule>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForAssignmentRule>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetChangeSequenceForAssignmentRuleQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



