<script setup lang="ts">
import type { Task, User } from "~/model";
import { filterIcon } from "@progress/kendo-svg-icons";
import { Button, Toolbar, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import { computed, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { createConfirmDialog } from "vuejs-confirm-dialog";

import { deleteTask, useListTasks } from "~/api/tasks/tasks";
import KodexaDeleteConfirm from "~/components/kodexa-confirm.vue";
import { createGridHelper } from "~/store/useGridHelper";
import { updateHandler } from "~/utils/error-handler";

const router = useRouter();
const route = useRoute();
const organizationId = computed(() => route.params.organizationId as string);

const gridHelper = createGridHelper(`project-tasks-${organizationId.value}`, {
  page: 1,
  pageSize: 10,
  sort: "createdOn:desc",
  showFilter: false,
  filter: "project.organization.id:'${organizationId.value}'",
  query: "",
});

const showNewTask = ref(false);
const selectedTask = ref<Task | null>(null);

const {
  isLoading,
  isError,
  data,
  error,
  refetch,
} = useListTasks(gridHelper.query);

const computedQuery = computed({
  get: () => gridHelper.query,
  set: value => gridHelper.setQuery(value),
});

const columns = [
  {
    title: "Task Name",
    field: "title",
    cell: "taskName",
  },
  {
    title: "Description",
    field: "description",
    cell: "description",
  },
  {
    title: "Project Name",
    field: "project.name",
  },
  {
    title: "Assignee",
    field: "assignee",
    cell: "assignee",
  },
  {
    title: "Actions",
    field: "description",
    cell: "actions",
    width: "120px",
    filterable: false,
    sortable: false,
  },
];

function navigateToTask(task: Task) {
  const orgId = route.params.organizationId;
  const projectId = task.project?.id;

  if (orgId && projectId && task.id) {
    router.push({
      path: `/f/o/${orgId}/p/${projectId}/t/${task.id}`,
      state: { taskData: task },
    });
  }
}

function editTask(task: Task) {
  if (!task.id) {
    console.error("Cannot edit task without ID");
    return;
  }
  selectedTask.value = task;
  showNewTask.value = true;
}

function formatUserDisplay(user: User | null): string {
  if (!user) {
    return "-";
  }
  return `${user.firstName} ${user.lastName} (${user.email})`;
}

async function handleTaskUpdated() {
  await refetch();
  selectedTask.value = null;
  updateHandler(Promise.resolve(), "Task updated successfully");
}

function handleDialogClose() {
  showNewTask.value = false;
  selectedTask.value = null;
}

async function doDeleteTask(task: Task) {
  if (!task?.id) {
    return;
  }

  const deleteConfirmDialog = createConfirmDialog(KodexaDeleteConfirm);
  const { isCanceled } = await deleteConfirmDialog.reveal({
    title: `Delete task ${task.title}?`,
    message: "This action cannot be undone. All associated activities will also be deleted.",
  });

  // Then delete the task
  await updateHandler(deleteTask(task.id), "Task deleted successfully");
  await refetch();
}

function truncateDescription(text: string, maxLines: number = 3): string {
  if (!text) {
    return "-";
  }
  const words = text.split(" ");
  const truncated = words.slice(0, 30).join(" ");
  return words.length > 30 ? `${truncated}...` : truncated;
}

watch(gridHelper.pageSettings, (newPageSettings) => {
  if (newPageSettings) {
    gridQuery.value.pageSize = newPageSettings.take as number;
  }
});
</script>

<template>
  <div>
    <div class="mx-4">
      <Toolbar class="mb-1 border-0 bg-white">
        <label for="query" class="sr-only">Query</label>
        <KodexaTextInput
          id="query"
          v-model="computedQuery"
          :show-clear="true"
          type="text"
          name="query"
          class="block w-96 rounded-md border-gray-300 shadow-sm sm:text-sm"
          placeholder="Search Tasks"
        />
        <Button
          class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          :svg-icon="filterIcon"
          title="Filter"
          :togglable="true"
          @click="gridHelper.toggleFilter()"
        />
        <ToolbarSpacer />
        <KodexaButton
          id="newTask"
          icon="plus"
          title="New Task"
          @click="showNewTask = true"
        >
          New Task
        </KodexaButton>
      </Toolbar>
      <Grid
        style="height: calc(100vh - 170px)"
        :data-items="data?.content || []"
        :pageable="gridHelper.pageSettings"
        :loader="isLoading"
        :sort="gridHelper.sort"
        :skip="gridHelper.pageSettings.skip as number"
        :total="data?.totalElements || 0"
        :page-size="gridHelper.pageSettings.take as number"
        :take="gridHelper.pageSettings.take as number"
        :columns="columns"
        :filterable="gridHelper.showFilter"
        :filter="gridHelper.filter"
        :resizable="true"
        :sortable="true"
        @pagechange="gridHelper.pageChangeHandler($event)"
        @filterchange="gridHelper.filterChange($event)"
        @sortchange="gridHelper.sortChange($event)"
      >
        <GridNoRecords>
          <KodexaGridNoRecords
            :error="error"
            :is-loading="isLoading"
            :is-error="isError"
            no-records-message="No tasks found"
          />
        </GridNoRecords>
        <template #taskName="{ props }">
          <td>
            <div class="flex items-center">
              <div
                class="flex-0 cursor-pointer font-bold text-gray-900 hover:text-blue-600"
                @click="navigateToTask(props.dataItem)"
              >
                {{ props.dataItem?.title }}
              </div>
            </div>
          </td>
        </template>
        <template #description="{ props }">
          <td>
            <div class="line-clamp-3 max-w-md text-sm text-gray-600">
              {{ truncateDescription(props.dataItem.description) }}
            </div>
          </td>
        </template>
        <template #assignee="{ props }">
          <td>
            <div class="flex items-center">
              <KodexaUserAvatar
                v-if="props.dataItem.assignee"
                :user="props.dataItem.assignee"
                size="8"
                class="mr-2"
              />
              <span class="text-sm text-gray-900">
                {{ formatUserDisplay(props.dataItem.assignee) }}
              </span>
            </div>
          </td>
        </template>
        <template #actions="{ props }">
          <td>
            <div class="flex justify-center space-x-2">
              <KodexaButton
                icon="pencil"
                title="Edit Task"
                size="small"
                @click="editTask(props.dataItem)"
              />
              <KodexaButton
                icon="delete"
                title="Delete Task"
                size="small"
                type="danger"
                @click="doDeleteTask(props.dataItem)"
              />
            </div>
          </td>
        </template>
      </Grid>
      <NewTaskPopover
        v-model="showNewTask"
        :task-to-edit="selectedTask"
        :organization-id="organizationId"
        @update:task-to-edit="(val) => selectedTask = val"
        @task-created="refetch"
        @task-updated="handleTaskUpdated"
        @update:model-value="handleDialogClose"
      />
    </div>
  </div>
</template>

<style scoped>
:deep(button .icon) {
  margin-right: 0 !important;
}
</style>
