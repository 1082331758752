/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  CreateDashboardParams,
  Dashboard,
  DeleteDashboardParams,
  DeleteVersionDashboardParams,
  ExceptionResponse,
  ListDashboardsForOrganizationParams,
  ListDashboardsParams,
  PageDashboard,
  StreamingResponseBody,
  ThrowableProblem
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get the current version of the object with given slug
 */
export const getDashboard = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
      
      return customAxios<Dashboard>(
      {url: `/api/dashboards/${organizationSlug}/${slug}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDashboardQueryKey = (organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,) => {
    return ['api','dashboards',organizationSlug,slug] as const;
    }

    
export const getGetDashboardQueryOptions = <TData = Awaited<ReturnType<typeof getDashboard>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDashboard>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetDashboardQueryKey(organizationSlug,slug);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDashboard>>> = ({ signal }) => getDashboard(organizationSlug,slug, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug) && unref(slug))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDashboard>>, TError, TData> 
}

export type GetDashboardQueryResult = NonNullable<Awaited<ReturnType<typeof getDashboard>>>
export type GetDashboardQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetDashboard<TData = Awaited<ReturnType<typeof getDashboard>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDashboard>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetDashboardQueryOptions(organizationSlug,slug,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the current version object with given slug in the organization
 */
export const updateDashboard = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    dashboard: MaybeRef<Dashboard>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
dashboard = unref(dashboard);
      
      return customAxios<Dashboard>(
      {url: `/api/dashboards/${organizationSlug}/${slug}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: dashboard
    },
      options);
    }
  


export const getUpdateDashboardMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDashboard>>, TError,{organizationSlug: string;slug: string;data: Dashboard}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateDashboard>>, TError,{organizationSlug: string;slug: string;data: Dashboard}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateDashboard>>, {organizationSlug: string;slug: string;data: Dashboard}> = (props) => {
          const {organizationSlug,slug,data} = props ?? {};

          return  updateDashboard(organizationSlug,slug,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateDashboardMutationResult = NonNullable<Awaited<ReturnType<typeof updateDashboard>>>
    export type UpdateDashboardMutationBody = Dashboard
    export type UpdateDashboardMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateDashboard = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDashboard>>, TError,{organizationSlug: string;slug: string;data: Dashboard}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateDashboard>>,
        TError,
        {organizationSlug: string;slug: string;data: Dashboard},
        TContext
      > => {

      const mutationOptions = getUpdateDashboardMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the current version of the given object
 */
export const deleteDashboard = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    params?: MaybeRef<DeleteDashboardParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
params = unref(params);
      
      return customAxios<StreamingResponseBody>(
      {url: `/api/dashboards/${organizationSlug}/${slug}`, method: 'DELETE',
        params: unref(params)
    },
      options);
    }
  


export const getDeleteDashboardMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDashboard>>, TError,{organizationSlug: string;slug: string;params?: DeleteDashboardParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteDashboard>>, TError,{organizationSlug: string;slug: string;params?: DeleteDashboardParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDashboard>>, {organizationSlug: string;slug: string;params?: DeleteDashboardParams}> = (props) => {
          const {organizationSlug,slug,params} = props ?? {};

          return  deleteDashboard(organizationSlug,slug,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteDashboardMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDashboard>>>
    
    export type DeleteDashboardMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteDashboard = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDashboard>>, TError,{organizationSlug: string;slug: string;params?: DeleteDashboardParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteDashboard>>,
        TError,
        {organizationSlug: string;slug: string;params?: DeleteDashboardParams},
        TContext
      > => {

      const mutationOptions = getDeleteDashboardMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the specific version of the object with given slug
 */
export const getVersionDashboard = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
      
      return customAxios<Dashboard>(
      {url: `/api/dashboards/${organizationSlug}/${slug}/${version}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetVersionDashboardQueryKey = (organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,) => {
    return ['api','dashboards',organizationSlug,slug,version] as const;
    }

    
export const getGetVersionDashboardQueryOptions = <TData = Awaited<ReturnType<typeof getVersionDashboard>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getVersionDashboard>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetVersionDashboardQueryKey(organizationSlug,slug,version);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getVersionDashboard>>> = ({ signal }) => getVersionDashboard(organizationSlug,slug,version, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug) && unref(slug) && unref(version))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getVersionDashboard>>, TError, TData> 
}

export type GetVersionDashboardQueryResult = NonNullable<Awaited<ReturnType<typeof getVersionDashboard>>>
export type GetVersionDashboardQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetVersionDashboard<TData = Awaited<ReturnType<typeof getVersionDashboard>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getVersionDashboard>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetVersionDashboardQueryOptions(organizationSlug,slug,version,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the object with given slug and version in the organization
 */
export const updateVersionDashboard = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    dashboard: MaybeRef<Dashboard>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
dashboard = unref(dashboard);
      
      return customAxios<Dashboard>(
      {url: `/api/dashboards/${organizationSlug}/${slug}/${version}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: dashboard
    },
      options);
    }
  


export const getUpdateVersionDashboardMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateVersionDashboard>>, TError,{organizationSlug: string;slug: string;version: string;data: Dashboard}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateVersionDashboard>>, TError,{organizationSlug: string;slug: string;version: string;data: Dashboard}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateVersionDashboard>>, {organizationSlug: string;slug: string;version: string;data: Dashboard}> = (props) => {
          const {organizationSlug,slug,version,data} = props ?? {};

          return  updateVersionDashboard(organizationSlug,slug,version,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateVersionDashboardMutationResult = NonNullable<Awaited<ReturnType<typeof updateVersionDashboard>>>
    export type UpdateVersionDashboardMutationBody = Dashboard
    export type UpdateVersionDashboardMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateVersionDashboard = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateVersionDashboard>>, TError,{organizationSlug: string;slug: string;version: string;data: Dashboard}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateVersionDashboard>>,
        TError,
        {organizationSlug: string;slug: string;version: string;data: Dashboard},
        TContext
      > => {

      const mutationOptions = getUpdateVersionDashboardMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the specified version of the given object
 */
export const deleteVersionDashboard = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<DeleteVersionDashboardParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
params = unref(params);
      
      return customAxios<boolean>(
      {url: `/api/dashboards/${organizationSlug}/${slug}/${version}`, method: 'DELETE',
        params: unref(params)
    },
      options);
    }
  


export const getDeleteVersionDashboardMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteVersionDashboard>>, TError,{organizationSlug: string;slug: string;version: string;params?: DeleteVersionDashboardParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteVersionDashboard>>, TError,{organizationSlug: string;slug: string;version: string;params?: DeleteVersionDashboardParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteVersionDashboard>>, {organizationSlug: string;slug: string;version: string;params?: DeleteVersionDashboardParams}> = (props) => {
          const {organizationSlug,slug,version,params} = props ?? {};

          return  deleteVersionDashboard(organizationSlug,slug,version,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteVersionDashboardMutationResult = NonNullable<Awaited<ReturnType<typeof deleteVersionDashboard>>>
    
    export type DeleteVersionDashboardMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteVersionDashboard = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteVersionDashboard>>, TError,{organizationSlug: string;slug: string;version: string;params?: DeleteVersionDashboardParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteVersionDashboard>>,
        TError,
        {organizationSlug: string;slug: string;version: string;params?: DeleteVersionDashboardParams},
        TContext
      > => {

      const mutationOptions = getDeleteVersionDashboardMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a paginated list of the resources for an organization
 */
export const listDashboardsForOrganization = (
    organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListDashboardsForOrganizationParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
params = unref(params);
      
      return customAxios<PageDashboard>(
      {url: `/api/dashboards/${organizationSlug}`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListDashboardsForOrganizationQueryKey = (organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListDashboardsForOrganizationParams>,) => {
    return ['api','dashboards',organizationSlug, ...(params ? [params]: [])] as const;
    }

    
export const getListDashboardsForOrganizationQueryOptions = <TData = Awaited<ReturnType<typeof listDashboardsForOrganization>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListDashboardsForOrganizationParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listDashboardsForOrganization>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListDashboardsForOrganizationQueryKey(organizationSlug,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listDashboardsForOrganization>>> = ({ signal }) => listDashboardsForOrganization(organizationSlug,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listDashboardsForOrganization>>, TError, TData> 
}

export type ListDashboardsForOrganizationQueryResult = NonNullable<Awaited<ReturnType<typeof listDashboardsForOrganization>>>
export type ListDashboardsForOrganizationQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useListDashboardsForOrganization<TData = Awaited<ReturnType<typeof listDashboardsForOrganization>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListDashboardsForOrganizationParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listDashboardsForOrganization>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListDashboardsForOrganizationQueryOptions(organizationSlug,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Create a new instance of the object in the organization using the template with given ref
 */
export const createDashboard = (
    organizationSlug: MaybeRef<string>,
    dashboard: MaybeRef<Dashboard>,
    params: MaybeRef<CreateDashboardParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
dashboard = unref(dashboard);
params = unref(params);
      
      return customAxios<Dashboard>(
      {url: `/api/dashboards/${organizationSlug}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: dashboard,
        params: unref(params), signal
    },
      options);
    }
  


export const getCreateDashboardMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createDashboard>>, TError,{organizationSlug: string;data: Dashboard;params: CreateDashboardParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createDashboard>>, TError,{organizationSlug: string;data: Dashboard;params: CreateDashboardParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createDashboard>>, {organizationSlug: string;data: Dashboard;params: CreateDashboardParams}> = (props) => {
          const {organizationSlug,data,params} = props ?? {};

          return  createDashboard(organizationSlug,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateDashboardMutationResult = NonNullable<Awaited<ReturnType<typeof createDashboard>>>
    export type CreateDashboardMutationBody = Dashboard
    export type CreateDashboardMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateDashboard = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createDashboard>>, TError,{organizationSlug: string;data: Dashboard;params: CreateDashboardParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof createDashboard>>,
        TError,
        {organizationSlug: string;data: Dashboard;params: CreateDashboardParams},
        TContext
      > => {

      const mutationOptions = getCreateDashboardMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a paginated list of all resources
 */
export const listDashboards = (
    params?: MaybeRef<ListDashboardsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageDashboard>(
      {url: `/api/dashboards`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListDashboardsQueryKey = (params?: MaybeRef<ListDashboardsParams>,) => {
    return ['api','dashboards', ...(params ? [params]: [])] as const;
    }

    
export const getListDashboardsQueryOptions = <TData = Awaited<ReturnType<typeof listDashboards>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListDashboardsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listDashboards>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListDashboardsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listDashboards>>> = ({ signal }) => listDashboards(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listDashboards>>, TError, TData> 
}

export type ListDashboardsQueryResult = NonNullable<Awaited<ReturnType<typeof listDashboards>>>
export type ListDashboardsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useListDashboards<TData = Awaited<ReturnType<typeof listDashboards>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListDashboardsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listDashboards>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListDashboardsQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Get the change sequence for resource with the provided slug and version
 */
export const getModifiedForDashboard = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
      
      return customAxios<number>(
      {url: `/api/dashboards/${organizationSlug}/${slug}/${version}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetModifiedForDashboardQueryKey = (organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,) => {
    return ['api','dashboards',organizationSlug,slug,version,'sequence'] as const;
    }

    
export const getGetModifiedForDashboardQueryOptions = <TData = Awaited<ReturnType<typeof getModifiedForDashboard>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getModifiedForDashboard>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetModifiedForDashboardQueryKey(organizationSlug,slug,version);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getModifiedForDashboard>>> = ({ signal }) => getModifiedForDashboard(organizationSlug,slug,version, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug) && unref(slug) && unref(version))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getModifiedForDashboard>>, TError, TData> 
}

export type GetModifiedForDashboardQueryResult = NonNullable<Awaited<ReturnType<typeof getModifiedForDashboard>>>
export type GetModifiedForDashboardQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetModifiedForDashboard<TData = Awaited<ReturnType<typeof getModifiedForDashboard>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getModifiedForDashboard>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetModifiedForDashboardQueryOptions(organizationSlug,slug,version,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



