<script setup lang="ts">
import { Toolbar } from "@progress/kendo-vue-buttons";
import { Pager } from "@progress/kendo-vue-data-tools";
import { v4 as uuidv4 } from "uuid";
import { availableWidgets } from "~/components/dashboard/widgets/widgets";
import appStore from "~/store";

const props = defineProps({
  dashboard: {
    type: Object,
    required: true,
  },
  entryPoints: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(["closed"]);
const filter = ref("");

function selectWidget(widget: any) {
  // Clone the widget
  const newWidget = JSON.parse(JSON.stringify(widget));
  newWidget.id = uuidv4();

  if (props.entryPoints?.includes("organization")) {
    appStore.organizationStore.addWidgetToDashboard(props.dashboard, newWidget);
    emit("closed");
  } else {
    appStore.projectStore.addWidgetToDashboard(props.dashboard, newWidget);
    emit("closed");
  }
}

const skip = ref(0);
const take = ref(50);

function pageChangeHandler(event: any) {
  skip.value = event.skip;
  take.value = event.take;
}

const widgets = computed(() => {
  // We want to get a page of widgets where the page is page
  // and page size is 8
  return availableWidgets.filter(w => !w.hidden && !w.dashboard && (filter.value.trim() === "" || w.name.toLowerCase().includes(filter.value.toLowerCase()))).slice(skip.value, skip.value + take.value);
});
</script>

<template>
  <div>
    <KodexaHeader
      title="Widget Library" sub-title="Choose a widget to add to your dashboard"
    >
      <template #subTitle>
        Choose a widget to add to your dashboard
      </template>
      <template #actions>
        <div class="flex h-7 items-center">
          <button type="button" class="text-gray-400 hover:text-gray-500" @click="emit('closed')">
            <span class="sr-only">Close panel</span>
            <MaterialDesignIcon name="close" class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </template>
    </KodexaHeader>
    <Toolbar class="mx-4 mb-1 mt-2 border-0 bg-white">
      <label for="query" class="sr-only">Query</label>
      <KodexaTextInput
        id="query" v-model="filter" type="text" name="query"
        :show-clear="true"
        class="block w-96 rounded-md border-gray-300 shadow-sm sm:text-sm" placeholder="Filter..."
      />
    </Toolbar>

    <div>
      <Pager
        ref="pager"
        class="mx-4 mb-1 mt-2"
        :page-size="8"
        :button-count="3"
        :skip="0"
        :total="widgets.length"
        :take="8"
        @pagechange="pageChangeHandler"
      />
      <div class="border-1 border-gray-600" style="height: calc(100vh - 25rem); overflow-y: scroll">
        <div class="p-4">
          <ul v-if="widgets" role="list" class="grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
            <li
              v-for="widget in widgets" :key="widget.type"
              class="group col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white shadow"
            >
              <div class="flex flex-1 p-2 cursor-pointer" style="overflow-y: hidden" @click="selectWidget(widget)">
                <img
                  class="m-2 mr-4 h-14 w-14"
                  :src="widget.imageUrl?.replace('https://images.kodexa.com/', '/assets/')" alt=""
                >
                <dl class="mr-2 mt-1 flex grow flex-col">
                  <div class="text-md font-semibold text-black">
                    {{ widget.name }}
                  </div>
                  <dd class="mt-3 text-sm">
                    <span>{{ widget.description }}</span>
                  </dd>
                </dl>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
