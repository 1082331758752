<script lang="ts" setup>
import type { PropType } from "vue";
import appStore from "~/store";

const props = defineProps({
  keyName: {
    type: String,
    required: true,
  },
  node: {
    type: Object as PropType<Node>,
  },
  nodeColor: {
    type: String,
    default: '#23ABDC',
  },
  textColor: {
    type: String,
    default: 'white',
  },
});

const title = computed(() => {
  if (props.keyName === "external_data") {
    return "External Data";
  }
  if (props.keyName === "final_llm_response") {
    return "Final LLM Response";
  }
  return "JSON Schema";
});

function openSchema() {
  appStore.workspaceStore.createTextViewer(`${props.node.data.clean.label} ${title.value}`, JSON.stringify(props.node.data.raw[props.keyName], null, 2));
}
</script>

<template>
  <KodexaButton
    class="open-schema"
    v-tooltip="`View ${title}`"
    name="openSchema"
    size="medium"
    icon="sitemap-outline"
    @click="openSchema"
  >
  </KodexaButton>
</template>

<style scoped>
.open-schema {
  height: 40px;
  width: 40px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  background-color: white !important;
  color: v-bind(nodeColor) !important;
  border-radius: 100px;
  border: 1px solid v-bind(nodeColor);
}

.open-schema:hover {
  opacity: 0.8;
}

</style>
