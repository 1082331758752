/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  ExceptionResponse,
  Label,
  ListLabelsParams,
  PageLabel,
  ThrowableProblem
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get a resource with the provided ID
 */
export const getLabel = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<Label>(
      {url: `/api/labels/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetLabelQueryKey = (id: MaybeRef<string>,) => {
    return ['api','labels',id] as const;
    }

    
export const getGetLabelQueryOptions = <TData = Awaited<ReturnType<typeof getLabel>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getLabel>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetLabelQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getLabel>>> = ({ signal }) => getLabel(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getLabel>>, TError, TData> 
}

export type GetLabelQueryResult = NonNullable<Awaited<ReturnType<typeof getLabel>>>
export type GetLabelQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetLabel<TData = Awaited<ReturnType<typeof getLabel>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getLabel>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetLabelQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the given instance
 */
export const updateLabel = (
    id: MaybeRef<string>,
    label: MaybeRef<Label>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
label = unref(label);
      
      return customAxios<Label>(
      {url: `/api/labels/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: label
    },
      options);
    }
  


export const getUpdateLabelMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateLabel>>, TError,{id: string;data: Label}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateLabel>>, TError,{id: string;data: Label}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateLabel>>, {id: string;data: Label}> = (props) => {
          const {id,data} = props ?? {};

          return  updateLabel(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateLabelMutationResult = NonNullable<Awaited<ReturnType<typeof updateLabel>>>
    export type UpdateLabelMutationBody = Label
    export type UpdateLabelMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateLabel = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateLabel>>, TError,{id: string;data: Label}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateLabel>>,
        TError,
        {id: string;data: Label},
        TContext
      > => {

      const mutationOptions = getUpdateLabelMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteLabel = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/labels/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteLabelMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteLabel>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteLabel>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteLabel>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteLabel(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteLabelMutationResult = NonNullable<Awaited<ReturnType<typeof deleteLabel>>>
    
    export type DeleteLabelMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteLabel = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteLabel>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteLabel>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteLabelMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listLabels = (
    params?: MaybeRef<ListLabelsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageLabel>(
      {url: `/api/labels`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListLabelsQueryKey = (params?: MaybeRef<ListLabelsParams>,) => {
    return ['api','labels', ...(params ? [params]: [])] as const;
    }

    
export const getListLabelsQueryOptions = <TData = Awaited<ReturnType<typeof listLabels>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListLabelsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listLabels>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListLabelsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listLabels>>> = ({ signal }) => listLabels(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listLabels>>, TError, TData> 
}

export type ListLabelsQueryResult = NonNullable<Awaited<ReturnType<typeof listLabels>>>
export type ListLabelsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useListLabels<TData = Awaited<ReturnType<typeof listLabels>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListLabelsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listLabels>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListLabelsQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createLabel = (
    label: MaybeRef<Label>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      label = unref(label);
      
      return customAxios<Label>(
      {url: `/api/labels`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: label, signal
    },
      options);
    }
  


export const getCreateLabelMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createLabel>>, TError,{data: Label}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createLabel>>, TError,{data: Label}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createLabel>>, {data: Label}> = (props) => {
          const {data} = props ?? {};

          return  createLabel(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateLabelMutationResult = NonNullable<Awaited<ReturnType<typeof createLabel>>>
    export type CreateLabelMutationBody = Label
    export type CreateLabelMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateLabel = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createLabel>>, TError,{data: Label}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof createLabel>>,
        TError,
        {data: Label},
        TContext
      > => {

      const mutationOptions = getCreateLabelMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForLabel = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/labels/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForLabelQueryKey = (id: MaybeRef<string>,) => {
    return ['api','labels',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForLabelQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForLabel>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForLabel>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForLabelQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForLabel>>> = ({ signal }) => getChangeSequenceForLabel(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForLabel>>, TError, TData> 
}

export type GetChangeSequenceForLabelQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForLabel>>>
export type GetChangeSequenceForLabelQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetChangeSequenceForLabel<TData = Awaited<ReturnType<typeof getChangeSequenceForLabel>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForLabel>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetChangeSequenceForLabelQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



