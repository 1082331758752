<script setup lang="ts">
import type { Task } from "~/model";
import { nextTick, onMounted, watch } from "vue";
import KodexaUserAvatar from "~/components/kodexa-user-avatar.vue";
import KodexaDateTimeView from "~/components/util/kodexa-date-time-view.vue";

const props = defineProps({
  currentTask: {
    type: Object as PropType<Task>,
    required: true,
  },
  taskActivities: {
    type: Object,
    required: true,
  },
  formatActivityMessage: {
    type: Function,
    required: true,
  },
  formatTimestamp: {
    type: Function,
    required: true,
  },
  handleTextareaResize: {
    type: Function,
    required: true,
  },
});

const emit = defineEmits(["update:currentTask"]);

function adjustTextareaHeight() {
  nextTick(() => {
    const textarea = document.querySelector("textarea");
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  });
}

// Adjust height when component is mounted
onMounted(adjustTextareaHeight);

// Watch for changes in currentTask description
watch(() => props.currentTask.description, adjustTextareaHeight, { immediate: true });

// Function to format the full date for tooltip
function formatFullDate(date: string) {
  const activityDate = new Date(date);
  activityDate.setHours(activityDate.getHours() + 8); // Add 8 hours for PHT

  return activityDate.toLocaleString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
    timeZone: "Asia/Manila",
  });
}
</script>

<template>
  <div class="space-y-4">
    <KodexaTextInput
      v-model="currentTask.title"
      borderless
      font-size="xl"
      font-weight="bold"
      name="task-title"
      class="w-full border-none focus:outline-none focus:ring-0 placeholder-gray-400"
      placeholder="Task Name"
      @input="$emit('update:currentTask', currentTask)"
    />
    <textarea
      v-model="currentTask.description"
      class="w-full min-h-[24px] text-gray-500 border-none focus:outline-none focus:ring-0 placeholder-gray-400 resize-none transition-all duration-100 ease-in-out"
      placeholder="Add description..."
      rows="1"
      @input="handleTextareaResize"
    />

    <!-- Activity Logs -->
    <div v-if="taskActivities?.content?.length" class="mt-8 pt-8 space-y-4 border-t border-gray-200">
      <h3 class="text-lg font-bold">
        Activity
      </h3>
      <div class="space-y-2">
        <div
          v-for="activity in taskActivities.content"
          :key="activity.id"
          class="flex items-start gap-x-3 text-sm"
        >
          <KodexaUserAvatar
            :user="activity.user"
            size="5"
            class="flex-shrink-0 rounded-full"
          />
          <div class="flex-1 min-w-0">
            <p class="text-gray-900" v-html="formatActivityMessage(activity)" />
            <p class="text-gray-500 text-xs">
              <span v-tooltip="formatFullDate(activity.createdOn)">
                <KodexaDateTimeView
                  :date="activity.createdOn"
                  ago
                />
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
:deep(.kodexa-input .k-input-inner::placeholder) {
  @apply font-normal text-gray-400 !important;
}
</style>
