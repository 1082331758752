/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  ExceptionResponse,
  ListTaskTemplatesParams,
  PageTaskTemplate,
  TaskTemplate,
  ThrowableProblem
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get a resource with the provided ID
 */
export const getTaskTemplate = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<TaskTemplate>(
      {url: `/api/taskTemplates/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTaskTemplateQueryKey = (id: MaybeRef<string>,) => {
    return ['api','taskTemplates',id] as const;
    }

    
export const getGetTaskTemplateQueryOptions = <TData = Awaited<ReturnType<typeof getTaskTemplate>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTaskTemplate>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetTaskTemplateQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTaskTemplate>>> = ({ signal }) => getTaskTemplate(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTaskTemplate>>, TError, TData> 
}

export type GetTaskTemplateQueryResult = NonNullable<Awaited<ReturnType<typeof getTaskTemplate>>>
export type GetTaskTemplateQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetTaskTemplate<TData = Awaited<ReturnType<typeof getTaskTemplate>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTaskTemplate>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetTaskTemplateQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the given instance
 */
export const updateTaskTemplate = (
    id: MaybeRef<string>,
    taskTemplate: MaybeRef<TaskTemplate>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
taskTemplate = unref(taskTemplate);
      
      return customAxios<TaskTemplate>(
      {url: `/api/taskTemplates/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: taskTemplate
    },
      options);
    }
  


export const getUpdateTaskTemplateMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTaskTemplate>>, TError,{id: string;data: TaskTemplate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateTaskTemplate>>, TError,{id: string;data: TaskTemplate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateTaskTemplate>>, {id: string;data: TaskTemplate}> = (props) => {
          const {id,data} = props ?? {};

          return  updateTaskTemplate(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateTaskTemplateMutationResult = NonNullable<Awaited<ReturnType<typeof updateTaskTemplate>>>
    export type UpdateTaskTemplateMutationBody = TaskTemplate
    export type UpdateTaskTemplateMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateTaskTemplate = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTaskTemplate>>, TError,{id: string;data: TaskTemplate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateTaskTemplate>>,
        TError,
        {id: string;data: TaskTemplate},
        TContext
      > => {

      const mutationOptions = getUpdateTaskTemplateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteTaskTemplate = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/taskTemplates/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteTaskTemplateMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTaskTemplate>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteTaskTemplate>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTaskTemplate>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteTaskTemplate(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteTaskTemplateMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTaskTemplate>>>
    
    export type DeleteTaskTemplateMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteTaskTemplate = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTaskTemplate>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteTaskTemplate>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteTaskTemplateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listTaskTemplates = (
    params?: MaybeRef<ListTaskTemplatesParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageTaskTemplate>(
      {url: `/api/taskTemplates`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListTaskTemplatesQueryKey = (params?: MaybeRef<ListTaskTemplatesParams>,) => {
    return ['api','taskTemplates', ...(params ? [params]: [])] as const;
    }

    
export const getListTaskTemplatesQueryOptions = <TData = Awaited<ReturnType<typeof listTaskTemplates>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListTaskTemplatesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTaskTemplates>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListTaskTemplatesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listTaskTemplates>>> = ({ signal }) => listTaskTemplates(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listTaskTemplates>>, TError, TData> 
}

export type ListTaskTemplatesQueryResult = NonNullable<Awaited<ReturnType<typeof listTaskTemplates>>>
export type ListTaskTemplatesQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useListTaskTemplates<TData = Awaited<ReturnType<typeof listTaskTemplates>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListTaskTemplatesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTaskTemplates>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListTaskTemplatesQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createTaskTemplate = (
    taskTemplate: MaybeRef<TaskTemplate>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      taskTemplate = unref(taskTemplate);
      
      return customAxios<TaskTemplate>(
      {url: `/api/taskTemplates`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: taskTemplate, signal
    },
      options);
    }
  


export const getCreateTaskTemplateMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTaskTemplate>>, TError,{data: TaskTemplate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createTaskTemplate>>, TError,{data: TaskTemplate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTaskTemplate>>, {data: TaskTemplate}> = (props) => {
          const {data} = props ?? {};

          return  createTaskTemplate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateTaskTemplateMutationResult = NonNullable<Awaited<ReturnType<typeof createTaskTemplate>>>
    export type CreateTaskTemplateMutationBody = TaskTemplate
    export type CreateTaskTemplateMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateTaskTemplate = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTaskTemplate>>, TError,{data: TaskTemplate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof createTaskTemplate>>,
        TError,
        {data: TaskTemplate},
        TContext
      > => {

      const mutationOptions = getCreateTaskTemplateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForTaskTemplate = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/taskTemplates/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForTaskTemplateQueryKey = (id: MaybeRef<string>,) => {
    return ['api','taskTemplates',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForTaskTemplateQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForTaskTemplate>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForTaskTemplate>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForTaskTemplateQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForTaskTemplate>>> = ({ signal }) => getChangeSequenceForTaskTemplate(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForTaskTemplate>>, TError, TData> 
}

export type GetChangeSequenceForTaskTemplateQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForTaskTemplate>>>
export type GetChangeSequenceForTaskTemplateQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetChangeSequenceForTaskTemplate<TData = Awaited<ReturnType<typeof getChangeSequenceForTaskTemplate>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForTaskTemplate>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetChangeSequenceForTaskTemplateQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



