import { defineStore, storeToRefs } from "pinia";
import type { ILogObj } from "tslog";
import { Logger } from "tslog";
import type { DataAttribute, DataException, DataObject, Taxon, TaxonValidation, Taxonomy } from "~/model";
import appStore from "~/store/index";

/**
 * We have a single instance of validators that we use within the
 * workspace to ensure that all the validations are run on the data objects and
 * data attributes
 */
export const useValidators = defineStore("validators", () => {
  const log: Logger<ILogObj> = new Logger({ name: "validators" });

  const { dataObjects } = storeToRefs(appStore.workspaceStore);
  const { contentTaxonomies, tagMetadataMap } = storeToRefs(appStore.projectStore);
  const taxonomyFormulaServices = new Map<string, any>();

  // We have a few ways in which we can get validation
  // 1. From the taxonomy, so we want to store those by path
  // 2. From the data object, so we want to store those by data object
  // 3. From the data attribute, so we want to store those by data object and path
  const validationsByTaxonPath = computed(() => {
    const validations = new Map<string, TaxonValidation[]>();

    function processTaxon(taxon: Taxon) {
      // Process validations for current node
      if (taxon.validationRules) {
        taxon.validationRules.forEach((validation) => {
          if (!validations.has(taxon.path as string)) {
            validations.set(taxon.path as string, []);
          }
          validations.get(taxon.path as string)?.push(validation);
        });
      }

      // Recursively process children
      if (taxon.children) {
        taxon.children.forEach(child => processTaxon(child));
      }
    }

    contentTaxonomies.value.forEach((taxonomy: Taxonomy) => {
      taxonomyFormulaServices.set(taxonomy.ref as string, createKodexaFormulaService(ref(taxonomy)));
      taxonomy.taxons?.forEach((taxon: Taxon) => {
        processTaxon(taxon);
      });
    });

    return validations;
  });

  const validationsByDataObject = computed(() => {
    const validations = new Map<string, TaxonValidation[]>();
    dataObjects.value.forEach((dataObject: DataObject) => {
      if (dataObject.lineage?.validations) {
        validations.set(dataObject.uuid as string, dataObject.lineage.validations);
      }

      // if we have attribute validations we will add them to the data object dict with /path on the end
      if (dataObject.lineage?.attributeValidations) {
        Object.keys(dataObject.lineage.attributeValidations).forEach((path) => {
          if (!validations.has(`${dataObject.uuid}/${path}`)) {
            validations.set(`${dataObject.uuid}/${path}`, []);
          }
          if (dataObject.lineage?.attributeValidations) {
            validations.get(`${dataObject.uuid}/${path}`)?.push(...dataObject.lineage.attributeValidations[path]);
          }
        });
      }
    });

    return validations;
  });

  function createValidationException(formulaService: any, validation: TaxonValidation, dataObject: DataObject, attribute?: DataAttribute) {
    const message = formulaService.buildMessage(validation.messageFormula, tagMetadataMap.value.get(dataObject.path), dataObject, Array.from(dataObjects.value.values()));
    const exceptionDetails = formulaService.buildMessage(validation.detailFormula, tagMetadataMap.value.get(dataObject.path), dataObject, Array.from(dataObjects.value.values()));
    return { message, exceptionDetails, dataAttribute: attribute, dataObject } as DataException;
  }

  function processValidation(formulaService: any, validation: TaxonValidation, dataObject: DataObject, attribute?: DataAttribute) {
    const result = formulaService.evaluateFormula(validation.ruleFormula, tagMetadataMap.value.get(dataObject.path), dataObject, Array.from(dataObjects.value.values()));
    if (result.result === false) {
      const path = attribute ? `${dataObject.uuid}/${attribute.path}` : dataObject.uuid;
      log.info(`Validation failed for ${path}`);

      const newDataException = createValidationException(formulaService, validation, dataObject, attribute);
      if (!dataObject.dataExceptions) {
        dataObject.dataExceptions = [];
      }
      dataObject.dataExceptions.push(newDataException);
    }
  }

  function runDataObjectValidation(dataObject: DataObject, validations: TaxonValidation[]) {
    log.info(`Running validations for ${dataObject.uuid}`);
    const formulaService = taxonomyFormulaServices.get(dataObject.taxonomyRef as string);
    validations.forEach(validation => processValidation(formulaService, validation, dataObject));
  }

  function runDataAttributeValidation(dataObject: DataObject, attribute: DataAttribute, validations: TaxonValidation[]) {
    log.info(`Running validations for ${dataObject.uuid}/${attribute.path}`);
    const formulaService = taxonomyFormulaServices.get(dataObject.taxonomyRef as string);
    validations.forEach(validation => processValidation(formulaService, validation, dataObject, attribute));
  }

  // If any of the data objects change we need to recompute the validations
  watch(dataObjects, () => {
    log.info("Data objects changed, recomputing validations");

    if (!dataObjects) {
      return;
    }


    // We need to run the validations based on the paths for all the dataobjects and attributes
    // we have
    dataObjects.value.forEach((dataObject: DataObject) => {
      if (!validationsByDataObject.value.has(dataObject.uuid as string)) {
        runDataObjectValidation(dataObject, validationsByDataObject.value.get(dataObject.uuid as string) as TaxonValidation[]);
      }

      if (validationsByTaxonPath.value.has(dataObject.path as string)) {
        runDataObjectValidation(dataObject, validationsByTaxonPath.value.get(dataObject.path as string) as TaxonValidation[]);
      }

      // Let's see if we have attributes that have validations
      if (dataObject.attributes) {
        dataObject.attributes.forEach((attribute: DataAttribute) => {
          if (validationsByDataObject.value.has(`${dataObject.uuid}/${attribute.path}`)) {
            runDataAttributeValidation(dataObject, attribute, validationsByDataObject.value.get(`${dataObject.uuid}/${attribute.path}`) as TaxonValidation[]);
          }
          if (validationsByTaxonPath.value.has(attribute.path as string)) {
            runDataAttributeValidation(dataObject, attribute, validationsByTaxonPath.value.get(attribute.path as string) as TaxonValidation[]);
          }
        });
      }
    });
  }, { immediate: true, deep: true });

  return {
  };
});
