<script setup lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import type {PropType, Ref} from "vue";
import type {Execution, ExecutionLogEntry} from "~/model";
import {Toolbar} from "@progress/kendo-vue-buttons";
import {getExecutionLogs} from "~/api/executions/executions";
import {copyToClipboard} from "~/utils/general";

const props = defineProps({
  execution: Object as PropType<Execution>,
});
const emit = defineEmits(["confirm", "cancel"]);
const open = ref(true);
const logs: Ref<string | undefined> = ref(undefined);

const currentPage = ref(1);

async function loadLogs() {
  if (props.execution) {
    const response = await getExecutionLogs(props.execution.id as string, {
      page: currentPage.value,
      pageSize: 100,
      sort: "logSequence:asc",
    });
    if (logs.value === undefined) {
      logs.value = "";
    }
    if (response.content && response.content.length > 0) {
      logs.value = `${logs.value}\n${response.content
        ? response.content.map((log: ExecutionLogEntry) => {
          return `${log.logDate}: ${log.entry}`;
        }).join("\n")
        : ""}`;
    }
    currentPage.value++;
  }

  setTimeout(() => {
    loadLogs();
  }, 5);
}

function copyIt() {
  if (logs.value) {
    copyToClipboard(logs.value, "Copied to clipboard");
  }
}

function download() {
  if (logs.value) {
    const blob = new Blob([logs.value], {type: "text/plain"});
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "logs.txt";
    a.click();
    URL.revokeObjectURL(url);
  }
}

loadLogs();
</script>
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="open = false" >
      <TransitionChild
        as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500/75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0" >
          <TransitionChild
            as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel

              class="relative overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 m:p-6"
              style="width: 90%; height: 90%"
            >
              <Toolbar class="mb-1 border-0 bg-white">
                <KodexaButton icon="content-copy" size="small" type="secondary" @click="copyIt">
                  Copy to Clipboard
                </KodexaButton>
                <KodexaButton icon="download" size="small" type="secondary" @click="download">
                  Download
                </KodexaButton>
                <KodexaButton icon="close" size="small" type="secondary" @click="emit('cancel')">
                  Close
                </KodexaButton>
              </Toolbar>
              <KodexaCodeEditor
                v-if="logs" v-model="logs" style="height: calc(100vh - 8rem); width: 100%" :readonly="true"
                :language="'python'"
              />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
