/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  CreateExtensionPackParams,
  DeleteExtensionPackParams,
  DeleteVersionExtensionPackParams,
  DeploymentOptions,
  ExceptionResponse,
  ExtensionPack,
  ExtensionPackStatus,
  ListExtensionPacksForOrganizationParams,
  ListExtensionPacksParams,
  PageExtensionPack,
  StreamingResponseBody,
  ThrowableProblem
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get the current version of the object with given slug
 */
export const getExtensionPack = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
      
      return customAxios<ExtensionPack>(
      {url: `/api/extensionPacks/${organizationSlug}/${slug}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetExtensionPackQueryKey = (organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,) => {
    return ['api','extensionPacks',organizationSlug,slug] as const;
    }

    
export const getGetExtensionPackQueryOptions = <TData = Awaited<ReturnType<typeof getExtensionPack>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getExtensionPack>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetExtensionPackQueryKey(organizationSlug,slug);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExtensionPack>>> = ({ signal }) => getExtensionPack(organizationSlug,slug, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug) && unref(slug))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getExtensionPack>>, TError, TData> 
}

export type GetExtensionPackQueryResult = NonNullable<Awaited<ReturnType<typeof getExtensionPack>>>
export type GetExtensionPackQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetExtensionPack<TData = Awaited<ReturnType<typeof getExtensionPack>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getExtensionPack>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetExtensionPackQueryOptions(organizationSlug,slug,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the current version object with given slug in the organization
 */
export const updateExtensionPack = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    extensionPack: MaybeRef<ExtensionPack>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
extensionPack = unref(extensionPack);
      
      return customAxios<ExtensionPack>(
      {url: `/api/extensionPacks/${organizationSlug}/${slug}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: extensionPack
    },
      options);
    }
  


export const getUpdateExtensionPackMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateExtensionPack>>, TError,{organizationSlug: string;slug: string;data: ExtensionPack}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateExtensionPack>>, TError,{organizationSlug: string;slug: string;data: ExtensionPack}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateExtensionPack>>, {organizationSlug: string;slug: string;data: ExtensionPack}> = (props) => {
          const {organizationSlug,slug,data} = props ?? {};

          return  updateExtensionPack(organizationSlug,slug,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateExtensionPackMutationResult = NonNullable<Awaited<ReturnType<typeof updateExtensionPack>>>
    export type UpdateExtensionPackMutationBody = ExtensionPack
    export type UpdateExtensionPackMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateExtensionPack = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateExtensionPack>>, TError,{organizationSlug: string;slug: string;data: ExtensionPack}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateExtensionPack>>,
        TError,
        {organizationSlug: string;slug: string;data: ExtensionPack},
        TContext
      > => {

      const mutationOptions = getUpdateExtensionPackMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the current version of the given object
 */
export const deleteExtensionPack = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    params?: MaybeRef<DeleteExtensionPackParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
params = unref(params);
      
      return customAxios<StreamingResponseBody>(
      {url: `/api/extensionPacks/${organizationSlug}/${slug}`, method: 'DELETE',
        params: unref(params)
    },
      options);
    }
  


export const getDeleteExtensionPackMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteExtensionPack>>, TError,{organizationSlug: string;slug: string;params?: DeleteExtensionPackParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteExtensionPack>>, TError,{organizationSlug: string;slug: string;params?: DeleteExtensionPackParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteExtensionPack>>, {organizationSlug: string;slug: string;params?: DeleteExtensionPackParams}> = (props) => {
          const {organizationSlug,slug,params} = props ?? {};

          return  deleteExtensionPack(organizationSlug,slug,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteExtensionPackMutationResult = NonNullable<Awaited<ReturnType<typeof deleteExtensionPack>>>
    
    export type DeleteExtensionPackMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteExtensionPack = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteExtensionPack>>, TError,{organizationSlug: string;slug: string;params?: DeleteExtensionPackParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteExtensionPack>>,
        TError,
        {organizationSlug: string;slug: string;params?: DeleteExtensionPackParams},
        TContext
      > => {

      const mutationOptions = getDeleteExtensionPackMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the specific version of the object with given slug
 */
export const getVersionExtensionPack = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
      
      return customAxios<ExtensionPack>(
      {url: `/api/extensionPacks/${organizationSlug}/${slug}/${version}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetVersionExtensionPackQueryKey = (organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,) => {
    return ['api','extensionPacks',organizationSlug,slug,version] as const;
    }

    
export const getGetVersionExtensionPackQueryOptions = <TData = Awaited<ReturnType<typeof getVersionExtensionPack>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getVersionExtensionPack>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetVersionExtensionPackQueryKey(organizationSlug,slug,version);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getVersionExtensionPack>>> = ({ signal }) => getVersionExtensionPack(organizationSlug,slug,version, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug) && unref(slug) && unref(version))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getVersionExtensionPack>>, TError, TData> 
}

export type GetVersionExtensionPackQueryResult = NonNullable<Awaited<ReturnType<typeof getVersionExtensionPack>>>
export type GetVersionExtensionPackQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetVersionExtensionPack<TData = Awaited<ReturnType<typeof getVersionExtensionPack>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getVersionExtensionPack>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetVersionExtensionPackQueryOptions(organizationSlug,slug,version,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the object with given slug and version in the organization
 */
export const updateVersionExtensionPack = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    extensionPack: MaybeRef<ExtensionPack>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
extensionPack = unref(extensionPack);
      
      return customAxios<ExtensionPack>(
      {url: `/api/extensionPacks/${organizationSlug}/${slug}/${version}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: extensionPack
    },
      options);
    }
  


export const getUpdateVersionExtensionPackMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateVersionExtensionPack>>, TError,{organizationSlug: string;slug: string;version: string;data: ExtensionPack}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateVersionExtensionPack>>, TError,{organizationSlug: string;slug: string;version: string;data: ExtensionPack}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateVersionExtensionPack>>, {organizationSlug: string;slug: string;version: string;data: ExtensionPack}> = (props) => {
          const {organizationSlug,slug,version,data} = props ?? {};

          return  updateVersionExtensionPack(organizationSlug,slug,version,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateVersionExtensionPackMutationResult = NonNullable<Awaited<ReturnType<typeof updateVersionExtensionPack>>>
    export type UpdateVersionExtensionPackMutationBody = ExtensionPack
    export type UpdateVersionExtensionPackMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateVersionExtensionPack = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateVersionExtensionPack>>, TError,{organizationSlug: string;slug: string;version: string;data: ExtensionPack}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateVersionExtensionPack>>,
        TError,
        {organizationSlug: string;slug: string;version: string;data: ExtensionPack},
        TContext
      > => {

      const mutationOptions = getUpdateVersionExtensionPackMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the specified version of the given object
 */
export const deleteVersionExtensionPack = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<DeleteVersionExtensionPackParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
params = unref(params);
      
      return customAxios<boolean>(
      {url: `/api/extensionPacks/${organizationSlug}/${slug}/${version}`, method: 'DELETE',
        params: unref(params)
    },
      options);
    }
  


export const getDeleteVersionExtensionPackMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteVersionExtensionPack>>, TError,{organizationSlug: string;slug: string;version: string;params?: DeleteVersionExtensionPackParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteVersionExtensionPack>>, TError,{organizationSlug: string;slug: string;version: string;params?: DeleteVersionExtensionPackParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteVersionExtensionPack>>, {organizationSlug: string;slug: string;version: string;params?: DeleteVersionExtensionPackParams}> = (props) => {
          const {organizationSlug,slug,version,params} = props ?? {};

          return  deleteVersionExtensionPack(organizationSlug,slug,version,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteVersionExtensionPackMutationResult = NonNullable<Awaited<ReturnType<typeof deleteVersionExtensionPack>>>
    
    export type DeleteVersionExtensionPackMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteVersionExtensionPack = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteVersionExtensionPack>>, TError,{organizationSlug: string;slug: string;version: string;params?: DeleteVersionExtensionPackParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteVersionExtensionPack>>,
        TError,
        {organizationSlug: string;slug: string;version: string;params?: DeleteVersionExtensionPackParams},
        TContext
      > => {

      const mutationOptions = getDeleteVersionExtensionPackMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Undeploy the extension pack
 */
export const undeploy = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
      
      return customAxios<ExtensionPack>(
      {url: `/api/extensionPacks/${organizationSlug}/${slug}/${version}/undeploy`, method: 'PUT'
    },
      options);
    }
  


export const getUndeployMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof undeploy>>, TError,{organizationSlug: string;slug: string;version: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof undeploy>>, TError,{organizationSlug: string;slug: string;version: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof undeploy>>, {organizationSlug: string;slug: string;version: string}> = (props) => {
          const {organizationSlug,slug,version} = props ?? {};

          return  undeploy(organizationSlug,slug,version,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UndeployMutationResult = NonNullable<Awaited<ReturnType<typeof undeploy>>>
    
    export type UndeployMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUndeploy = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof undeploy>>, TError,{organizationSlug: string;slug: string;version: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof undeploy>>,
        TError,
        {organizationSlug: string;slug: string;version: string},
        TContext
      > => {

      const mutationOptions = getUndeployMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Deploy the specified version of the extension pack
 */
export const deploy = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    deploymentOptions: MaybeRef<DeploymentOptions>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
deploymentOptions = unref(deploymentOptions);
      
      return customAxios<ExtensionPack>(
      {url: `/api/extensionPacks/${organizationSlug}/${slug}/${version}/deploy`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: deploymentOptions
    },
      options);
    }
  


export const getDeployMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deploy>>, TError,{organizationSlug: string;slug: string;version: string;data: DeploymentOptions}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deploy>>, TError,{organizationSlug: string;slug: string;version: string;data: DeploymentOptions}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deploy>>, {organizationSlug: string;slug: string;version: string;data: DeploymentOptions}> = (props) => {
          const {organizationSlug,slug,version,data} = props ?? {};

          return  deploy(organizationSlug,slug,version,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeployMutationResult = NonNullable<Awaited<ReturnType<typeof deploy>>>
    export type DeployMutationBody = DeploymentOptions
    export type DeployMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeploy = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deploy>>, TError,{organizationSlug: string;slug: string;version: string;data: DeploymentOptions}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deploy>>,
        TError,
        {organizationSlug: string;slug: string;version: string;data: DeploymentOptions},
        TContext
      > => {

      const mutationOptions = getDeployMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Cancel all executions in the extension pack
 */
export const cancelAll = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
      
      return customAxios<void>(
      {url: `/api/extensionPacks/${organizationSlug}/${slug}/${version}/cancelAll`, method: 'PUT'
    },
      options);
    }
  


export const getCancelAllMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cancelAll>>, TError,{organizationSlug: string;slug: string;version: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof cancelAll>>, TError,{organizationSlug: string;slug: string;version: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cancelAll>>, {organizationSlug: string;slug: string;version: string}> = (props) => {
          const {organizationSlug,slug,version} = props ?? {};

          return  cancelAll(organizationSlug,slug,version,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CancelAllMutationResult = NonNullable<Awaited<ReturnType<typeof cancelAll>>>
    
    export type CancelAllMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCancelAll = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cancelAll>>, TError,{organizationSlug: string;slug: string;version: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof cancelAll>>,
        TError,
        {organizationSlug: string;slug: string;version: string},
        TContext
      > => {

      const mutationOptions = getCancelAllMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a paginated list of the resources for an organization
 */
export const listExtensionPacksForOrganization = (
    organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListExtensionPacksForOrganizationParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
params = unref(params);
      
      return customAxios<PageExtensionPack>(
      {url: `/api/extensionPacks/${organizationSlug}`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListExtensionPacksForOrganizationQueryKey = (organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListExtensionPacksForOrganizationParams>,) => {
    return ['api','extensionPacks',organizationSlug, ...(params ? [params]: [])] as const;
    }

    
export const getListExtensionPacksForOrganizationQueryOptions = <TData = Awaited<ReturnType<typeof listExtensionPacksForOrganization>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListExtensionPacksForOrganizationParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listExtensionPacksForOrganization>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListExtensionPacksForOrganizationQueryKey(organizationSlug,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listExtensionPacksForOrganization>>> = ({ signal }) => listExtensionPacksForOrganization(organizationSlug,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listExtensionPacksForOrganization>>, TError, TData> 
}

export type ListExtensionPacksForOrganizationQueryResult = NonNullable<Awaited<ReturnType<typeof listExtensionPacksForOrganization>>>
export type ListExtensionPacksForOrganizationQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useListExtensionPacksForOrganization<TData = Awaited<ReturnType<typeof listExtensionPacksForOrganization>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListExtensionPacksForOrganizationParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listExtensionPacksForOrganization>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListExtensionPacksForOrganizationQueryOptions(organizationSlug,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Create a new instance of the object in the organization using the template with given ref
 */
export const createExtensionPack = (
    organizationSlug: MaybeRef<string>,
    extensionPack: MaybeRef<ExtensionPack>,
    params: MaybeRef<CreateExtensionPackParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
extensionPack = unref(extensionPack);
params = unref(params);
      
      return customAxios<ExtensionPack>(
      {url: `/api/extensionPacks/${organizationSlug}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: extensionPack,
        params: unref(params), signal
    },
      options);
    }
  


export const getCreateExtensionPackMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createExtensionPack>>, TError,{organizationSlug: string;data: ExtensionPack;params: CreateExtensionPackParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createExtensionPack>>, TError,{organizationSlug: string;data: ExtensionPack;params: CreateExtensionPackParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createExtensionPack>>, {organizationSlug: string;data: ExtensionPack;params: CreateExtensionPackParams}> = (props) => {
          const {organizationSlug,data,params} = props ?? {};

          return  createExtensionPack(organizationSlug,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateExtensionPackMutationResult = NonNullable<Awaited<ReturnType<typeof createExtensionPack>>>
    export type CreateExtensionPackMutationBody = ExtensionPack
    export type CreateExtensionPackMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateExtensionPack = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createExtensionPack>>, TError,{organizationSlug: string;data: ExtensionPack;params: CreateExtensionPackParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof createExtensionPack>>,
        TError,
        {organizationSlug: string;data: ExtensionPack;params: CreateExtensionPackParams},
        TContext
      > => {

      const mutationOptions = getCreateExtensionPackMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a paginated list of all resources
 */
export const listExtensionPacks = (
    params?: MaybeRef<ListExtensionPacksParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageExtensionPack>(
      {url: `/api/extensionPacks`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListExtensionPacksQueryKey = (params?: MaybeRef<ListExtensionPacksParams>,) => {
    return ['api','extensionPacks', ...(params ? [params]: [])] as const;
    }

    
export const getListExtensionPacksQueryOptions = <TData = Awaited<ReturnType<typeof listExtensionPacks>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListExtensionPacksParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listExtensionPacks>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListExtensionPacksQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listExtensionPacks>>> = ({ signal }) => listExtensionPacks(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listExtensionPacks>>, TError, TData> 
}

export type ListExtensionPacksQueryResult = NonNullable<Awaited<ReturnType<typeof listExtensionPacks>>>
export type ListExtensionPacksQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useListExtensionPacks<TData = Awaited<ReturnType<typeof listExtensionPacks>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListExtensionPacksParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listExtensionPacks>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListExtensionPacksQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Cancel all executions in the extension pack
 */
export const getExtensionPackStatus = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
      
      return customAxios<ExtensionPackStatus>(
      {url: `/api/extensionPacks/${organizationSlug}/${slug}/${version}/status`, method: 'GET', signal
    },
      options);
    }
  

export const getGetExtensionPackStatusQueryKey = (organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,) => {
    return ['api','extensionPacks',organizationSlug,slug,version,'status'] as const;
    }

    
export const getGetExtensionPackStatusQueryOptions = <TData = Awaited<ReturnType<typeof getExtensionPackStatus>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getExtensionPackStatus>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetExtensionPackStatusQueryKey(organizationSlug,slug,version);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExtensionPackStatus>>> = ({ signal }) => getExtensionPackStatus(organizationSlug,slug,version, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug) && unref(slug) && unref(version))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getExtensionPackStatus>>, TError, TData> 
}

export type GetExtensionPackStatusQueryResult = NonNullable<Awaited<ReturnType<typeof getExtensionPackStatus>>>
export type GetExtensionPackStatusQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetExtensionPackStatus<TData = Awaited<ReturnType<typeof getExtensionPackStatus>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getExtensionPackStatus>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetExtensionPackStatusQueryOptions(organizationSlug,slug,version,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Get the change sequence for resource with the provided slug and version
 */
export const getModifiedForExtensionPack = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
      
      return customAxios<number>(
      {url: `/api/extensionPacks/${organizationSlug}/${slug}/${version}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetModifiedForExtensionPackQueryKey = (organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,) => {
    return ['api','extensionPacks',organizationSlug,slug,version,'sequence'] as const;
    }

    
export const getGetModifiedForExtensionPackQueryOptions = <TData = Awaited<ReturnType<typeof getModifiedForExtensionPack>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getModifiedForExtensionPack>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetModifiedForExtensionPackQueryKey(organizationSlug,slug,version);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getModifiedForExtensionPack>>> = ({ signal }) => getModifiedForExtensionPack(organizationSlug,slug,version, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug) && unref(slug) && unref(version))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getModifiedForExtensionPack>>, TError, TData> 
}

export type GetModifiedForExtensionPackQueryResult = NonNullable<Awaited<ReturnType<typeof getModifiedForExtensionPack>>>
export type GetModifiedForExtensionPackQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetModifiedForExtensionPack<TData = Awaited<ReturnType<typeof getModifiedForExtensionPack>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getModifiedForExtensionPack>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetModifiedForExtensionPackQueryOptions(organizationSlug,slug,version,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



