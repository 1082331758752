/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  CreateGuidanceParams,
  DeleteGuidanceParams,
  DeleteVersionGuidanceParams,
  ExceptionResponse,
  GuidanceSet,
  ListGuidancesForOrganizationParams,
  ListGuidancesParams,
  PageGuidanceSet,
  RelevantGuidanceQuery,
  RetainedGuidance,
  StreamingResponseBody,
  ThrowableProblem
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get the current version of the object with given slug
 */
export const getGuidance = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
      
      return customAxios<GuidanceSet>(
      {url: `/api/guidance/${organizationSlug}/${slug}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetGuidanceQueryKey = (organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,) => {
    return ['api','guidance',organizationSlug,slug] as const;
    }

    
export const getGetGuidanceQueryOptions = <TData = Awaited<ReturnType<typeof getGuidance>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGuidance>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetGuidanceQueryKey(organizationSlug,slug);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getGuidance>>> = ({ signal }) => getGuidance(organizationSlug,slug, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug) && unref(slug))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getGuidance>>, TError, TData> 
}

export type GetGuidanceQueryResult = NonNullable<Awaited<ReturnType<typeof getGuidance>>>
export type GetGuidanceQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetGuidance<TData = Awaited<ReturnType<typeof getGuidance>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGuidance>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetGuidanceQueryOptions(organizationSlug,slug,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the current version object with given slug in the organization
 */
export const updateGuidance = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    guidanceSet: MaybeRef<GuidanceSet>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
guidanceSet = unref(guidanceSet);
      
      return customAxios<GuidanceSet>(
      {url: `/api/guidance/${organizationSlug}/${slug}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: guidanceSet
    },
      options);
    }
  


export const getUpdateGuidanceMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateGuidance>>, TError,{organizationSlug: string;slug: string;data: GuidanceSet}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateGuidance>>, TError,{organizationSlug: string;slug: string;data: GuidanceSet}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateGuidance>>, {organizationSlug: string;slug: string;data: GuidanceSet}> = (props) => {
          const {organizationSlug,slug,data} = props ?? {};

          return  updateGuidance(organizationSlug,slug,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateGuidanceMutationResult = NonNullable<Awaited<ReturnType<typeof updateGuidance>>>
    export type UpdateGuidanceMutationBody = GuidanceSet
    export type UpdateGuidanceMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateGuidance = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateGuidance>>, TError,{organizationSlug: string;slug: string;data: GuidanceSet}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateGuidance>>,
        TError,
        {organizationSlug: string;slug: string;data: GuidanceSet},
        TContext
      > => {

      const mutationOptions = getUpdateGuidanceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the current version of the given object
 */
export const deleteGuidance = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    params?: MaybeRef<DeleteGuidanceParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
params = unref(params);
      
      return customAxios<StreamingResponseBody>(
      {url: `/api/guidance/${organizationSlug}/${slug}`, method: 'DELETE',
        params: unref(params)
    },
      options);
    }
  


export const getDeleteGuidanceMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteGuidance>>, TError,{organizationSlug: string;slug: string;params?: DeleteGuidanceParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteGuidance>>, TError,{organizationSlug: string;slug: string;params?: DeleteGuidanceParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteGuidance>>, {organizationSlug: string;slug: string;params?: DeleteGuidanceParams}> = (props) => {
          const {organizationSlug,slug,params} = props ?? {};

          return  deleteGuidance(organizationSlug,slug,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteGuidanceMutationResult = NonNullable<Awaited<ReturnType<typeof deleteGuidance>>>
    
    export type DeleteGuidanceMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteGuidance = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteGuidance>>, TError,{organizationSlug: string;slug: string;params?: DeleteGuidanceParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteGuidance>>,
        TError,
        {organizationSlug: string;slug: string;params?: DeleteGuidanceParams},
        TContext
      > => {

      const mutationOptions = getDeleteGuidanceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the specific version of the object with given slug
 */
export const getVersionGuidance = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
      
      return customAxios<GuidanceSet>(
      {url: `/api/guidance/${organizationSlug}/${slug}/${version}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetVersionGuidanceQueryKey = (organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,) => {
    return ['api','guidance',organizationSlug,slug,version] as const;
    }

    
export const getGetVersionGuidanceQueryOptions = <TData = Awaited<ReturnType<typeof getVersionGuidance>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getVersionGuidance>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetVersionGuidanceQueryKey(organizationSlug,slug,version);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getVersionGuidance>>> = ({ signal }) => getVersionGuidance(organizationSlug,slug,version, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug) && unref(slug) && unref(version))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getVersionGuidance>>, TError, TData> 
}

export type GetVersionGuidanceQueryResult = NonNullable<Awaited<ReturnType<typeof getVersionGuidance>>>
export type GetVersionGuidanceQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetVersionGuidance<TData = Awaited<ReturnType<typeof getVersionGuidance>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getVersionGuidance>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetVersionGuidanceQueryOptions(organizationSlug,slug,version,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the object with given slug and version in the organization
 */
export const updateVersionGuidance = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    guidanceSet: MaybeRef<GuidanceSet>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
guidanceSet = unref(guidanceSet);
      
      return customAxios<GuidanceSet>(
      {url: `/api/guidance/${organizationSlug}/${slug}/${version}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: guidanceSet
    },
      options);
    }
  


export const getUpdateVersionGuidanceMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateVersionGuidance>>, TError,{organizationSlug: string;slug: string;version: string;data: GuidanceSet}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateVersionGuidance>>, TError,{organizationSlug: string;slug: string;version: string;data: GuidanceSet}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateVersionGuidance>>, {organizationSlug: string;slug: string;version: string;data: GuidanceSet}> = (props) => {
          const {organizationSlug,slug,version,data} = props ?? {};

          return  updateVersionGuidance(organizationSlug,slug,version,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateVersionGuidanceMutationResult = NonNullable<Awaited<ReturnType<typeof updateVersionGuidance>>>
    export type UpdateVersionGuidanceMutationBody = GuidanceSet
    export type UpdateVersionGuidanceMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateVersionGuidance = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateVersionGuidance>>, TError,{organizationSlug: string;slug: string;version: string;data: GuidanceSet}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateVersionGuidance>>,
        TError,
        {organizationSlug: string;slug: string;version: string;data: GuidanceSet},
        TContext
      > => {

      const mutationOptions = getUpdateVersionGuidanceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the specified version of the given object
 */
export const deleteVersionGuidance = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<DeleteVersionGuidanceParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
params = unref(params);
      
      return customAxios<boolean>(
      {url: `/api/guidance/${organizationSlug}/${slug}/${version}`, method: 'DELETE',
        params: unref(params)
    },
      options);
    }
  


export const getDeleteVersionGuidanceMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteVersionGuidance>>, TError,{organizationSlug: string;slug: string;version: string;params?: DeleteVersionGuidanceParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteVersionGuidance>>, TError,{organizationSlug: string;slug: string;version: string;params?: DeleteVersionGuidanceParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteVersionGuidance>>, {organizationSlug: string;slug: string;version: string;params?: DeleteVersionGuidanceParams}> = (props) => {
          const {organizationSlug,slug,version,params} = props ?? {};

          return  deleteVersionGuidance(organizationSlug,slug,version,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteVersionGuidanceMutationResult = NonNullable<Awaited<ReturnType<typeof deleteVersionGuidance>>>
    
    export type DeleteVersionGuidanceMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteVersionGuidance = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteVersionGuidance>>, TError,{organizationSlug: string;slug: string;version: string;params?: DeleteVersionGuidanceParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteVersionGuidance>>,
        TError,
        {organizationSlug: string;slug: string;version: string;params?: DeleteVersionGuidanceParams},
        TContext
      > => {

      const mutationOptions = getDeleteVersionGuidanceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a paginated list of the resources for an organization
 */
export const listGuidancesForOrganization = (
    organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListGuidancesForOrganizationParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
params = unref(params);
      
      return customAxios<PageGuidanceSet>(
      {url: `/api/guidance/${organizationSlug}`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListGuidancesForOrganizationQueryKey = (organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListGuidancesForOrganizationParams>,) => {
    return ['api','guidance',organizationSlug, ...(params ? [params]: [])] as const;
    }

    
export const getListGuidancesForOrganizationQueryOptions = <TData = Awaited<ReturnType<typeof listGuidancesForOrganization>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListGuidancesForOrganizationParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listGuidancesForOrganization>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListGuidancesForOrganizationQueryKey(organizationSlug,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listGuidancesForOrganization>>> = ({ signal }) => listGuidancesForOrganization(organizationSlug,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listGuidancesForOrganization>>, TError, TData> 
}

export type ListGuidancesForOrganizationQueryResult = NonNullable<Awaited<ReturnType<typeof listGuidancesForOrganization>>>
export type ListGuidancesForOrganizationQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useListGuidancesForOrganization<TData = Awaited<ReturnType<typeof listGuidancesForOrganization>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListGuidancesForOrganizationParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listGuidancesForOrganization>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListGuidancesForOrganizationQueryOptions(organizationSlug,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Create a new instance of the object in the organization using the template with given ref
 */
export const createGuidance = (
    organizationSlug: MaybeRef<string>,
    guidanceSet: MaybeRef<GuidanceSet>,
    params: MaybeRef<CreateGuidanceParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
guidanceSet = unref(guidanceSet);
params = unref(params);
      
      return customAxios<GuidanceSet>(
      {url: `/api/guidance/${organizationSlug}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: guidanceSet,
        params: unref(params), signal
    },
      options);
    }
  


export const getCreateGuidanceMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createGuidance>>, TError,{organizationSlug: string;data: GuidanceSet;params: CreateGuidanceParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createGuidance>>, TError,{organizationSlug: string;data: GuidanceSet;params: CreateGuidanceParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createGuidance>>, {organizationSlug: string;data: GuidanceSet;params: CreateGuidanceParams}> = (props) => {
          const {organizationSlug,data,params} = props ?? {};

          return  createGuidance(organizationSlug,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateGuidanceMutationResult = NonNullable<Awaited<ReturnType<typeof createGuidance>>>
    export type CreateGuidanceMutationBody = GuidanceSet
    export type CreateGuidanceMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateGuidance = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createGuidance>>, TError,{organizationSlug: string;data: GuidanceSet;params: CreateGuidanceParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof createGuidance>>,
        TError,
        {organizationSlug: string;data: GuidanceSet;params: CreateGuidanceParams},
        TContext
      > => {

      const mutationOptions = getCreateGuidanceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const findRelevantGuidance = (
    orgSlug: MaybeRef<string>,
    relevantGuidanceQuery: MaybeRef<RelevantGuidanceQuery>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
relevantGuidanceQuery = unref(relevantGuidanceQuery);
      
      return customAxios<RetainedGuidance[]>(
      {url: `/api/guidance/${orgSlug}/findRetainedGuidance`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: relevantGuidanceQuery, signal
    },
      options);
    }
  


export const getFindRelevantGuidanceMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof findRelevantGuidance>>, TError,{orgSlug: string;data: RelevantGuidanceQuery}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof findRelevantGuidance>>, TError,{orgSlug: string;data: RelevantGuidanceQuery}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof findRelevantGuidance>>, {orgSlug: string;data: RelevantGuidanceQuery}> = (props) => {
          const {orgSlug,data} = props ?? {};

          return  findRelevantGuidance(orgSlug,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type FindRelevantGuidanceMutationResult = NonNullable<Awaited<ReturnType<typeof findRelevantGuidance>>>
    export type FindRelevantGuidanceMutationBody = RelevantGuidanceQuery
    export type FindRelevantGuidanceMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useFindRelevantGuidance = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof findRelevantGuidance>>, TError,{orgSlug: string;data: RelevantGuidanceQuery}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof findRelevantGuidance>>,
        TError,
        {orgSlug: string;data: RelevantGuidanceQuery},
        TContext
      > => {

      const mutationOptions = getFindRelevantGuidanceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a paginated list of all resources
 */
export const listGuidances = (
    params?: MaybeRef<ListGuidancesParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageGuidanceSet>(
      {url: `/api/guidance`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListGuidancesQueryKey = (params?: MaybeRef<ListGuidancesParams>,) => {
    return ['api','guidance', ...(params ? [params]: [])] as const;
    }

    
export const getListGuidancesQueryOptions = <TData = Awaited<ReturnType<typeof listGuidances>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListGuidancesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listGuidances>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListGuidancesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listGuidances>>> = ({ signal }) => listGuidances(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listGuidances>>, TError, TData> 
}

export type ListGuidancesQueryResult = NonNullable<Awaited<ReturnType<typeof listGuidances>>>
export type ListGuidancesQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useListGuidances<TData = Awaited<ReturnType<typeof listGuidances>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListGuidancesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listGuidances>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListGuidancesQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Get the change sequence for resource with the provided slug and version
 */
export const getModifiedForGuidance = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
      
      return customAxios<number>(
      {url: `/api/guidance/${organizationSlug}/${slug}/${version}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetModifiedForGuidanceQueryKey = (organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,) => {
    return ['api','guidance',organizationSlug,slug,version,'sequence'] as const;
    }

    
export const getGetModifiedForGuidanceQueryOptions = <TData = Awaited<ReturnType<typeof getModifiedForGuidance>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getModifiedForGuidance>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetModifiedForGuidanceQueryKey(organizationSlug,slug,version);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getModifiedForGuidance>>> = ({ signal }) => getModifiedForGuidance(organizationSlug,slug,version, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug) && unref(slug) && unref(version))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getModifiedForGuidance>>, TError, TData> 
}

export type GetModifiedForGuidanceQueryResult = NonNullable<Awaited<ReturnType<typeof getModifiedForGuidance>>>
export type GetModifiedForGuidanceQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetModifiedForGuidance<TData = Awaited<ReturnType<typeof getModifiedForGuidance>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getModifiedForGuidance>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetModifiedForGuidanceQueryOptions(organizationSlug,slug,version,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



