<script setup lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import type { UploadOnBeforeUploadEvent } from "@progress/kendo-vue-upload";
import { Upload } from "@progress/kendo-vue-upload";
import type { UploadFileInfo } from "@progress/kendo-vue-upload/dist/npm/interfaces/UploadFileInfo";
import type { PropType } from "vue";
import { storeToRefs } from "pinia";
import { authHeader } from "~/api/custom-axios";
import type { Store } from "~/model";
import appStore from "~/store";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  documentStore: {
    type: Object as PropType<Store>,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue", "change"]);

function close() {
  emit("update:modelValue", false);
  emit("change");
}

const properties = ref({});

const openExternalData = ref(false);

const externalData = ref("");

const fileRecords = ref<UploadFileInfo[]>([]);

const metadata = ref<{ name: string; value: string }[]>([]);

function deleteMetadataEntry(metadataEntry: { name: string; value: string }) {
  const index = metadata.value.indexOf(metadataEntry);
  if (index > -1) {
    metadata.value.splice(index, 1);
  }
}

const authorizationHeader = ref<string | undefined>(undefined);
authHeader().then((h) => {
  authorizationHeader.value = h.Authorization;
});

function onBeforeUpload(event: UploadOnBeforeUploadEvent) {
  event.headers.Authorization = authorizationHeader.value;
  metadata.value.forEach((item) => {
    event.additionalData[item.name] = item.value;
  });

  // We want to merge the object from properties into the additionalData
  event.additionalData = { ...event.additionalData, ...properties.value };

  event.additionalData.path = event.files[0].name;

  if (externalData.value.length > 0) {
    event.additionalData.externalData = externalData.value;
  }
}
const {
  isZeroCredits,
} = storeToRefs(appStore.organizationStore);

function toggleExternalData() {
  openExternalData.value = !openExternalData.value;

  if (!openExternalData.value) {
    externalData.value = "";
  }
}
</script>

<template>
  <TransitionRoot :show="props.modelValue" as="template" appear>
    <Dialog as="div" class="relative z-10" @close="close">
      <TransitionChild
        as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 p-4 sm:p-6 md:p-20">
        <TransitionChild
          as="template" enter="ease-out duration-300" enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100" leave="ease-in duration-200"
          leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95"
        >
          <DialogPanel
            class="mx-auto max-w-xl divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
          >
            <div v-if="!isZeroCredits" class="p-3">
              <div class="mb-3">

                <div class="flex justify-between">
                  <p class="mt-3 text-sm">
                    Need additional metadata? Click <a
                      class="text-blue-500"
                      @click="metadata.push({ name: '', value: '' })"
                    >here</a> to add or {{ externalData.length > 0 ? 'unset' : 'set' }} external data <a class="text-blue-500" @click="toggleExternalData">here</a>
                  </p>
                  <button type="button" class="text-gray-400 hover:text-gray-500" @click="close">
                    <span class="sr-only">Close panel</span>
                    <MaterialDesignIcon name="close" class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div v-if="documentStore.metadata?.documentProperties">
                  <ConfigurationOption v-for="option in documentStore.metadata.documentProperties" :key="option.name" :item="option" v-model="properties" />
                </div>
                <div v-for="(metadataEntry, idx) in metadata" :key="idx" class="my-1">
                  <Input v-model="metadataEntry.name" style="width: 40%" class="mr-1" placeholder="Name" /><Input
                    v-model="metadataEntry.value" style="width: 45%" placeholder="Value"
                  />
                  <Button fill-mode="flat" class="ml-1" @click="deleteMetadataEntry(metadataEntry)">
                    <MaterialDesignIcon name="delete" size="18" class="text-red-700" />
                  </Button>
                </div>
              </div>
              <div v-if="openExternalData">
                <KodexaCodeEditor v-model="externalData" language="json" width="100%" style="height: 300px" />
              </div>
              <Upload
                :batch="false"
                :default-files="fileRecords"
                :multiple="true"
                :with-credentials="false"
                :save-url="`/api/stores/${documentStore.orgSlug}/${documentStore.slug}/${documentStore.version}/fs`"
                save-field="file"
                @beforeupload="onBeforeUpload"
              />
            </div>
            <div v-else class="p-3">
              <div class="mb-3">
                <div class="flex justify-between">
                  <KodexaArticle class="school-icon ml-2 pt-0.5" article-id="8729166" text="Learn more about credits" size="21" :slide="true" />
                  <button type="button" class="text-gray-400 hover:text-gray-500" @click="close">
                    <span class="sr-only">Close panel</span>
                    <MaterialDesignIcon name="close" class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div class="flex justify-center" style="border-color: rgba(0, 0, 0, 0.08); color: #424242; background-color: #fafafa;">
                  <div class="mr-4">
                    <MaterialDesignIcon name="warning" size="40" aria-hidden="true" />
                  </div>
                  <p class="mt-3 text-sm text-center">
                    Your organization does not have any AI credits. <br>
                    <span class="text-xs">Uploading documents is disabled.</span>
                  </p>
                </div>
              </div>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>
</style>
