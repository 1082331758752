/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */

export type NotificationNotificationType = typeof NotificationNotificationType[keyof typeof NotificationNotificationType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationNotificationType = {
  EMAIL_NOTIFICATION: 'EMAIL_NOTIFICATION',
} as const;
