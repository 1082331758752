<script setup lang="ts">
import { onMounted, ref } from "vue";
import {
  GridColumnMenuSort,
} from "@progress/kendo-vue-grid";

interface Column {
  hidden: boolean;
  title: string;
  [key: string]: any;
}

interface Props {
  column: object;
  sortable: boolean | object;
  sort: any[];
  columns: Column[];
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "contentfocus", event: Event): void;
  (e: "columnssubmit", columns: Column[]): void;
  (e: "closemenu"): void;
  (e: "expandchange"): void;
  (e: "sortchange", newDescriptor: any, event: Event): void;
  (e: "toggleColumnLock"): void;
}>();

const currentColumns = ref<Column[]>([]);

onMounted(() => {
  currentColumns.value = props.columns;
});

function closeMenu() {
  emit("closemenu");
}

function sortChange(newDescriptor: any, e: Event) {
  emit("sortchange", newDescriptor, e);
}

function toggleColumnLock() {
  emit("toggleColumnLock");
}
</script>

<template>
  <div>
    <GridColumnMenuSort
      :column="column"
      :sortable="sortable"
      :sort="sort"
      @closemenu="closeMenu"
      @sortchange="sortChange"
    />
    <KodexaCheckbox
      name="columnLock"
      class="mx-2"
      :label="column.locked ? 'Unlock Column' : 'Lock Column'"
      :value="column.locked"
      @click="toggleColumnLock"
    />
  </div>
</template>
