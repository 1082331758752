<script setup lang="ts">
import { Button, Toolbar, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import { storeToRefs } from "pinia";
import { filterIcon } from "@progress/kendo-svg-icons";
import type { PropType } from "vue";
import { computed, ref } from "vue";
import { createGridHelper } from "~/store/useGridHelper";
import type { DashboardWidget, TaskTemplate } from "~/model";
import appStore from "~/store";
import { useListTaskTemplates } from "~/api/task-templates/task-templates";

// Props
const props = defineProps({
  widget: {
    type: Object as PropType<DashboardWidget>,
  },
});

// Store
const { project } = storeToRefs(appStore.projectStore);

// Grid setup
const gridHelper = createGridHelper("task-template-list", {
  page: 1,
  pageSize: 10,
  filter: "",
  query: "",
  sort: "createdOn:desc",
});

const { pageSettings, sort, showFilter, filter, gridQuery } = storeToRefs(gridHelper);

// Data fetching
const { data, isLoading, refetch } = useListTaskTemplates(gridQuery.value);

// Watchers
watch(data, (newValue) => {
  if (newValue?.totalElements) {
    gridHelper.setTotal(newValue.totalElements);
    gridHelper.saveState();
  }
});

watch(project, (newValue) => {
  if (newValue) {
    gridHelper.setBaseFilter(`project.id: '${project.value.id}'`);
  }
}, { immediate: true });

// Grid configuration
const columns = [
  {
    title: "Template Name",
    field: "title",
    cell: "name",
  },
  {
    title: "Description",
    field: "description",
  }
];

// Template state
const showCreateTemplate = ref(false);
const selectedTemplate = ref<TaskTemplate>();
const showSelectedTemplate = ref(false);

// Methods
const openNewTemplate = () => {
  showCreateTemplate.value = true;
};

const editTemplate = (template: TaskTemplate) => {
  selectedTemplate.value = template;
  showSelectedTemplate.value = true;
};

const handleTemplateDeleted = async (templateId: string) => {
  await refetch();
};

const gridStyle = computed(() => {
  if (props.widget?.properties?.style) {
    return props.widget.properties.style;
  }

  const rowSpan = props.widget?.properties?.defaultPosition?.rowSpan || 6;
  return {
    height: `${(rowSpan * 85) - 130}px`,
  };
});
</script>

<template>
  <div class="">
    <div class="mx-4">
      <!-- Toolbar -->
      <Toolbar class="border-0 bg-white">
        <Button
          :svg-icon="filterIcon"
          title="Filter"
          :togglable="true"
          @click="gridHelper.toggleFilter()"
        />
        <KodexaArticle
          class="mt-2 ml-2"
          article-id="9787665"
          text="Learn more about task templates"
        />
        <ToolbarSpacer />
        <KodexaButton
          id="createNewTemplate"
          icon="plus"
          @click="openNewTemplate"
        >
          New Task Template
        </KodexaButton>
      </Toolbar>

      <!-- Grid -->
      <Grid
        :style="gridStyle"
        :data-items="data?.content || []"
        :pageable="pageSettings"
        :loader="isLoading"
        :sort="sort"
        :skip="pageSettings.skip"
        :total="pageSettings.total"
        :page-size="pageSettings.take"
        :columns="columns"
        :filterable="showFilter"
        :filter="filter"
        :resizable="true"
        :sortable="true"
        @pagechange="gridHelper.pageChangeHandler($event)"
        @filterchange="gridHelper.filterChange($event)"
        @sortchange="gridHelper.sortChange($event)"
      >
        <GridNoRecords>
          <div class="p-4 text-center text-gray-500">
            No task templates found
          </div>
        </GridNoRecords>
        <template #name="{ props }">
          <td>
            <div class="flex items-center">
              <div
                class="flex-0 cursor-pointer font-bold text-gray-900"
                @click="editTemplate(props.dataItem)"
              >
                {{ props.dataItem?.title }}
              </div>
            </div>
          </td>
        </template>
      </Grid>

      <!-- Modals -->
      <ProjectNewTaskTemplate
        v-model="showCreateTemplate"
        @refetch-templates="refetch"
      />
      <ProjectEditTaskTemplate
        v-if="showSelectedTemplate"
        v-model="showSelectedTemplate"
        :template="selectedTemplate"
        @template-deleted="handleTemplateDeleted"
        @refetch-templates="refetch"
      />
    </div>
  </div>
</template>
