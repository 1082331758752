<script setup>
import { BaseEdge, EdgeLabelRenderer, getBezierPath } from "@vue-flow/core";
import { computed, ref } from "vue";

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  sourceX: {
    type: Number,
    required: true,
  },
  sourceY: {
    type: Number,
    required: true,
  },
  targetX: {
    type: Number,
    required: true,
  },
  targetY: {
    type: Number,
    required: true,
  },
  sourcePosition: {
    type: String,
    required: true,
  },
  targetPosition: {
    type: String,
    required: true,
  },
  markerEnd: {
    type: String,
    required: false,
  },
  style: {
    type: Object,
    required: false,
  },
  label: {
    type: Object,
    required: false,
  },
});

const path = computed(() => getBezierPath(props));

const labelText = computed(() => {
  return props.label?.subscription ? "Subscription" : "All Events";
});

const subscription = computed(() => {
  return props.label?.subscription;
});

const showTooltip = ref(false);
</script>

<script>
export default {
  inheritAttrs: false,
};
</script>

<template>
  <BaseEdge :id="id" :style="style" :path="path[0]" :marker-end="markerEnd" />

  <EdgeLabelRenderer>
    <div
      :style="{
        pointerEvents: 'all',
        position: 'absolute',
        transform: `translate(-50%, -50%) translate(${path[1]}px,${path[2]}px)`,
      }"
      class="nodrag nopan relative"
    >
      <div
        class="px-2 py-1 text-sm font-medium bg-white border border-gray-300 rounded shadow-md hover:shadow-lg transition-shadow duration-300 cursor-pointer"
        @mouseenter="showTooltip = true"
        @mouseleave="showTooltip = false"
      >
        {{ labelText }}
      </div>

      <div
        v-if="subscription && showTooltip"
        class="absolute -z-50 px-3 py-2 text-sm text-white bg-gray-800 rounded-lg shadow-lg transition-opacity duration-300"
        :class="{ 'opacity-100': showTooltip, 'opacity-0': !showTooltip }"
        style="top: -40px; left: 50%; transform: translateX(-50%); min-width: 300px; z-index: 1000 !important;"
      >
        {{ subscription }}
        <div class="absolute w-3 h-3 bg-gray-800 transform rotate-45 -bottom-1 left-1/2 -translate-x-1/2" />
      </div>
    </div>
  </EdgeLabelRenderer>
</template>

<style scoped>
.nodrag {
  user-select: none;
}

.nopan {
  pointer-events: all;
}
</style>
