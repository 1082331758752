<!--
  - Copyright (C) 2024 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import { storeToRefs } from "pinia";
import type { PropType } from "vue";
import { useConfigurationBase } from "~/components/options/configuration-option-utils";
import appStore from "~/store";
import WorkspaceOption from "~/components/options/types/workspace-option.vue";
import type { Assistant, ContentObject, Option } from "~/model";
import CloudEmbeddingOption from "~/components/options/types/cloud-embedding-option.vue";

const props = defineProps(
  {
    item: {
      type: Object as PropType<Option>,
      required: true,
    },
    modelValue: {
      required: false,
      type: Object as PropType<any>,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errors: Object,
    contentObject: Object as PropType<ContentObject | undefined>,
    assistant: Object as PropType<Assistant | undefined>,
  },
);

const emit = defineEmits(["update:modelValue"]);

const {
  documentStores,
  dataStores,
  modelStores,
  contentTaxonomies,
  guidance,
} = storeToRefs(appStore.projectStore);

const {
  isShown,
  type,
  localValue,
} = useConfigurationBase(props, emit, true);
</script>

<template>
  <div v-if="isShown">
    <div v-if="type === 'python' || type === 'javascript' || type === 'script' || type === 'code'">
      <CodeOption v-model="localValue" :item="item" language="python" />
    </div>
    <div v-if="type === 'article'">
      <ArticleOption :item="item" />
    </div>
    <div v-if="type === 'alert'">
      <AlertOption :item="item" />
    </div>

    <div v-if="type === 'attributeStatus'">
      <AttributeStatusOption
        v-model="localValue"
        :item="item"
      />
    </div>

    <div v-if="type === 'documentStatus'">
      <DocumentStatusOption
        v-model="localValue"
        :item="item"
      />
    </div>

    <div v-if="type === 'cloudModel'">
      <CloudModelOption
        v-model="localValue"
        :item="item"
      />
    </div>
    <div v-if="type === 'cloudEmbedding'">
      <CloudEmbeddingOption
        v-model="localValue"
        :item="item"
      />
    </div>
    <div v-if="type === 'guidanceSet'">
      <GuidanceSetOption
        v-model="localValue"
        :item="item"
      />
    </div>
    <div v-if="type === 'label'">
      <LabelOption
        v-model="localValue"
        :item="item"
      />
    </div>

    <div v-if="type === 'simpleExpression'">
      <CodeOption
        v-model="localValue" :item="item"
        height="100px"
      />
    </div>

    <div v-if="type === 'document'" class="type-holder">
      <div v-if="type === 'document'">
        <DocumentLookupOption
          v-model="localValue" :content-object="contentObject" :item="item"
          :stores="documentStores"
        />
      </div>
    </div>

    <div v-if="type === 'pipelineModelOptions'">
      <PipelineModelOptions v-model="localValue" :item="item" :assistant="assistant" />
    </div>

    <!--    <div v-if="type === 'pipeline'"> -->
    <!--      <PipelineOption v-model="localValue" :item="item" /> -->
    <!--    </div> -->

    <div v-if="type === 'tableStore'" class="type-holder">
      <ComponentLookupOption v-model="localValue" :item="item" :components="dataStores" />
    </div>
    <div v-if="type === 'documentStore'" class="type-holder">
      <ComponentLookupOption v-model="localValue" :item="item" :components="documentStores" />
    </div>
    <div v-if="type === 'modelStore'" class="type-holder">
      <ComponentLookupOption v-model="localValue" :item="item" :components="modelStores" />
    </div>
    <div v-if="type === 'taxonomy'">
      <ComponentLookupOption
        v-model="localValue" :content-object="contentObject" :item="item"
        :components="contentTaxonomies"
      />
    </div>
    <div v-if="type === 'guidance'">
      <ComponentLookupOption
        v-model="localValue" :content-object="contentObject" :item="item"
        :components="Array.from(guidance.values())"
      />
    </div>
    <div v-if="type === 'taxon' || type === 'taxon_label'">
      <TaxonOption
        v-model="localValue" :content-object="contentObject" :item="item"
      />
    </div>
    <div v-if="type === 'groupTaxon' || type === 'taxon_label'">
      <TaxonOption
        v-model="localValue" :content-object="contentObject" :item="item" :only-groups="true"
      />
    </div>
    <div v-if="type === 'taxon_label_with_properties'">
      <!--      <TaxonWithPropertiesOption -->
      <!--        v-model="localValue" :content-object="contentObject" :item="item" -->
      <!--        :taxonomies="contentTaxonomies" :additional-taxon-options="additionalTaxonOptions" -->
      <!--      /> -->
    </div>
    <div
      v-if="(type === 'regex' || type === 'string') && (!item.possibleValues || item.possibleValues.length === 0)"
      class="type-holder"
    >
      <TextOption v-model="localValue" :item="item" :content-object="contentObject" :type="type" />
    </div>

    <div
      v-if="(type === 'regex' || type === 'string') && item.possibleValues && item.possibleValues.length > 0"
      class="type-holder"
    >
      <SelectOption v-model="localValue" :values="item.possibleValues" :item="item" />
    </div>

    <div v-if="type === 'password'">
      <TextOption
        v-model="localValue" :item="item" :content-object="contentObject" :type="type"
        :password="true"
      />
    </div>

    <div v-if="type === 'number'">
      <NumberOption v-model="localValue" :item="item" :content-object="contentObject" :type="type" />
    </div>

    <div v-if="type === 'boolean'" class="type-holder">
      <BooleanOption v-model="localValue" :item="item" :content-object="contentObject" :type="type" />
    </div>

    <div v-if="type === 'workspace'" class="type-holder">
      <WorkspaceOption v-model="localValue" :item="item" :content-object="contentObject" :type="type" />
    </div>

    <div v-if="type === 'dataForm'" class="type-holder">
      <DataFormOption v-model="localValue" :item="item" :content-object="contentObject" :type="type" />
    </div>

    <div v-if="type === 'chartOption'" class="type-holder">
      <ChartOption
        v-model="localValue" :item="item" :type="type"
      />
    </div>
  </div>
</template>

<style scoped>
.type-holder {
  position: relative;
}
</style>
