<!--
  - Copyright (C) 2024 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import { Toolbar } from "@progress/kendo-vue-buttons";
import type { PropType } from "vue";
import { storeToRefs } from "pinia";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import { process } from "@progress/kendo-data-query";
import type { DataAttribute, DataObject, Store, Taxon } from "~/model";
import type { TagMetadata } from "~/store/useProject";
import appStore from "~/store";
import type { AttributeEditorOptions } from "~/components/dataObject/attribute-editor-options";
import { getAggregationFromTaxons, getAttributeValueByTaxonType } from "~/components/util/attribute-utils";
import { getColumnsFromTaxon } from "~/utils/general";
import { useListDataObjects } from "~/api/data-objects/data-objects";
import { useGridScroll } from "~/utils/useGridScroll";
import { log } from "~/utils/logger";

const props = defineProps({
  groupTaxonMetadata: {
    type: Object as PropType<TagMetadata>,
    required: true,
  },
  dataStore: {
    type: Object as PropType<Store>,
    required: true,
  },
  viewId: {
    type: String as PropType<string>,
    required: false,
    default: undefined,
  },
  groupTaxons: {
    type: Array as PropType<TagMetadata[]>,
    required: false,
    default: undefined,
  },
  parentDataObject: {
    type: Object as PropType<DataObject>,
    required: false,
    default: undefined,
  },
  showHeader: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const gridHelper = createGridHelper(`data-object-grid-${props.groupTaxonMetadata.path}`, {
  page: 1,
  pageSize: 20,
  filter: "",
  filters: [],
  sort: "",
  query: "",
}, `path: '${props.groupTaxonMetadata.path}' and store.id: '${props.dataStore._id}' ${props.parentDataObject ? `and parent.id: '${props.parentDataObject.id}'` : ""}`);

const {
  pageSettings,
  sort,
  showFilter,
  filter,
  gridQuery,
} = storeToRefs(gridHelper);

const dataObjectEndpoint = useListDataObjects(gridQuery.value);

const {
  isLoading,
  isError,
  data,
  error,
  refetch,
} = dataObjectEndpoint;

// Setup our grid helpers

watch(data, (newValue) => {
  if (newValue) {
    gridHelper.setTotal(newValue.totalElements as number);
  }
});

const group = ref([]);

watch(group, (newValue) => {
  if (newValue) {
    gridHelper.setGroups(newValue.map(group => `${group.field}:${group.dir || "asc"}`));
  }
});

const columns = computed(() => {
  return getColumnsFromTaxon(props.groupTaxonMetadata.taxon);
});

const { tagMetadataMap } = storeToRefs(appStore.projectStore);

const expandedItems = ref([] as string[]);

const dataItems = computed(() => {
  // We need to identify the parent data objects in include we need to include

  const dataItems = [];

  if (!data.value?.content) {
    return dataItems;
  }

  for (const dataObject of data.value?.content as DataObject[]) {
    if (dataObject.path !== props.groupTaxonMetadata?.path) {
      continue;
    }

    const dataItem = {
      attributes: new Map<string, DataAttribute>(),
      dataObjectInstance: dataObject,
      tagMetadata: new Map<string, TagMetadata>(),
      expanded: expandedItems.value.includes(dataObject?.uuid as string),
    };

    if (dataObject?.attributes) {
      for (const attribute of dataObject?.attributes) {
        // We have a hack here to get the grouping to work
        dataItem[attribute.path] = getAttributeValueByTaxonType(attribute, tagMetadataMap.value);

        dataItem.attributes.set(attribute.tag, attribute);
        dataItem.tagMetadata.set(attribute.tag, tagMetadataMap.value.get(`${props.groupTaxonMetadata.taxon.path}/${attribute.tag}`));
      }
    }
    dataItems.push(dataItem);
  }

  return dataItems;
});

const editorOptions = ref({ showPreview: false } as AttributeEditorOptions);

const gridItems = computed(() => {
  return process(dataItems.value, {
    group: group.value,
  });
});

const height = computed(() => {
  if (!props.parentDataObject) {
    return `calc(100vh - 20rem)`;
  } else {
    // We want the height based on the number of rows
    if (data.value.content?.length > 0) {
      return `${(data.value.content?.length * 50) + 100}px`;
    }
  }

  return `calc(100vh - 20rem)`;
});

const pageable = {
  buttonCount: 5,
  info: true,
  type: "numeric",
  pageSizes: [10, 20, 30, 50, 100],
  previousNext: true,
};
const aggregates = ref([]);

function dataStateChange(event) {
  // Need to determine if we have any aggregates
  // this is based on the taxons that we can aggregate on
  aggregates.value = getAggregationFromTaxons(props.groupTaxonMetadata.taxon?.children || []);

  if (event.data.group) {
    event.data.group.map(group => (group.aggregates = aggregates.value));
  }
  group.value = event.data.group;
}

function clickHandler(e) {
  const id = e.dataObjectInstance.uuid as string;
  if (expandedItems.value.includes(id)) {
    expandedItems.value.splice(id, 1);
  } else {
    expandedItems.value.push(id);
  }
}

function getChildItems(taxon: Taxon) {
  const items = [];
  if (!taxon.children) {
    return items;
  }

  taxon.children.forEach((child) => {
    if (!child.group || child.taxonType === "SECTION") {
      return;
    }
    items.push({
      ref: child.id,
      name: child.label,
      taxon: child,
    });
  });

  return items;
}

const selectedItems = ref({});

function selectedChild(taxon: Taxon, dataObjectId: string) {
  if (dataObjectId in selectedItems.value) {
    return selectedItems.value[dataObjectId];
  } else {
    selectedItems.value[dataObjectId] = getChildItems(taxon)[0];
    return selectedItems.value[dataObjectId];
  }
}

function selectChild(dataObjectId: string, selected: any) {
  selectedItems.value[dataObjectId] = selected;
}

const tableElement = ref(null);
const gridScroll = useGridScroll(tableElement); // Use the composable
onUnmounted(() => {
  gridScroll.unmount();
});
</script>

<template>
  <div>
    <Toolbar class="" :class="[{ '-ml-2 mb-1 border-0': showHeader, 'bg-white': showHeader, 'bg-grey-700': !showHeader }]">
      <KodexaButton
        :type="showFilter ? 'primary' : 'secondary'" icon="filter" size="small" title="Filter"
        @click="gridHelper.toggleFilter()"
      >
        Filter
      </KodexaButton>
      <KodexaButton
        :type="editorOptions.showPreview ? 'primary' : 'secondary'"
        icon="image" title="Preview" size="small"
        @click="editorOptions.showPreview = !editorOptions.showPreview"
      >
        Preview Popup
      </KodexaButton>
      <KodexaButton
        icon="refresh"
        title="Refresh"
        type="secondary"
        size="small"
        @click="refetch()"
      >
        Refresh
      </KodexaButton>
    </Toolbar>
    <div ref="tableElement">
      <Grid

        v-if="!parentDataObject || (data && data.content?.length > 0)"
        :style="{ height }"
        cell-render="dataAttribute"
        :size="parentDataObject === undefined ? 'medium' : 'small'"
        :data-items="gridItems"
        :columns="columns"
        :reorderable="true"
        :pageable="pageable"
        :loader="isLoading"
        :sort="sort"
        :take="pageSettings.take"
        :skip="pageSettings.skip"
        :total="pageSettings.total"
        :page-size="pageSettings.take"
        :filterable="showFilter"
        :filter="filter"
        :resizable="true"
        :sortable="true"
        detail="detailTemplate"
        expand-field="expanded"
        :groupable="{ footer: 'visible', enabled: parentDataObject === undefined }"
        :group="group"
        @pagechange="gridHelper.pageChangeHandler($event)"
        @filterchange="gridHelper.filterChange($event)"
        @sortchange="gridHelper.sortChange($event)"
        @datastatechange="dataStateChange"
        @expandchange="clickHandler"
      >
        <GridNoRecords>
          <KodexaGridNoRecords
            :is-error="isError" :is-loading="isLoading" :error="error"
            no-records-message="No data found"
          />
        </GridNoRecords>
        <template #detailTemplate="{ props }">
          <KodexaTabStrip
            :items="getChildItems(groupTaxonMetadata.taxon)"
            :selected="selectedChild(groupTaxonMetadata.taxon, props.dataItem.dataObjectInstance.uuid)"
            @selected="selectChild(props.dataItem.dataObjectInstance.uuid, $event)"
          />
          <KodexaDataObjectGridContainer
            v-if="selectedChild(groupTaxonMetadata.taxon, props.dataItem.dataObjectInstance.uuid)" :key="selectedChild(groupTaxonMetadata.taxon, props.dataItem.dataObjectInstance.uuid)"
            :data-store="dataStore"
            :group-taxon-metadata="tagMetadataMap.get(selectedChild(groupTaxonMetadata.taxon, props.dataItem.dataObjectInstance.uuid).taxon.path)"
            :parent-data-object="props.dataItem.dataObjectInstance" :view-id="viewId" :show-header="false"
          />
        </template>
        <template #dataAttribute="{ props }">
          <KodexaDataObjectGridGrouping
            :field="props.field"
            :expanded="props.expanded || false"
            :row-type="props.rowType"
            :level="props.level"
            :column-index="props.columnIndex"
            :columns-count="props.columnsCount"
            :data-item="props.dataItem"
            :class-name="props.className"
            :editor-options="editorOptions"
            :view-id="viewId"
            @click="clickHandler(props.dataItem)"
          />
        </template>
      </Grid>
    </div>
  </div>
</template>

<style scoped>
</style>
