<script lang="ts" setup>
import { storeToRefs } from "pinia";
import type { PropType } from "vue";
import appStore from "~/store";
import type { TagMetadata } from "~/store/useProject";
import { DataObject } from "~/model";

const props = defineProps({
  rows: {
    type: Array as PropType<any[]>,
    required: true,
  },
  viewId: {
    type: String,
    required: true,
  },
  parentDataObject: {
    type: Object as PropType<DataObject>,
    required: false,
    default: undefined,
  },
  parentDataObjectGroups: {
    type: Map as PropType<Map<string, DataObject>>,
    required: false,
    default: undefined,
  },
  parentTagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: false,
    default: undefined,
  },
});

const emit = defineEmits(["addCard", "selectCard", "removeCard", "deleteDataObject", "addDataObject", "moveDataObject"]);

onMounted(() => {
  nextTick(() => {
    // TODO replace me with something proper
    const els = document.querySelector(".kodexa-data-view-form-input");
    // @ts-expect-error this is horrible
    els?.focus();
  });
});
</script>

<template>
  <div>
    <div v-for="row in rows" :key="row.id" class="flex flex-wrap" :data-object-attributes="props.parentDataObject?.attributes[0]">
      <div
        v-for="rowCard in row.cards" :key="rowCard.id"
        :style="{ width: `${(parseInt(rowCard.colSpan) / 12) * 100}%` }"
      >
        <KodexaFormCard
          style="width: 100%" class="mt-1"
          :card="rowCard"
          :parent-tag-metadata="props.parentTagMetadata"
          :view-id="viewId"
          :parent-data-object="props.parentDataObject"
          :parent-data-object-groups="parentDataObjectGroups"
          :jump-on="rowCard.type === 'dataAttributeEditor' && rowCard.properties?.isJumpOn ? 'true' : 'false'"
          @delete-data-object="emit('deleteDataObject', $event)"
          @add-data-object="emit('addDataObject', $event)"
          @move-data-object="emit('moveDataObject', $event)"
        />
      </div>
    </div>
  </div>
</template>
