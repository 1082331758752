/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  ExceptionResponse,
  ListProductGroupsParams,
  PageProductGroup,
  Product,
  ProductGroup,
  ThrowableProblem
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get a resource with the provided ID
 */
export const getProductGroup = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<ProductGroup>(
      {url: `/api/productGroups/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetProductGroupQueryKey = (id: MaybeRef<string>,) => {
    return ['api','productGroups',id] as const;
    }

    
export const getGetProductGroupQueryOptions = <TData = Awaited<ReturnType<typeof getProductGroup>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProductGroup>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetProductGroupQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductGroup>>> = ({ signal }) => getProductGroup(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProductGroup>>, TError, TData> 
}

export type GetProductGroupQueryResult = NonNullable<Awaited<ReturnType<typeof getProductGroup>>>
export type GetProductGroupQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetProductGroup<TData = Awaited<ReturnType<typeof getProductGroup>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProductGroup>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetProductGroupQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the given instance
 */
export const updateProductGroup = (
    id: MaybeRef<string>,
    productGroup: MaybeRef<ProductGroup>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
productGroup = unref(productGroup);
      
      return customAxios<ProductGroup>(
      {url: `/api/productGroups/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: productGroup
    },
      options);
    }
  


export const getUpdateProductGroupMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProductGroup>>, TError,{id: string;data: ProductGroup}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateProductGroup>>, TError,{id: string;data: ProductGroup}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateProductGroup>>, {id: string;data: ProductGroup}> = (props) => {
          const {id,data} = props ?? {};

          return  updateProductGroup(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateProductGroupMutationResult = NonNullable<Awaited<ReturnType<typeof updateProductGroup>>>
    export type UpdateProductGroupMutationBody = ProductGroup
    export type UpdateProductGroupMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateProductGroup = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProductGroup>>, TError,{id: string;data: ProductGroup}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateProductGroup>>,
        TError,
        {id: string;data: ProductGroup},
        TContext
      > => {

      const mutationOptions = getUpdateProductGroupMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteProductGroup = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/productGroups/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteProductGroupMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProductGroup>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteProductGroup>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteProductGroup>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteProductGroup(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteProductGroupMutationResult = NonNullable<Awaited<ReturnType<typeof deleteProductGroup>>>
    
    export type DeleteProductGroupMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteProductGroup = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProductGroup>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteProductGroup>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteProductGroupMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listProductGroups = (
    params?: MaybeRef<ListProductGroupsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageProductGroup>(
      {url: `/api/productGroups`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListProductGroupsQueryKey = (params?: MaybeRef<ListProductGroupsParams>,) => {
    return ['api','productGroups', ...(params ? [params]: [])] as const;
    }

    
export const getListProductGroupsQueryOptions = <TData = Awaited<ReturnType<typeof listProductGroups>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListProductGroupsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listProductGroups>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListProductGroupsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listProductGroups>>> = ({ signal }) => listProductGroups(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listProductGroups>>, TError, TData> 
}

export type ListProductGroupsQueryResult = NonNullable<Awaited<ReturnType<typeof listProductGroups>>>
export type ListProductGroupsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useListProductGroups<TData = Awaited<ReturnType<typeof listProductGroups>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListProductGroupsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listProductGroups>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListProductGroupsQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createProductGroup = (
    productGroup: MaybeRef<ProductGroup>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      productGroup = unref(productGroup);
      
      return customAxios<ProductGroup>(
      {url: `/api/productGroups`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: productGroup, signal
    },
      options);
    }
  


export const getCreateProductGroupMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createProductGroup>>, TError,{data: ProductGroup}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createProductGroup>>, TError,{data: ProductGroup}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createProductGroup>>, {data: ProductGroup}> = (props) => {
          const {data} = props ?? {};

          return  createProductGroup(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateProductGroupMutationResult = NonNullable<Awaited<ReturnType<typeof createProductGroup>>>
    export type CreateProductGroupMutationBody = ProductGroup
    export type CreateProductGroupMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateProductGroup = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createProductGroup>>, TError,{data: ProductGroup}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof createProductGroup>>,
        TError,
        {data: ProductGroup},
        TContext
      > => {

      const mutationOptions = getCreateProductGroupMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForProductGroup = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/productGroups/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForProductGroupQueryKey = (id: MaybeRef<string>,) => {
    return ['api','productGroups',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForProductGroupQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForProductGroup>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForProductGroup>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForProductGroupQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForProductGroup>>> = ({ signal }) => getChangeSequenceForProductGroup(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForProductGroup>>, TError, TData> 
}

export type GetChangeSequenceForProductGroupQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForProductGroup>>>
export type GetChangeSequenceForProductGroupQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetChangeSequenceForProductGroup<TData = Awaited<ReturnType<typeof getChangeSequenceForProductGroup>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForProductGroup>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetChangeSequenceForProductGroupQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Get the products for a given product group
 */
export const getProducts = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<Product[]>(
      {url: `/api/productGroups/${id}/products`, method: 'GET', signal
    },
      options);
    }
  

export const getGetProductsQueryKey = (id: MaybeRef<string>,) => {
    return ['api','productGroups',id,'products'] as const;
    }

    
export const getGetProductsQueryOptions = <TData = Awaited<ReturnType<typeof getProducts>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProducts>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetProductsQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProducts>>> = ({ signal }) => getProducts(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProducts>>, TError, TData> 
}

export type GetProductsQueryResult = NonNullable<Awaited<ReturnType<typeof getProducts>>>
export type GetProductsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetProducts<TData = Awaited<ReturnType<typeof getProducts>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProducts>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetProductsQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



