<script lang="ts" setup>
import type { Ref } from "vue";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { createChannel, deleteChannel, getChannel, useListChannels } from "~/api/channel/channel";
import KodexaChannelViewer from "~/components/channels/kodexa-channel-viewer.vue";
import appStore from "~/store";
import { updateHandler } from "~/utils/error-handler";
import { log } from "~/utils/logger";

const props = defineProps({
  entryPoints: {
    type: Array,
    required: false,
    default: () => ["workspace"],
  },
});

defineEmits(["closed"]);

const {
  currentWorkspace,
  activeChannelId,
} = storeToRefs(appStore.workspaceStore);

const {
  project,
} = storeToRefs(appStore.projectStore);

const baseFilter = ref(`workspace.id: '${currentWorkspace.value?.id}'`);

const {
  data,
  refetch,
  isLoading,
} = useListChannels({
  filter: baseFilter.value,
  sort: "createdOn:desc",
  pageSize: 10,
}, {
  query: {
    enabled: true,
    refetchInterval: 1000,
    refetchIntervalInBackground: true,
    staleTime: 1000,
  },
});

const loading = ref(false);
const creating = ref(false);

const selectedChannelId: Ref<null | string> = ref(null);
const selectedChannel = computed(() => {
  if (activeChannelId.value && data.value && data.value.content) {
    selectedChannelId.value = activeChannelId.value;
    return data.value.content.find(c => c.id === activeChannelId.value);
  }

  if (selectedChannelId.value && data.value && data.value.content) {
    return data.value.content.find(c => c.id === selectedChannelId.value);
  }
  return null;
});

watch(() => [props.entrypoints, selectedChannelId, currentWorkspace, project], () => {
  if (props.entryPoints?.includes("workspace") && currentWorkspace.value) {
    baseFilter.value = `workspace.id: '${currentWorkspace.value.id}'`;
    refetch();
  } else if (props.entryPoints?.includes("project") && project.value) {
    baseFilter.value = `project.id: '${project.value.id}'`;
    refetch();
  }
  if (selectedChannelId.value) {
    activeChannelId.value = selectedChannelId.value;
  }
}, { immediate: true, deep: true });

watch(activeChannelId, () => {
  if (props.entryPoints?.includes("workspace")) {
    loading.value = true;
    getChannel(activeChannelId.value as string).then((channel) => {
      refetch().then(() => {
        selectedChannelId.value = channel.id as string;
        loading.value = false;
      });
    }).catch((e) => {
      log.error(`Error getting channel [${e}]`);
    });
  }
});

function startNewChannel() {
  creating.value = true;
  const participants = appStore.projectStore.buildParticipants();

  if (props.entryPoints?.includes("project")) {
    log.info(`Creating new channel for project [${project.value.id}]`);

    createChannel({
      name: "New Chat",
      project: { id: project.value.id },
      participants,
      private: false,
    }).then((channel) => {
      refetch();
      selectedChannelId.value = channel.id as string;
      creating.value = false;
    }).catch((e) => {
      console.error(e);
      creating.value = false;
    });
  } else {
    log.info(`Creating new channel for currentWorkspace [${currentWorkspace.value.id}]`);

    createChannel({
      name: "New Chat",
      workspace: { id: currentWorkspace.value.id },
      participants,
      private: false,
    }).then((channel) => {
      refetch();
      selectedChannelId.value = channel.id as string;
      creating.value = false;
    }).catch((e) => {
      console.error(e);
      creating.value = false;
    });
  }
}

const isDeleting = ref(false);

async function deleteSelected() {
  if (selectedChannel.value) {
    isDeleting.value = true;
    log.info("Deleting channel", selectedChannel.value.id);
    await updateHandler(deleteChannel(selectedChannel.value.id as string), "Chat deleted");
    isDeleting.value = false;
  } else {
    log.error("No channel selected");
  }
}

const {
  isZeroCredits,
} = storeToRefs(appStore.organizationStore);

const finalTemplates = computed(() => {
  const { messageTemplates } = storeToRefs(appStore.projectStore);
  return messageTemplates.value.filter(mt => !mt.requiresContext);
});

const showMessageTemplateOptions = ref(false);
const selectedMessageTemplate = ref(undefined);

function sendTemplate(messageTemplate: any) {
  // If the message template first block is options then we
  // want to get them filled in before we send
  if (messageTemplate.messageBlock && messageTemplate.messageBlock.type === "optionForm") {
    selectedMessageTemplate.value = messageTemplate;
    showMessageTemplateOptions.value = true;
  } else {
    appStore.workspaceStore.sendTemplateMessage(messageTemplate);
  }
}
</script>

<template>
  <div ref="chatTop" class="flex h-full flex-col border-r bg-gray-50">
    <div v-if="!isLoading" class="bg-gray-50">
      <div class="p-4" style="height: calc(100vh - 10rem); overflow: scroll">
        <KodexaDropDown
          v-if="data && data.content?.length > 0"
          v-model="selectedChannelId" placeholder="Select a chat to view messages" :disabled="!data"
          :items="data?.content || []" :loading="!data || loading"
          class="mb-1" label="Available Chats" name="selectedChannel" text-field="name" value-field="id"
        >
          <template #item-render="{ props }">
            <span v-if="props.dataItem">{{ props.dataItem.name }} (<KodexaDateTimeView
              :date="props.dataItem.createdOn"
              :ago="true"
            />)</span>
          </template>
          <template #value-render="{ props }">
            {{ props.value.name }} (
            <KodexaDateTimeView :date="props.value.createdOn" :ago="true" />
            )
          </template>
        </KodexaDropDown>
        <div class="buttons-container">
          <VDropdown v-if="data && data.content?.length > 0 && !isZeroCredits" style="width: 145px">
            <KodexaButton
              style="width: 145px"
              icon="chat-outline" size="small" type="secondary"
              :loading="creating"
            >
              Start New Chat
            </KodexaButton>
            <template #popper>
              <div class="bg-white shadow" style="max-height: 400px; width: 350px; overflow: auto">
                <div class="mx-2 my-2">
                  <div
                    v-close-popper
                    class="mt-1 flex cursor-pointer space-x-2 p-2 hover:bg-gray-100"
                    @click="startNewChannel"
                  >
                    <div class="shrink-0">
                      <div
                        class="mt-2 size-7 rounded bg-orange-400 p-1"
                      >
                        <MaterialDesignIcon
                          name="chat" class="text-white ml-1" style="margin-top: -8px" aria-hidden="true"
                          size="12"
                        />
                      </div>
                    </div>
                    <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                      <p class="text-sm text-gray-900">
                        Start new chat
                      </p>
                      <p class="text-xs font-light text-gray-600">
                        Open a new chat with participants
                      </p>
                    </div>
                  </div>
                  <hr class="my-2 h-0.5 border-t-0 bg-neutral-100 dark:bg-white/10">
                  <div
                    v-for="messageTemplate in finalTemplates"
                    :key="messageTemplate.id"
                    v-close-popper class="mt-1 flex cursor-pointer space-x-2 p-2 hover:bg-gray-100"
                    @click="sendTemplate(messageTemplate)"
                  >
                    <div class="shrink-0">
                      <div
                        class="mt-2 size-7 rounded bg-orange-400 p-1"
                      >
                        <MaterialDesignIcon
                          name="chat" class="text-white ml-1" style="margin-top: -8px" aria-hidden="true"
                          size="12"
                        />
                      </div>
                    </div>
                    <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                      <p class="text-sm text-gray-900">
                        {{ messageTemplate.label }}
                      </p>
                      <p class="text-xs font-light text-gray-600">
                        {{ messageTemplate.description }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </VDropdown>
          <KodexaButton
            v-if="data && data.content?.length > 0 && isZeroCredits" v-tooltip="`Chat is disabled. Your organization does not have any AI credits.`"
            disabled
            icon="chat-outline" size="small" type="gray"
          >
            Start New Chat
          </KodexaButton>
          <KodexaButton
            v-if="selectedChannel"
            :loading="isDeleting" class="ml-1" icon="delete" size="small" type="danger"
            @click="deleteSelected"
          >
            Delete Chat
          </KodexaButton>
        </div>
        <KodexaChannelViewer v-if="selectedChannel" :key="selectedChannel.id" :channel="selectedChannel" />
        <div v-else>
          <div class="mt-40 flex items-center justify-center" style="height: calc(100vh - 32rem)">
            <div class="text-center">
              <MaterialDesignIcon aria-hidden="true" class="size-12 text-gray-400" name="chat" />
              <h2 class="text-base font-semibold leading-6 text-gray-900">
                No Chats Selected
              </h2>
              <p v-if="data && data.content?.length === 0" class="mt-5 px-10 text-sm text-gray-500">
                <KodexaButton
                  id="startNewChat" icon="chat-outline" size="small" type="secondary"
                  @click="startNewChannel"
                >
                  Start New
                  Chat
                </KodexaButton>
                <span
                  v-if="data?.content?.length > 0"
                >, or select an existing chat to see the messages</span>
              </p>
              <KodexaArticle class="mt-10" article-id="9034541" text="Learn more about document chat" :slide="false" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="mt-64 flex items-center justify-center" style="height: calc(100vh - 40rem)">
        <div class="text-center">
          <KodexaSmallLoader class="mb-2" />
          <h2 class="text-base font-semibold leading-6 text-gray-900">
            Loading Chats
          </h2>
        </div>
      </div>
    </div>
    <KodexaMessageTemplateOptionsPopup
      v-model="showMessageTemplateOptions" :message-template="selectedMessageTemplate"
    />
  </div>
</template>

<style scoped>
.buttons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2px; /* This adds space between buttons */
  padding-top: 2px; /* Adds space above the buttons */
  justify-content: flex-start; /* Aligns items to the start of the container */
}

.button {
  min-width: 145px; /* Ensures all buttons have at least this width */
}
</style>
