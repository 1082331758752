<script setup lang="ts">
defineProps({
  selectedDocumentFamilies: {
    type: Array as PropType<DocumentFamily[]>,
    required: true,
  },
  documentStore: {
    type: Object as PropType<Store>,
    required: true,
  },
});

const emit = defineEmits(["clear-selection"]);

import KodexaBulkDeletePopup from "~/components/kodexa-bulk-delete-popup.vue";
import {type PropType, ref} from "vue";
import KodexaBulkReprocessPopup from "~/components/kodexa-bulk-reprocess-popup.vue";
import KodexaBulkLockPopup from "~/components/kodexa-bulk-lock-popup.vue";
import NewTaskPopover from '~/components/tasks/new-task-popover.vue';
import {DocumentFamily, Store} from "~/model";

const showBulkCopy = ref(false);
const showBulkDelete = ref(false);
const showBulkReprocess = ref(false);
const showBulkLock = ref(false);
const isLocking = ref(false);
const showNewTaskPopover = ref(false);
const selectedTask = ref(null);

const bulkActions = [
  // {icon: "copy", text: "Copy", action: "copy"},
  {icon: "clipboard-text", text: "Create Task", action: "create-task"},
  {icon: "refresh", text: "Reprocess", action: "reprocess"},
  {icon: "lock-open", text: "Unlock", action: "unlock"},
  {icon: "lock", text: "Lock", action: "lock"},
  {icon: "delete", text: "Delete", action: "delete"},
];

function executeBulkAction(item: any) {

  switch (item.action) {
    case "copy":
      showBulkCopy.value = true;
      break;
    case "create-task":
      showNewTaskPopover.value = true;
      break;
    case "reprocess":
      showBulkReprocess.value = true;
      break;
    case "unlock":
      isLocking.value = false;
      showBulkLock.value = true;
      break;
    case "lock":
      isLocking.value = true;
      showBulkLock.value = true;
      break;
    case "delete":
      showBulkDelete.value = true;
      break;
  }
}

function clearSelected() {
  showBulkCopy.value = false;
  showBulkDelete.value = false;
  showBulkReprocess.value = false;
  showBulkLock.value = false;
  showNewTaskPopover.value = false;
  emit("clear-selection");
}
</script>

<template>
  <div>
    <KodexaBulkCopyPopup v-if="showBulkCopy" v-model="showBulkCopy" :document-families="selectedDocumentFamilies"
                         :store="documentStore" @clear-selection="clearSelected"/>
    <KodexaBulkDeletePopup v-if="showBulkDelete" v-model="showBulkDelete" :document-families="selectedDocumentFamilies"
                           :store="documentStore" @clear-selection="clearSelected"/>
    <KodexaBulkReprocessPopup v-if="showBulkReprocess"
                              v-model="showBulkReprocess" :document-families="selectedDocumentFamilies"
                              :store="documentStore" @clear-selection="clearSelected"
    />
    <KodexaBulkLockPopup
      v-model="showBulkLock" :document-families="selectedDocumentFamilies" :lock="isLocking"
      :store="documentStore"
    />
    <NewTaskPopover
      v-model="showNewTaskPopover"
      :document-families="selectedDocumentFamilies"
      :task-to-edit="selectedTask"
      @update:task-to-edit="(val) => selectedTask = val"
      @task-created="clearSelected"
      @task-updated="clearSelected"
      @update:model-value="showNewTaskPopover = $event"
    />
    <VDropdown>
      <KodexaButton :items="bulkActions" @itemclick="executeBulkAction" type="secondary" size="small"
                    icon="form-dropdown" class="mt-1">
        Bulk Actions ({{selectedDocumentFamilies.length}})
      </KodexaButton>
      <template #popper>
        <div class="bg-white shadow sm:rounded-lg" style="max-height: 400px; width: 150px; overflow: auto">
          <div class="mx-4 my-2">
            <div
              v-for="item in bulkActions" :key="item.text" class="mt-1 cursor-pointer p-2 hover:bg-gray-100"
              v-close-popper
              @click="executeBulkAction(item)"
            >
              <MaterialDesignIcon :name="item.icon" size="14" class="text-gray-600 mr-2 -mt-1" style="float:left"/>
              <p class="text-sm">
                {{ item.text }}
              </p>
            </div>
          </div>
        </div>
      </template>
    </VDropdown>

  </div>
</template>

<style scoped>

</style>
