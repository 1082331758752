<script setup lang="ts">
import { storeToRefs } from "pinia";
import { Toolbar, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import appStore from "~/store";

const { project } = storeToRefs(appStore.projectStore);

const localProject = ref(undefined);

watch(project, (newValue) => {
  localProject.value = newValue;
}, { deep: true, immediate: true });

watch(localProject, (newValue) => {
  if (newValue) {
    appStore.projectStore.updateCurrentProject(newValue);
  }
}, {
  deep: true,
});

const items = [
  { id: "string", text: "String" },
  { id: "number", text: "Number" },
  { id: "boolean", text: "Boolean" },
];

function addOption() {
  localProject.value?.options.options.push({ name: "", label: "", type: "string", description: "" });
}
function deleteOption(idx: number) {
  localProject.value?.options.options.splice(idx, 1);
}
</script>

<template>
  <div class="mx-4">
    <Toolbar class="border-0 bg-white">
      <ToolbarSpacer />
      <KodexaButton name="add" icon="plus" type="primary" @click="addOption">
        Add Option
      </KodexaButton>
    </Toolbar>
    <div class="p-4 space-y-4">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div v-for="(option, idx) in localProject.options.options" :key="idx" class="p-4 shadow-lg rounded-lg">
          <KodexaTextInput
            v-model="option.name"
            class="mt-2"
            name="name"
            label="Name"
          />
          <KodexaTextInput
            v-model="option.label"
            class="mt-2"
            name="label"
            label="Label"
          />
          <KodexaTextInput
            v-model="option.description"
            class="mt-2"
            name="description"
            label="Description"
          />
          <KodexaDropDown
            v-model="option.type"
            class="mt-2"
            name="type"
            :items="items"
            label="Type"
          />
          <KodexaButton name="delete" icon="delete" type="danger" class="mt-2" @click="deleteOption(idx)">
            Delete
          </KodexaButton>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
