<script setup lang="ts">
import { ref, computed } from 'vue';
import type { PropType } from "vue";
import { storeToRefs } from "pinia";
import appStore from "~/store";
import { log } from "~/utils/logger";
import type { TagMetadata } from "~/store/useProject";
import type { DataAttribute, DataObject } from "~/model";

const props = defineProps({
  dataObject: {
    type: Object as PropType<DataObject>,
    required: true,
  },
  attribute: {
    type: Object as PropType<DataAttribute>,
    required: false,
  },
  viewId: {
    type: String,
    required: true,
  },
  placeholder: {
    type: Boolean,
    required: false,
    default: false,
  },
  tagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: false,
  },
});

const emit = defineEmits(["deleteAttribute", "addSelectedValue"]);

const { activeSelectionView } = storeToRefs(appStore.workspaceStore);

const reactiveDataObject = computed(() => {
  return { ...props.dataObject };
});

interface NavItem {
  text: string;
  items?: NavItem[];
  run?: () => void;
  icon?: string;
  render?: string;
  divider?: boolean;
  color?: string;
}

const navigation = ref([] as NavItem[]);

function buildNav() {
  const items = [] as NavItem[];

  if (props.attribute?.ownerUri && props.attribute.ownerUri.includes("model")) {
    items.push(
      { icon: "creation", render: "itemRender", text: "AI Labelled", color: "text-gray-400" },
    );
    items.push(
      { divider: true },
    );
  }

  // Add Audit/Notes menu item
  items.push(
    {
      icon: "note-text-outline",
      text: "Show in Context",
      run: async () => {
        const { currentWorkspaceId } = storeToRefs(appStore.workspaceStore);
        const useSidebar = createSidecar(currentWorkspaceId.value);
        useSidebar.focusAttribute(props.attribute, props.dataObject);
      },
    },
  );

  if (!props.dataObject?.documentFamily?.locked && !props.placeholder) {
    items.push(
      { color: "text-red-400", icon: "delete", render: "itemRender", text: "Delete" },
    );
  }

  if (activeSelectionView.value && activeSelectionView.value.viewId) {
    const view = appStore.workspaceStore.getViewById(activeSelectionView.value.viewId);
    if (view && view.viewType === "document") {
      const documentView = createDocumentViewerStore(view.id);
      if (reactiveDataObject.value?.documentFamily.id === documentView?.documentFamily.id) {
        if (documentView?.selectionContext && documentView.selectionContext.selectedNodes.length > 0) {
          items.push(
            {
              icon: "plus",
              text: "Add Selected Value",
              run: () => {
                appStore.workspaceStore.loadDataObject(props.dataObject);

                if (documentView && props.tagMetadata) {
                  documentView.addTag(props.tagMetadata, documentView.selectionContext.selectedNodes, { groupUuid: props.dataObject.groupUuid });
                } else {
                  log.warn("No document view store or missing attribute path");
                }
              },
            },
          );
        }
      }
    }
  }

  navigation.value = items;
}

function openNav(item: NavItem) {
  if (item.text === "Delete") {
    emit("deleteAttribute");
  }

  if (item.run) {
    item.run();
  }
}
</script>

<template>
  <div>
    <VDropdown :arrow-overflow="false" @click="buildNav">
      <!-- This will be the popover target (for the events and position) -->
      <MaterialDesignIcon name="THREEDOTMENU" class="size-5 pt-2 text-gray-500" aria-hidden="true" />
      <!-- This will be the content of the popover -->
      <template #popper>
        <nav class="space-y-1 p-2" aria-label="Sidebar">
          <div v-for="item in navigation" :key="item.name">
            <a
              v-if="!item.divider"
              v-close-popper
              class="cursor-pointer flex items-center rounded-md px-3 py-2 text-xs font-medium text-gray-600 hover:bg-gray-100 hover:text-gray-900"
              :aria-current="item.current ? 'page' : undefined" @click="openNav(item)"
            >
              <MaterialDesignIcon
                :name="item.icon"
                class="-ml-1 mr-3 size-4 shrink-0"
                aria-hidden="true"
              />
              <span class="truncate">{{ item.text }}</span>
            </a>
            <div v-else class="my-2 border-t border-gray-200" />
          </div>
        </nav>
      </template>
    </VDropdown>
  </div>
</template>
