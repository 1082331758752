<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import appStore from "~/store";
import type { CostData, ModelInteraction } from "~/model";
import { log } from "~/utils/logger";
import KodexaCostAnalysisView from '~/components/costAnalysis/kodexa-cost-analysis-view.vue';

const props = defineProps({
  viewId: {
    type: String,
    required: true,
  },
  kioskMode: {
    type: Boolean,
    default: false,
    required: false,
  },
});

const view = ref(null);
const modelInteractions = ref([] as ModelInteraction[]);
const totalCost = ref('0');
const dataLoaded = ref(false);

onMounted(() => {
  view.value = appStore.workspaceStore.views.find(v => v.id === props.viewId);
  console.log("Found view:", JSON.stringify(view.value, null, 2));
  if (view.value) {
    modelInteractions.value = view.value.modelInteractions || [];
    totalCost.value = view.value.totalCost || '0';
    dataLoaded.value = true;
  }
});
</script>

<template>
  <div style="height: calc(100vh - 13rem); overflow-y: scroll; width: 100%; position: relative">
    <KodexaCostAnalysisView
      v-if="dataLoaded"
      :model-interactions="modelInteractions"
      :total-cost="totalCost"
    />
    <div v-else>
      Loading cost data...
    </div>
  </div>
</template>
