<script setup lang="ts">
import { storeToRefs } from "pinia";
import type { Ref } from "vue";
import { ref } from "vue";
import type { PropType } from "vue/dist/vue";
import appStore from "~/store";
import type { TagMetadata } from "~/store/useProject";
import type { Card, DataObject } from "~/model";

defineProps({
  card: {
    type: Object as PropType<Card>,
    required: true,
  },
  designMode: {
    type: Boolean,
    required: false,
    default: false,
  },
  viewId: {
    type: String,
    required: true,
  },
});

const { tagMetadataMap } = storeToRefs(appStore.projectStore);

const { dataObjects } = storeToRefs(appStore.workspaceStore);

const groupTaxons: Ref<TagMetadata[]> = computed(() => {
  return Array.from(tagMetadataMap.value.values()).filter((tagMetadata: any) => {
    return tagMetadata.taxon.group && tagMetadata.taxon.enabled && tagMetadata.taxon.group && tagMetadata.taxonomy.taxonomyType === "CONTENT" && !tagMetadata.taxon.path.includes("/");
  }) as TagMetadata[];
});

const currentTaxon = ref<TagMetadata | undefined>(undefined);

watch(groupTaxons, () => {
  if (groupTaxons.value.length > 0) {
    currentTaxon.value = groupTaxons.value[0] as TagMetadata;
  }
}, { immediate: true, deep: true });

const rawDataObjects: Ref<DataObject[]> = computed(() => {
  return dataObjects.value ? Array.from(dataObjects.value.values()) : [];
});
</script>

<template>
  <div>
    <div class="px-1">
      <ul class="-mb-px -mt-4 flex flex-wrap text-center text-sm font-medium text-gray-500 dark:text-gray-400">
        <li
          v-for="groupTaxon in groupTaxons"
          :key="groupTaxon.taxon.id"
          class="mr-2"
          @click="currentTaxon = groupTaxon"
        >
          <a
            class="text-md"
            :class="currentTaxon?.taxon.id === groupTaxon.taxon.id ? 'inline-flex items-center justify-center p-4 text-theme-primary border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group' : 'inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'"
          >
            {{ groupTaxon.label }}
          </a>
        </li>
      </ul>
    </div>
    <div>
      <KodexaWorkspaceDataObjectGridContainer
        v-if="currentTaxon"
        :key="currentTaxon?.taxon.id" :view-id="viewId"
        :data-objects="rawDataObjects"
        :group-taxon-metadata="currentTaxon" class="mt-1"
        :group-taxons="groupTaxons"
      />
    </div>
  </div>
</template>

<style scoped>

</style>
