/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  CreateTaxonomyParams,
  DeleteTaxonomyParams,
  DeleteVersionTaxonomyParams,
  ExceptionResponse,
  ExportVersionParams,
  ListTaxonomiesForOrganizationParams,
  ListTaxonomiesParams,
  PageTaxonomy,
  SemanticDefinitionSuggestionRequest,
  SemanticDefinitionSuggestionResponse,
  StreamingResponseBody,
  Taxonomy,
  ThrowableProblem
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get the current version of the object with given slug
 */
export const getTaxonomy = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
      
      return customAxios<Taxonomy>(
      {url: `/api/taxonomies/${organizationSlug}/${slug}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTaxonomyQueryKey = (organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,) => {
    return ['api','taxonomies',organizationSlug,slug] as const;
    }

    
export const getGetTaxonomyQueryOptions = <TData = Awaited<ReturnType<typeof getTaxonomy>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTaxonomy>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetTaxonomyQueryKey(organizationSlug,slug);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTaxonomy>>> = ({ signal }) => getTaxonomy(organizationSlug,slug, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug) && unref(slug))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTaxonomy>>, TError, TData> 
}

export type GetTaxonomyQueryResult = NonNullable<Awaited<ReturnType<typeof getTaxonomy>>>
export type GetTaxonomyQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetTaxonomy<TData = Awaited<ReturnType<typeof getTaxonomy>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTaxonomy>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetTaxonomyQueryOptions(organizationSlug,slug,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the current version object with given slug in the organization
 */
export const updateTaxonomy = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    taxonomy: MaybeRef<Taxonomy>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
taxonomy = unref(taxonomy);
      
      return customAxios<Taxonomy>(
      {url: `/api/taxonomies/${organizationSlug}/${slug}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: taxonomy
    },
      options);
    }
  


export const getUpdateTaxonomyMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTaxonomy>>, TError,{organizationSlug: string;slug: string;data: Taxonomy}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateTaxonomy>>, TError,{organizationSlug: string;slug: string;data: Taxonomy}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateTaxonomy>>, {organizationSlug: string;slug: string;data: Taxonomy}> = (props) => {
          const {organizationSlug,slug,data} = props ?? {};

          return  updateTaxonomy(organizationSlug,slug,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateTaxonomyMutationResult = NonNullable<Awaited<ReturnType<typeof updateTaxonomy>>>
    export type UpdateTaxonomyMutationBody = Taxonomy
    export type UpdateTaxonomyMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateTaxonomy = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTaxonomy>>, TError,{organizationSlug: string;slug: string;data: Taxonomy}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateTaxonomy>>,
        TError,
        {organizationSlug: string;slug: string;data: Taxonomy},
        TContext
      > => {

      const mutationOptions = getUpdateTaxonomyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the current version of the given object
 */
export const deleteTaxonomy = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    params?: MaybeRef<DeleteTaxonomyParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
params = unref(params);
      
      return customAxios<StreamingResponseBody>(
      {url: `/api/taxonomies/${organizationSlug}/${slug}`, method: 'DELETE',
        params: unref(params)
    },
      options);
    }
  


export const getDeleteTaxonomyMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTaxonomy>>, TError,{organizationSlug: string;slug: string;params?: DeleteTaxonomyParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteTaxonomy>>, TError,{organizationSlug: string;slug: string;params?: DeleteTaxonomyParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTaxonomy>>, {organizationSlug: string;slug: string;params?: DeleteTaxonomyParams}> = (props) => {
          const {organizationSlug,slug,params} = props ?? {};

          return  deleteTaxonomy(organizationSlug,slug,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteTaxonomyMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTaxonomy>>>
    
    export type DeleteTaxonomyMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteTaxonomy = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTaxonomy>>, TError,{organizationSlug: string;slug: string;params?: DeleteTaxonomyParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteTaxonomy>>,
        TError,
        {organizationSlug: string;slug: string;params?: DeleteTaxonomyParams},
        TContext
      > => {

      const mutationOptions = getDeleteTaxonomyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the specific version of the object with given slug
 */
export const getVersionTaxonomy = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
      
      return customAxios<Taxonomy>(
      {url: `/api/taxonomies/${organizationSlug}/${slug}/${version}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetVersionTaxonomyQueryKey = (organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,) => {
    return ['api','taxonomies',organizationSlug,slug,version] as const;
    }

    
export const getGetVersionTaxonomyQueryOptions = <TData = Awaited<ReturnType<typeof getVersionTaxonomy>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getVersionTaxonomy>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetVersionTaxonomyQueryKey(organizationSlug,slug,version);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getVersionTaxonomy>>> = ({ signal }) => getVersionTaxonomy(organizationSlug,slug,version, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug) && unref(slug) && unref(version))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getVersionTaxonomy>>, TError, TData> 
}

export type GetVersionTaxonomyQueryResult = NonNullable<Awaited<ReturnType<typeof getVersionTaxonomy>>>
export type GetVersionTaxonomyQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetVersionTaxonomy<TData = Awaited<ReturnType<typeof getVersionTaxonomy>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getVersionTaxonomy>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetVersionTaxonomyQueryOptions(organizationSlug,slug,version,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the object with given slug and version in the organization
 */
export const updateVersionTaxonomy = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    taxonomy: MaybeRef<Taxonomy>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
taxonomy = unref(taxonomy);
      
      return customAxios<Taxonomy>(
      {url: `/api/taxonomies/${organizationSlug}/${slug}/${version}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: taxonomy
    },
      options);
    }
  


export const getUpdateVersionTaxonomyMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateVersionTaxonomy>>, TError,{organizationSlug: string;slug: string;version: string;data: Taxonomy}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateVersionTaxonomy>>, TError,{organizationSlug: string;slug: string;version: string;data: Taxonomy}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateVersionTaxonomy>>, {organizationSlug: string;slug: string;version: string;data: Taxonomy}> = (props) => {
          const {organizationSlug,slug,version,data} = props ?? {};

          return  updateVersionTaxonomy(organizationSlug,slug,version,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateVersionTaxonomyMutationResult = NonNullable<Awaited<ReturnType<typeof updateVersionTaxonomy>>>
    export type UpdateVersionTaxonomyMutationBody = Taxonomy
    export type UpdateVersionTaxonomyMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateVersionTaxonomy = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateVersionTaxonomy>>, TError,{organizationSlug: string;slug: string;version: string;data: Taxonomy}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateVersionTaxonomy>>,
        TError,
        {organizationSlug: string;slug: string;version: string;data: Taxonomy},
        TContext
      > => {

      const mutationOptions = getUpdateVersionTaxonomyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the specified version of the given object
 */
export const deleteVersionTaxonomy = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<DeleteVersionTaxonomyParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
params = unref(params);
      
      return customAxios<boolean>(
      {url: `/api/taxonomies/${organizationSlug}/${slug}/${version}`, method: 'DELETE',
        params: unref(params)
    },
      options);
    }
  


export const getDeleteVersionTaxonomyMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteVersionTaxonomy>>, TError,{organizationSlug: string;slug: string;version: string;params?: DeleteVersionTaxonomyParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteVersionTaxonomy>>, TError,{organizationSlug: string;slug: string;version: string;params?: DeleteVersionTaxonomyParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteVersionTaxonomy>>, {organizationSlug: string;slug: string;version: string;params?: DeleteVersionTaxonomyParams}> = (props) => {
          const {organizationSlug,slug,version,params} = props ?? {};

          return  deleteVersionTaxonomy(organizationSlug,slug,version,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteVersionTaxonomyMutationResult = NonNullable<Awaited<ReturnType<typeof deleteVersionTaxonomy>>>
    
    export type DeleteVersionTaxonomyMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteVersionTaxonomy = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteVersionTaxonomy>>, TError,{organizationSlug: string;slug: string;version: string;params?: DeleteVersionTaxonomyParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteVersionTaxonomy>>,
        TError,
        {organizationSlug: string;slug: string;version: string;params?: DeleteVersionTaxonomyParams},
        TContext
      > => {

      const mutationOptions = getDeleteVersionTaxonomyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a paginated list of all resources
 */
export const listTaxonomies = (
    params?: MaybeRef<ListTaxonomiesParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageTaxonomy>(
      {url: `/api/taxonomies`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListTaxonomiesQueryKey = (params?: MaybeRef<ListTaxonomiesParams>,) => {
    return ['api','taxonomies', ...(params ? [params]: [])] as const;
    }

    
export const getListTaxonomiesQueryOptions = <TData = Awaited<ReturnType<typeof listTaxonomies>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListTaxonomiesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTaxonomies>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListTaxonomiesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listTaxonomies>>> = ({ signal }) => listTaxonomies(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listTaxonomies>>, TError, TData> 
}

export type ListTaxonomiesQueryResult = NonNullable<Awaited<ReturnType<typeof listTaxonomies>>>
export type ListTaxonomiesQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useListTaxonomies<TData = Awaited<ReturnType<typeof listTaxonomies>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListTaxonomiesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTaxonomies>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListTaxonomiesQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Expression builder
 */
export const suggestTaxon = (
    semanticDefinitionSuggestionRequest: MaybeRef<SemanticDefinitionSuggestionRequest>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      semanticDefinitionSuggestionRequest = unref(semanticDefinitionSuggestionRequest);
      
      return customAxios<SemanticDefinitionSuggestionResponse>(
      {url: `/api/taxonomies`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: semanticDefinitionSuggestionRequest, signal
    },
      options);
    }
  


export const getSuggestTaxonMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof suggestTaxon>>, TError,{data: SemanticDefinitionSuggestionRequest}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof suggestTaxon>>, TError,{data: SemanticDefinitionSuggestionRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof suggestTaxon>>, {data: SemanticDefinitionSuggestionRequest}> = (props) => {
          const {data} = props ?? {};

          return  suggestTaxon(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SuggestTaxonMutationResult = NonNullable<Awaited<ReturnType<typeof suggestTaxon>>>
    export type SuggestTaxonMutationBody = SemanticDefinitionSuggestionRequest
    export type SuggestTaxonMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useSuggestTaxon = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof suggestTaxon>>, TError,{data: SemanticDefinitionSuggestionRequest}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof suggestTaxon>>,
        TError,
        {data: SemanticDefinitionSuggestionRequest},
        TContext
      > => {

      const mutationOptions = getSuggestTaxonMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a paginated list of the resources for an organization
 */
export const listTaxonomiesForOrganization = (
    organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListTaxonomiesForOrganizationParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
params = unref(params);
      
      return customAxios<PageTaxonomy>(
      {url: `/api/taxonomies/${organizationSlug}`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListTaxonomiesForOrganizationQueryKey = (organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListTaxonomiesForOrganizationParams>,) => {
    return ['api','taxonomies',organizationSlug, ...(params ? [params]: [])] as const;
    }

    
export const getListTaxonomiesForOrganizationQueryOptions = <TData = Awaited<ReturnType<typeof listTaxonomiesForOrganization>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListTaxonomiesForOrganizationParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTaxonomiesForOrganization>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListTaxonomiesForOrganizationQueryKey(organizationSlug,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listTaxonomiesForOrganization>>> = ({ signal }) => listTaxonomiesForOrganization(organizationSlug,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listTaxonomiesForOrganization>>, TError, TData> 
}

export type ListTaxonomiesForOrganizationQueryResult = NonNullable<Awaited<ReturnType<typeof listTaxonomiesForOrganization>>>
export type ListTaxonomiesForOrganizationQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useListTaxonomiesForOrganization<TData = Awaited<ReturnType<typeof listTaxonomiesForOrganization>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListTaxonomiesForOrganizationParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTaxonomiesForOrganization>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListTaxonomiesForOrganizationQueryOptions(organizationSlug,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Create a new instance of the object in the organization using the template with given ref
 */
export const createTaxonomy = (
    organizationSlug: MaybeRef<string>,
    taxonomy: MaybeRef<Taxonomy>,
    params: MaybeRef<CreateTaxonomyParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
taxonomy = unref(taxonomy);
params = unref(params);
      
      return customAxios<Taxonomy>(
      {url: `/api/taxonomies/${organizationSlug}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: taxonomy,
        params: unref(params), signal
    },
      options);
    }
  


export const getCreateTaxonomyMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTaxonomy>>, TError,{organizationSlug: string;data: Taxonomy;params: CreateTaxonomyParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createTaxonomy>>, TError,{organizationSlug: string;data: Taxonomy;params: CreateTaxonomyParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTaxonomy>>, {organizationSlug: string;data: Taxonomy;params: CreateTaxonomyParams}> = (props) => {
          const {organizationSlug,data,params} = props ?? {};

          return  createTaxonomy(organizationSlug,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateTaxonomyMutationResult = NonNullable<Awaited<ReturnType<typeof createTaxonomy>>>
    export type CreateTaxonomyMutationBody = Taxonomy
    export type CreateTaxonomyMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateTaxonomy = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTaxonomy>>, TError,{organizationSlug: string;data: Taxonomy;params: CreateTaxonomyParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof createTaxonomy>>,
        TError,
        {organizationSlug: string;data: Taxonomy;params: CreateTaxonomyParams},
        TContext
      > => {

      const mutationOptions = getCreateTaxonomyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided slug and version
 */
export const getModifiedForTaxonomy = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
      
      return customAxios<number>(
      {url: `/api/taxonomies/${organizationSlug}/${slug}/${version}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetModifiedForTaxonomyQueryKey = (organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,) => {
    return ['api','taxonomies',organizationSlug,slug,version,'sequence'] as const;
    }

    
export const getGetModifiedForTaxonomyQueryOptions = <TData = Awaited<ReturnType<typeof getModifiedForTaxonomy>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getModifiedForTaxonomy>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetModifiedForTaxonomyQueryKey(organizationSlug,slug,version);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getModifiedForTaxonomy>>> = ({ signal }) => getModifiedForTaxonomy(organizationSlug,slug,version, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug) && unref(slug) && unref(version))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getModifiedForTaxonomy>>, TError, TData> 
}

export type GetModifiedForTaxonomyQueryResult = NonNullable<Awaited<ReturnType<typeof getModifiedForTaxonomy>>>
export type GetModifiedForTaxonomyQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetModifiedForTaxonomy<TData = Awaited<ReturnType<typeof getModifiedForTaxonomy>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getModifiedForTaxonomy>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetModifiedForTaxonomyQueryOptions(organizationSlug,slug,version,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Get the specific version of the object with given slug
 */
export const exportVersion = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params: MaybeRef<ExportVersionParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
params = unref(params);
      
      return customAxios<string>(
      {url: `/api/taxonomies/${organizationSlug}/${slug}/${version}/export`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getExportVersionQueryKey = (organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params: MaybeRef<ExportVersionParams>,) => {
    return ['api','taxonomies',organizationSlug,slug,version,'export', ...(params ? [params]: [])] as const;
    }

    
export const getExportVersionQueryOptions = <TData = Awaited<ReturnType<typeof exportVersion>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params: MaybeRef<ExportVersionParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportVersion>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getExportVersionQueryKey(organizationSlug,slug,version,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof exportVersion>>> = ({ signal }) => exportVersion(organizationSlug,slug,version,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug) && unref(slug) && unref(version))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof exportVersion>>, TError, TData> 
}

export type ExportVersionQueryResult = NonNullable<Awaited<ReturnType<typeof exportVersion>>>
export type ExportVersionQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useExportVersion<TData = Awaited<ReturnType<typeof exportVersion>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params: MaybeRef<ExportVersionParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportVersion>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getExportVersionQueryOptions(organizationSlug,slug,version,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



