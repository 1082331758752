<!--
  - Copyright (C) 2024 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import type { ComputedRef, PropType } from "vue";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { v4 as uuidv4 } from "uuid";
import type { Card, DataObject } from "~/model";

const props = defineProps({
  card: {
    type: Object as PropType<Card>,
    required: true,
  },
  parentDataObject: {
    type: Object as PropType<DataObject>,
    required: false,
  },
  parentDataObjectGroups: {
    type: Map as PropType<Map<string, DataObject>>,
    required: false,
  },
});
const emit = defineEmits(["moveDataObject"]);
interface DataItem {
  id?: string;
  text?: string;
  dataObject?: DataObject;
}

const defaultValue: ComputedRef<DataItem> = computed(() => {
  if (props.parentDataObjectGroups) {
    const matchDataObjectGroups = Array.from(props.parentDataObjectGroups).find(([key, value]) => {
      return value.uuid === props.parentDataObject?.parent?.uuid;
    });

    if (matchDataObjectGroups) {
      const [key, value] = matchDataObjectGroups;
      return { id: uuidv4(), text: key, dataObject: value };
    } else {
      return {};
    }
  } else {
    return {};
  }
});

const dataItems = computed(() => {
  const items: DataItem[] = [];
  if (!props.parentDataObjectGroups) {
    return items;
  }
  props.parentDataObjectGroups.forEach((value, key) => {
    if (value.uuid === props.parentDataObject?.parent?.uuid) {
      return;
    }
    const item = { id: uuidv4(), text: `${key}`, dataObject: value };

    items.push(item);
  });
  return items;
});

const selectedValue = computed({
  get() {
    return defaultValue.value;
  },
  set(value: DataItem) {
    emit("moveDataObject", { destinationDataObject: value.dataObject, dataObject: props.parentDataObject });
  },
});
</script>

<template>
  <div class="mr-2">
    <p class="mb-1 text-gray-700">
      <span v-if="props.card?.properties?.label">{{ props.card.properties.label }}</span>
    </p>
    <DropDownList
      v-model="selectedValue"
      :data-items="dataItems"
      text-field="text"
      data-item-key="id"
      fill-mode="outline"
      rounded="medium"
      class="border-green-500 text-gray-800 opacity-70 hover:text-black "
      style="background-color: white;"
      :left-right-keys-navigation="false"
    />
  </div>
</template>

<style scoped>
</style>
