<script setup lang="ts">
import { toRef } from "vue";
import { TextArea } from "@progress/kendo-vue-inputs";

const props = defineProps({
  id: {
    type: String,
    required: false,
  },
  modelValue: {
    type: String,
    default: "",
    required: false,
  },
  name: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: "text",
  },
  label: {
    type: String,
    required: false,
    default: undefined,
  },
  successMessage: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  autocomplete: {
    type: String,
    default: "",
  },
  errors: {
    type: Object,
    default: () => {
    },
  },
  rows: {
    type: Number,
    default: 3,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  hint: {
    type: String,
    default: undefined,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue", "onEnter", "onBlur"]);

const name = toRef(props, "name");

const localValue = computed({
  get: () => props.modelValue,
  set: value => emit("update:modelValue", value),
});
</script>

<template>
  <div>
    <div
      :class="{ 'has-error': errors && errors[name], 'success': errors && !errors[name] }"
    />
    <label v-if="label" for="name" class="kodexa-label"> {{ label }}</label>
    <TextArea
      :id="id || name"
      v-model="localValue"
      :disabled="disabled"
      :loading="loading"
      :show-loading-icon="loading"
      :enable="true"
      :placeholder="placeholder"
      class="kodexa-text-area"
      :style="{ height: `${rows * 20}px` }"
      @on-blur="emit('onBlur', $event)"
      @on-enter="emit('onEnter', $event)"
    />
    <p v-if="hint" class="text-sm text-gray-500">
      {{ hint }}
    </p>

    <p v-if="errors && errors[name]" class="mt-2 text-sm text-red-600">
      {{ errors[name] }}
    </p>
  </div>
</template>

<style scoped>
.kodexa-label {
  @apply block text-gray-700 mb-1 !important;
}

.kodexa-text-area {
  @apply w-full appearance-none rounded-md border border-gray-300
  px-0 py-0 placeholder:text-gray-400
}

.kodexa-text-area:focus-within {
  @apply border-blue-500 ring-1 ring-blue-500
}

.kodexa-text-area:focus {
  @apply border-blue-500 ring-1 ring-blue-500
}
</style>
