<script lang="ts" setup>
import { createConfirmDialog } from "vuejs-confirm-dialog";
import KodexaCheckbox from "~/components/inputs/kodexa-checkbox.vue";
import appStore from "~/store";
import KodexaConfirm from "~/components/kodexa-confirm.vue";

const props = defineProps({
  resource: Object,
  label: String,
});

const emit = defineEmits(["deleted"]);

const isDelete = ref(false);

async function deleteResource() {
  const confirmLostChanges = createConfirmDialog(KodexaConfirm);
  const result = await confirmLostChanges.reveal({
    icon: "alert-circle-outline",
    title: "Unsaved Changes",
    message: "You have unsaved changes, if you continue they will be lost!",
    notes: "Changes to resources will require the project to reload. Do you want to discard the changes, or stay in the project to save them",
    confirmText: "Discard Changes, Delete Resource",
    confirmIcon: "alert-circle-outline",
    cancelText: "Return to Project",
    cancelIcon: "close",
    type: "danger",
  });

  if (result.isCanceled) {
    return false;
  }

  await appStore.projectStore.unlinkProjectResource(props.resource, isDelete.value);
  emit("deleted");
}
</script>

<template>
  <div class="bg-white shadow sm:rounded-lg mt-4">
    <div v-if="!resource?.deleteProtection" class="px-4 py-5 sm:p-6">
      <div class="sm:flex sm:items-start sm:justify-between">
        <div>
          <h3 class="text-base font-semibold leading-6 text-gray-900">
            Deleting or Unlinking {{ label }}
          </h3>
          <div class="mt-2 max-w-xl text-sm text-gray-500">
            <p>
              You can either unlink the {{ label }} from this project, which will not delete any content but it will remove it from
              the project.  If you want to completely delete the {{ label }}, then click the checkbox below to confirm you are deleting.
            </p>
            <KodexaCheckbox
              v-model="isDelete"
              class="mt-3"
              name="isDelete"
              label="Delete the resource (this action can not be undone)"
            />
            <KodexaButton id="deleteProject" icon="delete" :type="isDelete ? 'danger' : 'secondary'" class="mt-7" @click="deleteResource()">
              {{ isDelete ? `Delete ${label}` : `Unlink ${label}` }}
            </KodexaButton>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="px-4 py-5 sm:p-6">
      <div class="sm:flex sm:items-start sm:justify-between">
        <div>
          <h3 class="text-base font-semibold leading-6 text-gray-900">
            Delete Protection Enabled
          </h3>
          <div class="mt-2 max-w-xl text-sm text-gray-500">
            <p>
              This {{ label }} has delete protection enabled, you can not delete it. However, you can unlink it from the project.
            </p>
            <KodexaButton id="deleteProject" icon="delete" :type="isDelete ? 'danger' : 'secondary'" class="mt-7" @click="deleteResource()">
              {{ isDelete ? `Delete ${label}` : `Unlink ${label}` }}
            </KodexaButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
