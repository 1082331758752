<script lang="ts" setup>
import type { PropType } from "vue";
import appStore from "~/store";

const props = defineProps({
  keyName: {
    type: String,
    required: true,
  },
  node: {
    type: Object as PropType<Node>,
    required: true,
  },
  nodeColor: {
    type: String,
    default: "#23ABDC",
  },
  textColor: {
    type: String,
    default: "white",
  },
});

function openMetadata() {
  const metadata = JSON.stringify(props.node.data.raw, null, 2);
  appStore.workspaceStore.createTextViewer(`${props.node.data.clean.label} Metadata`, metadata);
}
</script>

<template>
  <div class="text-center py-2">
    <KodexaButton
      class="open-metadata text-sm font-light"
      name="openMetadata"
      size="medium"
      @click="openMetadata"
    >
      Open Metadata
    </KodexaButton>
  </div>
</template>

<style scoped>
.open-metadata {
  width: max-content;
  box-shadow: none;
  font-size: 12px;
}

.open-metadata:hover {
  opacity: 0.8;
}
</style>
