<script setup lang="ts">
import type { Task, TaskStatus, User } from "~/model";
import { Dialog, DialogPanel, TransitionRoot } from "@headlessui/vue";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import { storeToRefs } from "pinia";
import { useListMemberships } from "~/api/memberships/memberships";
import { useListProjects } from "~/api/projects/projects";
import { useCreateTaskActivity } from "~/api/task-activity/task-activity";
import { createTask, updateTask } from "~/api/tasks/tasks";
import KodexaUserAvatar from "~/components/kodexa-user-avatar.vue";
import appStore from "~/store";
import { updateHandler } from "~/utils/error-handler";

interface Props {
  modelValue: boolean;
  documentFamily?: DocumentFamily;
  documentFamilies?: DocumentFamily[];
  taskToEdit?: Task | null;
}

const props = withDefaults(defineProps<Props>(), {
  taskToEdit: null,
  documentFamily: undefined,
  documentFamilies: undefined,
});

const emit = defineEmits<{
  "update:modelValue": [value: boolean];
  "task-created": [];
  "task-updated": [];
  "update:taskToEdit": [value: Task | null];
}>();

const { project } = storeToRefs(appStore.projectStore);
const { currentOrganization } = storeToRefs(appStore.organizationStore);
const { user, currentUser } = storeToRefs(appStore.userStore);

const isExpanded = ref(false);
const newTask = ref<Task>({
  title: "",
  project: project.value,
  description: "",
  status: "TODO" as TaskStatus,
  dueDate: null,
  metadata: {
    documentFamilies: props.documentFamily ? [props.documentFamily] : [],
  },
});

const { data: projects } = useListProjects({
  page: 1,
  pageSize: 100,
  filter: `organization.id:'${currentOrganization.value?.id}'`,
  query: "",
  sort: "",
});

const { data: memberships } = useListMemberships({
  page: 1,
  pageSize: 100,
  filter: `organization.id:'${currentOrganization.value?.id}'`,
  sort: "",
});

const createTaskActivityMutation = useCreateTaskActivity();

const selectedProjectId = computed({
  get: () => newTask.value.project?.id ?? "",
  set: (value: string) => {
    newTask.value.project = projects.value?.content.find(p => p.id === value) ?? null;
  },
});

const assigneeOptions = computed(() =>
  memberships.value?.content.map(m => ({
    id: m.user.id,
    name: formatUserName(m.user),
    email: m.user.email,
    user: m.user,
    text: formatUserName(m.user),
    avatarUrl: m.user.avatarUrl,
  })) ?? [],
);

const selectedAssigneeId = computed({
  get: () => newTask.value.assignee?.id ?? "",
  set: (value: string) => {
    newTask.value.assignee = assigneeOptions.value.find(option => option.id === value)?.user ?? null;
  },
});

const statusOptions = [
  { id: "TODO", text: "To Do" },
  { id: "IN_PROGRESS", text: "In Progress" },
  { id: "DONE", text: "Done" },
];

const selectedStatus = computed({
  get: () => newTask.value.status ?? "TODO",
  set: (value: string) => {
    if (newTask.value) {
      newTask.value.status = value as TaskStatus;
    }
  },
});

const today = new Date();

const taskDueDate = computed({
  get: () => newTask.value.dueDate ? new Date(newTask.value.dueDate) : null,
  set: (value: Date | null) => {
    newTask.value.dueDate = value?.toISOString() || null;
  },
});

watch(() => props.modelValue, (value) => {
  if (value && props.taskToEdit) {
    newTask.value = {
      ...JSON.parse(JSON.stringify(props.taskToEdit)),
      changeSequence: props.taskToEdit.changeSequence,
      assignee: props.taskToEdit.assignee,
    };
  } else {
    // When creating a new task
    let documentInfo = '';
    
    if (props.documentFamilies?.length) {
      documentInfo = `\nSelected Documents (${props.documentFamilies.length}):\n${props.documentFamilies.map(doc => 
        `- ${doc.path.split('/').pop()} (ID: ${doc.id})`
      ).join('\n')}`;
    } else if (props.documentFamily) {
      const documentName = props.documentFamily.path.split('/').pop();
      documentInfo = `\nDocument Details:\nName: ${documentName}\nID: ${props.documentFamily.id}\nPath: ${props.documentFamily.path}`;
    }

    newTask.value = {
      title: "",
      description: documentInfo,
      project: project.value,
      assignee: null,
      status: "TODO" as TaskStatus,
      dueDate: null,
      metadata: {
        documentFamilies: props.documentFamilies || (props.documentFamily ? [props.documentFamily] : []),
      },
    };
  }
}, { immediate: true });

watch(() => props.modelValue, (value) => {
  if (!value) {
    isExpanded.value = false;
  }
});

const formatUserName = (user: User): string => `${user.firstName} ${user.lastName}`;

function toggleExpand() {
  isExpanded.value = !isExpanded.value;
}

function close() {
  isExpanded.value = false;
  emit("update:modelValue", false);
  emit("update:taskToEdit", null);
}

const saving  = ref(false);

async function save() {

  saving.value = true;

  if (!newTask.value.title?.trim() || !newTask.value.project) {
    return;
  }

  if (props.taskToEdit?.id) {
    const taskId = props.taskToEdit.id.toString();
    await updateHandler(updateTask(taskId, {
      ...newTask.value,
      changeSequence: props.taskToEdit.changeSequence,
    }), "Task updated successfully");
    emit("task-updated");
  } else {

    const taskData = {
      ...newTask.value,
      metadata: {
        ...newTask.value.metadata,
        documentFamilies: props.documentFamily ? [props.documentFamily] : [],
      }
    };

    const createdTask = await updateHandler(createTask(newTask.value), "Task created successfully");

    const documentName = props.documentFamily?.path.split('/').pop();
    await createTaskActivityMutation.mutateAsync({
      data: {
        task: { id: createdTask.id },
        user: user.value,
        content: props.documentFamily
          ? `created the task from document "${documentName}" (ID: ${props.documentFamily.id})`
          : "created the task",
        detail: {
          field: "creation",
          oldValue: "",
          newValue: "",
        },
      },
    });

    emit("task-created");
  }
  close();
  saving.value = false;
}
</script>

<template>
  <TransitionRoot appear as="template" :show="modelValue">
    <Dialog as="div" class="relative z-10" @close="close">
      <div class="fixed inset-0 bg-gray-500/75 transition-opacity duration-300" />
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-start justify-center p-4 text-center sm:p-0">
          <DialogPanel
            :class="`
              relative w-full transform overflow-visible rounded-lg bg-white
              text-left shadow-xl transition-all duration-300 ease-in-out z-50
              ${isExpanded ? 'sm:max-w-[65vw] sm:h-[90vh] sm:mt-8' : 'sm:max-w-4xl sm:mt-48'}
            `"
          >
            <div class="flex flex-col h-full">
              <!-- Header -->
              <div class="flex items-center justify-between px-4 py-3">
                <span class="px-2 py-1 rounded-md border-2 border-gray-100 text-sm font-bold">
                  {{ taskToEdit ? 'Update Task' : 'New Task' }}
                </span>
                <div class="flex items-center space-x-4">
                  <button
                    class="text-gray-400 hover:text-gray-600 transition-colors duration-200"
                    @click="toggleExpand"
                  >
                    <svg class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path
                        stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"
                      />
                    </svg>
                  </button>
                  <button
                    class="text-gray-400 hover:text-gray-600 transition-colors duration-200"
                    @click="close"
                  >
                    <span class="sr-only">Close</span>
                    <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>

              <!-- Content -->
              <div class="flex-grow px-4 py-4">
                <KodexaTextInput
                  v-model="newTask.title"
                  :borderless="true"
                  font-size="xl"
                  font-weight="bold"
                  name="task-title"
                  class="w-full bg-transparent text-gray-900 placeholder-gray-400 border-none focus:ring-0 focus:outline-none"
                  placeholder="Task Name"
                />
                <textarea
                  v-model="newTask.description"
                  class="w-full h-5/6 mt-4 bg-transparent text-gray-700 placeholder-gray-400 border-none focus:ring-0 focus:outline-none resize-none"
                  placeholder="Add description..."
                />
              </div>

              <!-- Dropdowns -->
              <div class="px-4 py-4">
                <div class="flex flex-wrap gap-2">
                  <!-- Status Dropdown -->
                  <div :class="[selectedStatus ? 'w-fit max-w-64' : 'w-52']">
                    <KodexaDropDown
                      id="status"
                      v-model="selectedStatus"
                      name="status"
                      :items="statusOptions"
                      value-field="id"
                      text-field="text"
                      placeholder="Status"
                      class="mt-1"
                    />
                  </div>

                  <!-- Assignee Dropdown -->
                  <div :class="[selectedAssigneeId ? 'w-fit max-w-64' : 'w-52']">
                    <KodexaDropDown
                      id="assignee"
                      v-model="selectedAssigneeId"
                      v-tooltip="selectedAssigneeId ? `${assigneeOptions.find(a => a.id === selectedAssigneeId)?.name} (${assigneeOptions.find(a => a.id === selectedAssigneeId)?.email})` : ''"
                      name="assignee"
                      :items="assigneeOptions"
                      value-field="id"
                      text-field="text"
                      placeholder="Assignee"
                      class="mt-1"
                    >
                      <template #item-render="{ item }">
                        <div class="flex items-center gap-2">
                          <KodexaUserAvatar
                            :user="item.user"
                            size="6"
                            class="flex-shrink-0"
                          />
                          <div class="flex flex-col overflow-hidden">
                            <span class="font-medium" :title="item.name">{{ item.name }}</span>
                            <span class="text-xs text-gray-500" :title="item.email">{{ item.email }}</span>
                          </div>
                        </div>
                      </template>
                      <template #value-render="{ item }">
                        <div class="flex items-center gap-2">
                          <KodexaUserAvatar
                            v-if="item?.user"
                            :user="item.user"
                            size="6"
                            class="flex-shrink-0"
                          />
                          <span class="font-medium truncate" :title="item?.name">{{ item?.name }}</span>
                        </div>
                      </template>
                    </KodexaDropDown>
                  </div>

                  <!-- Project Dropdown -->
                  <div :class="[selectedProjectId ? 'w-fit max-w-64' : 'w-52']">
                    <KodexaDropDown
                      id="project"
                      v-model="selectedProjectId"
                      v-tooltip="projects?.content.find(p => p.id === selectedProjectId)?.name ?? ''"
                      name="project"
                      :items="projects?.content ?? []"
                      value-field="id"
                      text-field="name"
                      placeholder="Project"
                      class="mt-1"
                    />
                  </div>

                  <!-- Due Date Picker -->
                  <div class="w-52">
                    <DatePicker
                      v-model="taskDueDate"
                      :min="today"
                      class="w-full date-picker-custom mt-1"
                      :clearable="true"
                      placeholder="Set Due Date"
                    />
                  </div>
                </div>
              </div>

              <!-- Footer -->
              <div class="flex items-center justify-end px-4 py-3 border-t border-gray-200">
                <KodexaButton
                  type="primary"
                  class="ml-2"
                  :loading="saving"
                  :disabled="!selectedProjectId || !newTask.title?.trim()"
                  :icon="taskToEdit ? 'content-save' : 'auto-fix'"
                  @click="save"
                >
                  {{ taskToEdit ? 'Save Changes' : 'Create Task' }}
                </KodexaButton>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>
:deep(.date-picker-custom) {
  height: 32px;
  margin-top: 4px;
  display: flex;
  align-items: center;
  border-color: #D1D5DB !important;
  background-color: white !important;
  background-image: none !important;
  @apply rounded-md shadow-sm;
}

:deep(.date-picker-custom .k-input:hover),
:deep(.date-picker-custom .k-input.k-focus) {
  border-color: #9CA3AF !important;
}

:deep(.date-picker-custom .k-input-inner) {
  @apply text-sm text-gray-900;
}

:deep(.date-picker-custom .k-input-inner::placeholder) {
  @apply text-sm text-gray-500;
}

:deep(.k-button) {
  --tw-ring-offset-shadow: 0 0 #0000 !important;
  --tw-ring-shadow: 0 0 #0000 !important;
}

:deep(.kodexa-input .k-input-inner::placeholder) {
  @apply font-normal text-gray-400;
}
</style>
