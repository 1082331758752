<script setup lang="ts">
import { Toolbar } from "@progress/kendo-vue-buttons";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import { storeToRefs } from "pinia";
import { computed, ref, toRef, watch } from "vue";
import { createGridHelper } from "~/store/useGridHelper";
import type { ModelInteraction } from "~/model";
import KodexaButton from "~/components/inputs/kodexa-button.vue";
import { copyToClipboard as copyToClipboardUtil } from "~/utils/general";
import { useListCloudModels } from "~/api/cloud-models/cloud-models";

const props = defineProps({
  modelInteractions: {
    type: Array as PropType<ModelInteraction[]>,
    required: true,
    default: () => [],
  },
  totalCost: {
    type: String,
    required: true,
    default: "0",
  },
});

const emit = defineEmits(["someEvent"]);

const modelInteractions = toRef(props, "modelInteractions");
const { data: cloudModels } = useListCloudModels();

const imageUrlsWithNames = ref<{ [key: string]: { url: string; name: string } }>({});

function getModelName(modelId: string) {
  const model = cloudModels.value?.content.find(m => m.modelId === modelId);
  return model ? model.name : modelId;
}

function getModelDescription(modelId: string) {
  const model = cloudModels.value?.content.find(m => m.modelId === modelId);
  return model ? model.description : "";
}

function fetchImageUrls(modelIds: string[]) {
  if (!cloudModels.value) {
    return;
  }

  imageUrlsWithNames.value = modelIds.reduce((acc, id) => {
    const model = cloudModels.value.content?.find(model => model.modelId === id);
    if (model && model.imageUrl) {
      acc[id] = { url: model.imageUrl, name: model.name || "Unknown Model" };
    }
    return acc;
  }, {} as { [key: string]: { url: string; name: string } });
}

watch([modelInteractions, cloudModels], ([newInteractions, newCloudModels]) => {
  if (newInteractions && newCloudModels) {
    fetchImageUrls(newInteractions.map(interaction => interaction.modelId));
  }
}, { immediate: true });

const gridHelper = createGridHelper("cost-analysis-list", {
  page: 1,
  pageSize: 99,
  sort: "id:asc",
  skip: 0,
  take: 10,
});

const {
  pageSettings,
  sort,
  showFilter,
  filter,
} = storeToRefs(gridHelper);

const allData = ref([]);

watch(modelInteractions, (newValue) => {
  allData.value = newValue.map((interaction, index) => ({
    id: index + 1,
    model: interaction.modelId,
    timeTaken: interaction.duration,
    inputTokens: interaction.inputTokens,
    outputTokens: interaction.outputTokens,
    cost: interaction.cost.toFixed(5),
  }));
  gridHelper.setTotal(allData.value.length);
}, { immediate: true });

const gridData = computed(() => {
  let filteredData = allData.value;

  // Apply filtering
  if (filter.value && filter.value.filters) {
    filteredData = filteredData.filter(item =>
      filter.value.filters.every(f =>
        String(item[f.field]).toLowerCase().includes(f.value.toLowerCase()),
      ),
    );
  }

  // Apply sorting
  if (sort.value && sort.value.length > 0) {
    let field, dir;
    if (typeof sort.value[0] === "string") {
      [field, dir] = sort.value[0].split(":");
    } else if (typeof sort.value[0] === "object") {
      field = sort.value[0].field;
      dir = sort.value[0].dir;
    }

    if (field && dir) {
      filteredData.sort((a, b) => {
        if (a[field] < b[field]) {
          return dir === "asc" ? -1 : 1;
        }
        if (a[field] > b[field]) {
          return dir === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
  }

  // Update total count
  gridHelper.setTotal(filteredData.length);

  // Apply pagination
  const start = pageSettings.value.skip;
  const end = start + pageSettings.value.take;
  return filteredData.slice(start, end);
});

const summaryStats = computed(() => {
  return allData.value.reduce((acc, curr) => {
    acc.totalModels += 1;
    acc.totalDuration += curr.timeTaken;
    acc.totalInputTokens += curr.inputTokens;
    acc.totalOutputTokens += curr.outputTokens;
    acc.totalCost += Number.parseFloat(curr.cost);
    if (!acc.uniqueModels.includes(curr.model)) {
      acc.uniqueModels.push(curr.model);
    }
    return acc;
  }, {
    totalModels: 0,
    uniqueModels: [],
    totalDuration: 0,
    totalInputTokens: 0,
    totalOutputTokens: 0,
    totalCost: 0,
  });
});

const columns = [
  { field: "id", title: "#", width: "50px" },
  {
    field: "model",
    title: "Model Used",
    cell: "modelCell",
  },
  { field: "timeTaken", title: "Time Taken (seconds)" },
  { field: "inputTokens", title: "Input Tokens" },
  { field: "outputTokens", title: "Output Tokens" },
  { field: "cost", title: "Cost ($)" },
];

function copyModelInteractions() {
  const content = JSON.stringify(modelInteractions.value, null, 2);
  copyToClipboardUtil(content, "Model Interactions JSON copied to clipboard");
}

const formattedTotalCost = computed(() => `$${summaryStats.value.totalCost.toFixed(5)}`);
const formattedTotalDuration = computed(() => `${summaryStats.value.totalDuration.toLocaleString()} ms`);
</script>

<template>
  <div :style="{ height: 'calc(100vh - 69px)' }">
    <Toolbar class="border-0 bg-white">
      <KodexaButton icon="filter-outline" size="small" type="secondary" :togglable="true" @click="gridHelper.toggleFilter()">
        Filter
      </KodexaButton>
      <KodexaButton icon="content-copy" size="small" type="secondary" @click="copyModelInteractions">
        Copy to Clipboard
      </KodexaButton>
    </Toolbar>
    <div class="mx-4">
      <p v-if="!modelInteractions || modelInteractions.length === 0" class="text-red-500">
        No model interactions data available
      </p>
      <div class="mb-4">
        <h2 class="text-2xl font-bold mb-1 mt-4">
          Model Cost Analysis
        </h2>
        <div class="flex items-center space-x-8">
          <div v-tooltip="'Model Used'" class="flex items-center">
            <MaterialDesignIcon
              name="head-snowflake-outline"
              class="text-[#23ABDC] mr-1.5"
              aria-hidden="true"
              size="20"
            />
            <div class="font-medium">
              {{ summaryStats.uniqueModels.length }}
            </div>
          </div>
          <div v-tooltip="'Total Input Tokens'" class="flex items-center">
            <MaterialDesignIcon
              name="application-import"
              class="text-[#23ABDC] mr-1.5"
              aria-hidden="true"
              size="20"
            />
            <div class="font-medium">
              {{ summaryStats.totalInputTokens.toLocaleString() }}
            </div>
          </div>
          <div v-tooltip="'Total Output Tokens'" class="flex items-center ">
            <MaterialDesignIcon
              name="application-export"
              class="text-[#23ABDC] mr-1.5"
              aria-hidden="true"
              size="20"
            />
            <div class="font-medium">
              {{ summaryStats.totalOutputTokens.toLocaleString() }}
            </div>
          </div>
          <div v-tooltip="'Total Cost'" class="flex items-center">
            <MaterialDesignIcon
              name="currency-usd"
              class="text-[#23ABDC] mr-1.5"
              aria-hidden="true"
              size="20"
            />
            <div class="font-medium">
              {{ formattedTotalCost }}
            </div>
          </div>
          <div v-tooltip="'Total Time Taken'" class="flex items-center">
            <MaterialDesignIcon
              name="clock-time-eight-outline"
              class="text-[#23ABDC] mr-1.5"
              aria-hidden="true"
              size="20"
            />
            <div class="font-medium">
              {{ formattedTotalDuration }}
            </div>
          </div>
        </div>
      </div>
      <Grid
        ref="grid"
        :data-items="gridData"
        :pageable="true"
        :sort="sort"
        :skip="pageSettings.skip"
        :take="pageSettings.take"
        :total="pageSettings.total"
        :columns="columns"
        :filterable="showFilter"
        :filter="filter"
        :resizable="true"
        :sortable="true"
        @pagechange="gridHelper.pageChangeHandler($event)"
        @filterchange="gridHelper.filterChange($event)"
        @sortchange="gridHelper.sortChange($event)"
      >
        <template #modelCell="{ props }">
          <td>
            <div class="model-cell">
              <div v-if="imageUrlsWithNames[props.dataItem.model]" class="model-image-container">
                <img
                  class="model-image"
                  :src="imageUrlsWithNames[props.dataItem.model].url"
                  :alt="imageUrlsWithNames[props.dataItem.model].name"
                  :title="imageUrlsWithNames[props.dataItem.model].name"
                >
              </div>
              <div class="model-info">
                <div class="model-name">
                  {{ getModelName(props.dataItem.model) }}
                </div>
                <div class="model-description">
                  {{ getModelDescription(props.dataItem.model) }}
                </div>
              </div>
            </div>
          </td>
        </template>
        <GridNoRecords>
          <KodexaGridNoRecords />
        </GridNoRecords>
      </Grid>
    </div>
  </div>
</template>

<style scoped>
@media (max-width: 768px) {
  .flex.justify-between {
    flex-direction: column;
    align-items: flex-start;
  }
  .flex.justify-between > div {
    margin-bottom: 1rem;
  }
}

.model-cell {
  display: flex;
  align-items: center;
  min-width: 100%;
  padding: 4px 0;
}

.model-image-container {
  flex: 0 0 32px;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  border-radius: 50%;
  border: 1px solid #E7E7E7;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.model-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.model-info {
  flex: 1;
  min-width: 0;
}

.model-name {
  font-weight: bold;
  word-break: break-word;
}

.model-description {
  font-size: 0.85em;
  color: #666;
  word-break: break-word;
}
</style>
