/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  ExceptionResponse,
  ListMembershipsParams,
  Membership,
  PageMembership,
  ThrowableProblem
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get a resource with the provided ID
 */
export const getMembership = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<Membership>(
      {url: `/api/memberships/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetMembershipQueryKey = (id: MaybeRef<string>,) => {
    return ['api','memberships',id] as const;
    }

    
export const getGetMembershipQueryOptions = <TData = Awaited<ReturnType<typeof getMembership>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMembership>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetMembershipQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMembership>>> = ({ signal }) => getMembership(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getMembership>>, TError, TData> 
}

export type GetMembershipQueryResult = NonNullable<Awaited<ReturnType<typeof getMembership>>>
export type GetMembershipQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetMembership<TData = Awaited<ReturnType<typeof getMembership>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMembership>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetMembershipQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the given instance
 */
export const updateMembership = (
    id: MaybeRef<string>,
    membership: MaybeRef<Membership>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
membership = unref(membership);
      
      return customAxios<Membership>(
      {url: `/api/memberships/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: membership
    },
      options);
    }
  


export const getUpdateMembershipMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateMembership>>, TError,{id: string;data: Membership}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateMembership>>, TError,{id: string;data: Membership}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateMembership>>, {id: string;data: Membership}> = (props) => {
          const {id,data} = props ?? {};

          return  updateMembership(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateMembershipMutationResult = NonNullable<Awaited<ReturnType<typeof updateMembership>>>
    export type UpdateMembershipMutationBody = Membership
    export type UpdateMembershipMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateMembership = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateMembership>>, TError,{id: string;data: Membership}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateMembership>>,
        TError,
        {id: string;data: Membership},
        TContext
      > => {

      const mutationOptions = getUpdateMembershipMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteMembership = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/memberships/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteMembershipMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteMembership>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteMembership>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteMembership>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteMembership(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteMembershipMutationResult = NonNullable<Awaited<ReturnType<typeof deleteMembership>>>
    
    export type DeleteMembershipMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteMembership = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteMembership>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteMembership>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteMembershipMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listMemberships = (
    params?: MaybeRef<ListMembershipsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageMembership>(
      {url: `/api/memberships`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListMembershipsQueryKey = (params?: MaybeRef<ListMembershipsParams>,) => {
    return ['api','memberships', ...(params ? [params]: [])] as const;
    }

    
export const getListMembershipsQueryOptions = <TData = Awaited<ReturnType<typeof listMemberships>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListMembershipsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listMemberships>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListMembershipsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listMemberships>>> = ({ signal }) => listMemberships(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listMemberships>>, TError, TData> 
}

export type ListMembershipsQueryResult = NonNullable<Awaited<ReturnType<typeof listMemberships>>>
export type ListMembershipsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useListMemberships<TData = Awaited<ReturnType<typeof listMemberships>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListMembershipsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listMemberships>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListMembershipsQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createMembership = (
    membership: MaybeRef<Membership>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      membership = unref(membership);
      
      return customAxios<Membership>(
      {url: `/api/memberships`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: membership, signal
    },
      options);
    }
  


export const getCreateMembershipMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createMembership>>, TError,{data: Membership}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createMembership>>, TError,{data: Membership}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createMembership>>, {data: Membership}> = (props) => {
          const {data} = props ?? {};

          return  createMembership(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateMembershipMutationResult = NonNullable<Awaited<ReturnType<typeof createMembership>>>
    export type CreateMembershipMutationBody = Membership
    export type CreateMembershipMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateMembership = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createMembership>>, TError,{data: Membership}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof createMembership>>,
        TError,
        {data: Membership},
        TContext
      > => {

      const mutationOptions = getCreateMembershipMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForMembership = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/memberships/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForMembershipQueryKey = (id: MaybeRef<string>,) => {
    return ['api','memberships',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForMembershipQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForMembership>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForMembership>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForMembershipQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForMembership>>> = ({ signal }) => getChangeSequenceForMembership(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForMembership>>, TError, TData> 
}

export type GetChangeSequenceForMembershipQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForMembership>>>
export type GetChangeSequenceForMembershipQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetChangeSequenceForMembership<TData = Awaited<ReturnType<typeof getChangeSequenceForMembership>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForMembership>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetChangeSequenceForMembershipQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



