/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */

export type NotificationActivityType = typeof NotificationActivityType[keyof typeof NotificationActivityType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationActivityType = {
  NEW_UPLOAD: 'NEW_UPLOAD',
  NEW_DATA_AVAILABLE: 'NEW_DATA_AVAILABLE',
  ASSIGNMENT_ADDED: 'ASSIGNMENT_ADDED',
  ASSIGNMENT_REMOVED: 'ASSIGNMENT_REMOVED',
  CUSTOM_SUBSCRIPTION: 'CUSTOM_SUBSCRIPTION',
} as const;
