<script setup lang="ts">
import type { Membership } from "~/model";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { notify } from "notiwind";
import { createMembership } from "~/api/memberships/memberships";
import appStore from "~/store";
import { handleError } from "~/utils/error-handler";

defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue", "refetchMembers"]);

const newMember = ref({
  user: {},
  role: undefined,
  organization: appStore.organizationStore.currentOrganization,
} as Membership);

function close() {
  emit("update:modelValue", false);
  newMember.value = {
    user: {},
    role: undefined,
    organization: appStore.organizationStore.currentOrganization,
  };
}

const roles = [
  {
    id: "READ",
    text: "READ",
  },
  {
    id: "WRITE",
    text: "WRITE",
  },
  {
    id: "OWNER",
    text: "OWNER",
  },
];

async function createNewMember() {
  try {
    await createMembership(newMember.value);
  } catch (e) {
    await handleError(e);
    console.error(e);
  }
  notify({
    group: "generic",
    title: "Success",
    text: "Added New Member successfully",
  }, 3000);
  emit("refetchMembers");
  close();
}
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative z-10" @close="close">
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild
              as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full" enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0" leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                <div class="flex h-full flex-col bg-white shadow-xl">
                  <div class="flex-1">
                    <!-- Header -->
                    <div class="bg-gray-50 px-4 py-6 sm:px-6">
                      <div class="flex items-start justify-between space-x-3">
                        <div class="space-y-1">
                          <DialogTitle class="text-base font-semibold leading-6 text-gray-900">
                            New Member
                          </DialogTitle>
                          <p class="text-sm text-gray-500">
                            Get started by filling in the information below to create your new member.
                          </p>
                        </div>
                        <!-- Action buttons -->
                        <div class="shrink-0 border-gray-200 px-4 py-5 pr-0">
                          <div class="flex justify-end space-x-3">
                            <KodexaButton
                              id="cancelCreateNewMember" icon="cancel" type="secondary" size="small"
                              @click="close"
                            >
                              Cancel
                            </KodexaButton>
                            <KodexaButton
                              id="createNewMember" icon="plus" type="primary" size="small"
                              @click="createNewMember"
                            >
                              Create
                            </KodexaButton>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Divider container -->
                    <div class="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                      <!-- First Name -->
                      <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                          <label
                            for="member-first-name"
                            class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                          >First Name</label>
                        </div>
                        <div class="sm:col-span-2">
                          <KodexaTextInput
                            id="member-first-name" v-model="newMember.user.firstName"
                            name="member-first-name"
                          />
                        </div>
                        <div>
                          <label
                            for="member-last-name"
                            class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                          >Last Name</label>
                        </div>
                        <div class="sm:col-span-2">
                          <KodexaTextInput
                            id="member-last-name" v-model="newMember.user.lastName"
                            name="member-last-name"
                          />
                        </div>

                        <!-- Email -->
                        <div>
                          <label for="member-email" class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Email
                            Address</label>
                        </div>
                        <div class="sm:col-span-2">
                          <KodexaTextInput
                            id="member-email" v-model="newMember.user.email"
                            name="member-email"
                          />
                        </div>
                        <div>
                          <label for="member-role" class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Role</label>
                        </div>
                        <div class="sm:col-span-2">
                          <KodexaDropDown id="member-role" v-model="newMember.role" :items="roles" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
